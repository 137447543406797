
/** @format */

import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { auditorTeamRole, DIMENSION_TYPE, QUESTIONNAIRE_RESULT_STATUS, QUESTIONNAIRE_STATUS, QuestionnaireDetails } from '../../models/questionnaire';
import { MatDialog } from '@angular/material/dialog';
import { AddEditUserComponent } from '../../dialogs/add-edit-user/add-edit-user.component';
import { Validators } from '@angular/forms';
import { allowedUserRoles, UserRoles } from '../../functions/allowed-user-roles';
import { UserValidatorService } from '../../services/user-validator.service';
import { PracticeInfoAccountType } from '../../models/practice-info';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent implements OnInit, OnDestroy {
  isUserTeamAdminOrIPFTeamAdmin: boolean;
  isUserCoAdmin: boolean;
  isUserAuditor: boolean;
  isQuestionnaireNotStartedYet: boolean;
  isQuestionnaireCompleted: boolean; // was opened, filled out, saved and finally closed now
  submissionId: string;
  isTeamWithExtendedAccountType: boolean;
  showFileUploadSection: boolean;
  canUserAssignAuditor: boolean;

  questionnaireStatus: QUESTIONNAIRE_STATUS;
  questionnaireResultStatus: QUESTIONNAIRE_RESULT_STATUS = null;

  #destroySubject = new Subject<void>();
  #questionnaireService = inject(QuestionnaireService);
  #userValidatorService = inject(UserValidatorService);
  #dialog = inject(MatDialog);

  questionnaireDetails$: BehaviorSubject<QuestionnaireDetails>;
  questionnaireLoading$: BehaviorSubject<boolean>;
  message$ = new BehaviorSubject<string>(null);

  readonly questionnaireForm = this.#questionnaireService.questionnaireForm;
  readonly auditorForm = this.#questionnaireService.auditorForm;
  readonly DIMENSION_TYPE = DIMENSION_TYPE;
  readonly QUESTIONNAIRE_STATUS = QUESTIONNAIRE_STATUS;
  readonly QUESTIONNAIRE_RESULT_STATUS = QUESTIONNAIRE_RESULT_STATUS;

  ngOnInit(): void {
    this.getQuestionnarieDetails();

    this.questionnaireDetails$ = this.#questionnaireService.questionnaireDetails;
    this.questionnaireLoading$ = this.#questionnaireService.isQuestionnaireDataLoading;

    this.canUserAssignAuditor = allowedUserRoles([UserRoles.TEAM_GROUP_ADMIN, UserRoles.ORG_ADMIN]);
    this.isUserTeamAdminOrIPFTeamAdmin = allowedUserRoles([UserRoles.TEAM_ADMIN, UserRoles.IPF_TEAM_ADMIN, UserRoles.IPF_AUDITOR]);
    this.isUserCoAdmin = allowedUserRoles([UserRoles.ORG_ADMIN]);
    this.isUserAuditor = allowedUserRoles([UserRoles.IPF_AUDITOR]);
    this.isTeamWithExtendedAccountType = ((this.#userValidatorService.currentUserRights.getValue().currentMember.team as any).accountType as PracticeInfoAccountType) === PracticeInfoAccountType.EXTENDED;
    this.showFileUploadSection = this.isTeamWithExtendedAccountType && allowedUserRoles([UserRoles.IPF_AUDITOR, UserRoles.IPF_TEAM_ADMIN, UserRoles.TEAM_ADMIN]);

    this.saveFormOnAnyChange();
    this.#autoSaveAuditForm();

    this.questionnaireDetails$.pipe(takeUntil(this.#destroySubject), tap(val => {
      if (val) {
        this.questionnaireStatus = val.status;

        if (val.results[0]?.status) {
          this.questionnaireResultStatus = val.results[0]?.status;
        }

        this.isQuestionnaireNotStartedYet = !val.results.length && this.questionnaireStatus === QUESTIONNAIRE_STATUS.ACTIVE;
        this.isQuestionnaireCompleted = this.questionnaireResultStatus === QUESTIONNAIRE_RESULT_STATUS.COMPLETED || this.questionnaireResultStatus === QUESTIONNAIRE_RESULT_STATUS.WAITING_FOR_AUDIT;
        this.submissionId = val.results[0]?.submission?.id;
      }
      this.#handleMessageBanner();
    })).subscribe();

    this.questionnaireForm.markAsPristine();
  }

  saveFormOnAnyChange(): void {
    this.questionnaireForm.valueChanges.pipe(takeUntil(this.#destroySubject)).subscribe(() => {
      if (this.questionnaireForm.dirty) {
        this.saveQuestionaire();
      }
    });

    this.#questionnaireService.proofsForm.clear(); // if we don't clear, new controls would be added all the time, so the formArray would be multiplied

    this.#questionnaireService.proofsForm.valueChanges.pipe(debounceTime(1500), takeUntil(this.#destroySubject)).subscribe(() => {
      if (this.#questionnaireService.proofsForm.dirty) {
        this.saveQuestionaire();
      }
    })
  };

  #autoSaveAuditForm(): void {
    this.auditorForm.clear();  // if we don't clear, new controls would be added all the time, so the formArray would be multiplied

    this.auditorForm.valueChanges.pipe(debounceTime(1500), takeUntil(this.#destroySubject)).subscribe(() => {
      if (this.auditorForm.dirty) {
        this.audit();
      }
    });
  }

  getQuestionnarieDetails(): void {
    this.#questionnaireService.loadQuestionnaireData();
  }

  publishQuestionaire(): void {
    this.#questionnaireService.publishQuestionnaire(this.questionnaireDetails$.getValue().id);
  }

  completeQuestionaire(): void {
    this.#questionnaireService.saveAndCompleteQuestionnaire();
    this.questionnaireForm.markAsUntouched();
  }

  saveQuestionaire(): void {
    this.#questionnaireService.submitQuestionnaire().subscribe();
  }

  assignAuditor(): void {
    const formValues = {
      teamAdmin: [false],
      teamRole: [auditorTeamRole, Validators.required],
      team: this.#userValidatorService.currentUserRights.getValue().currentMember.team,
      emailAddress: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      teamId: this.#userValidatorService.currentUserRights.getValue().currentMember.team.id,
      user: {
        organisationAdmin: false,
        emailAddress: '',
        firstName: '',
        lastName: '',
      },
    };

    this.#dialog.open(AddEditUserComponent, {
      data: { formValues: formValues, isNew: true, isAuditorBeingAssigned: true },
      disableClose: true,
      height: '500px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'users-teams-dialog',
    });
  }

  audit(completeAudit = false): void {
    const auditId = this.questionnaireDetails$.getValue().results[0].audit.id;

    this.#questionnaireService.auditQuestionnaire(auditId, completeAudit).subscribe();
  }

  #handleMessageBanner(): void {
    const waitingForAuditMessage = 'Het indienen van de bewijsstukken is succesvol verlopen! De IPF Uitgebreide test en de bewijsstukken worden in samenhang bekeken door een auditor. De uitslag volgt binnen 4 weken.';
    const activeStatusMessage = 'Er staat een nieuwe vragenlijst klaar voor uw praktijk. Klik op button Vragenlijst start om te de vragenlijst te delen met de praktijkmedewerkers en te starten met de zelfscan.';
    const completedStatusMessage = 'De vragenlijst is reeds afgerond, de scores zijn zichtbaar in de sunburst Innovatie. Je vind de sunburst in de bovenste menubalk onder optie Monitor.';

    if (this.questionnaireResultStatus == QUESTIONNAIRE_RESULT_STATUS.WAITING_FOR_AUDIT) {
      this.message$.next(waitingForAuditMessage);
      return;
    } else if (this.questionnaireResultStatus === QUESTIONNAIRE_RESULT_STATUS.COMPLETED) {
      this.message$.next(completedStatusMessage);
      return;
    } else if (this.isQuestionnaireNotStartedYet) {
      this.message$.next(activeStatusMessage); // message when the questionnaire is ready to be started
      return;
    } else {
      this.message$.next(null);
    }
  }

  ngOnDestroy(): void {
    this.#destroySubject.next();
    this.#destroySubject.complete();
  }
}
