/** @format */

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { HttpClient } from '@angular/common/http';
import { throwError as _throw, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { PagedResult } from '../models/paged-result';
import { PageSetting } from '../models/paged-setting';
import { BaseModel } from '../models/base-model';
import { AbstractRestService } from './iq-package-services/abstract-rest-service.service';
const apiPath = environment.apiPath;

/**
 *  Service for managing the users list.
 *
 * @export
 * @class             UsersListService
 * @extends           {AbstractRestService<User>}
 */
@Injectable()
export class UsersListService extends AbstractRestService<User> {
  /**
   * Creates an instance of UsersListService.
   *
   * @param           {HttpClient}      http
   * @memberof        UsersListService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}users`, 'users');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<User>}
   * @memberof        UsersListService
   */
  protected mapToModel(json: any): PagedResult<User> {
    //  If the Users array doesn't exist, create an empty object
    let payload = [];

    if (json._embedded) {
      payload = <User[]>json._embedded.users;
    }

    return new PagedResult<User>(payload, <PageSetting>json.page);
  }

  /**
   *  Resend the email if the user hasn't activated the account yet.
   *
   * @param       {string}      userId  The id of the user
   * @returns
   * @memberof    UsersListService
   */
  resendActivationEmail(userId: string) {
    return this.http
      .get<any>(`${this.actionUrl}/${userId}/activation`)
      .pipe(catchError((err) => _throw(err)));
  }

  /**
   *  Post user with the teamId param
   *
   * @param       {BaseModel}   aModel  The posted model
   * @param       {string}      teamId  The id of the team
   * @returns
   * @memberof    UsersListService
   */
  postUser(aModel: BaseModel, teamId: string): Observable<User> {
    return this.http
      .post<User>(`${this.actionUrl}?teamId=${teamId}`, aModel)
      .pipe(catchError((err) => _throw(err)));
  }

  /**
   *  Put user with the teamId param
   *
   * @param       {BaseModel}   aModel  The putted model
   * @returns
   * @memberof    UsersListService
   */
  put(aModel: any): Observable<User> {
    return this.http
      .put<User>(
        `${this.actionUrl}/${aModel.id}?teamId=${aModel.teamId}`,
        aModel,
      )
      .pipe(catchError((err) => _throw(err)));
  }
}
