/** @format */

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImprovementItem } from '../models/improvement_item';
import { Observable, throwError as _throw } from 'rxjs';
import { environment } from '../../environments/environment';
import { PagedResult } from '../models/paged-result';
import { PageSetting } from '../models/paged-setting';
import { BaseModel } from '../models/base-model';
import { AbstractRestService } from './iq-package-services/abstract-rest-service.service';

const apiPath = environment.apiPath;

/**
 *  Service for managing the Improvement item.
 *
 * @export
 * @class     ImprovementItemsService
 * @extends   {AbstractRestService<ImprovementItem>}
 */
@Injectable()
export class ImprovementItemsService extends AbstractRestService<ImprovementItem> {
  /**
   * Creates an instance of ImprovementItemsService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      ImprovementItemsService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}improvement_items`, 'improvement_items');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<ImprovementItem}
   * @memberof        ImprovementItemsService
   */
  protected mapToModel(json: any): PagedResult<ImprovementItem> {
    //  If the Team array doesn't exist, create an empty Team object
    let payload = [];

    if (json._embedded) {
      payload = <ImprovementItem[]>json._embedded.improvementItems;
    }

    return new PagedResult<ImprovementItem>(payload, <PageSetting>json.page);
  }

  /**
   *  Delete the item with the teamId query param.
   *
   * @param     {BaseModel}               aModel
   * @param     {string}                  teamId
   * @returns   {Observable<T>}
   * @memberof  ImprovementItemsService
   */
  deleteItem(aModel: BaseModel, teamId: string): Observable<ImprovementItem> {
    return this.http
      .delete<ImprovementItem>(
        `${this.actionUrl}/${aModel.id}?teamId=${teamId}`,
      )
      .pipe(catchError((err) => _throw(err)));
  }
}
