<!-- @format -->

<div class="row-buttons-container filters-container">
  <app-year-planning-dropdown></app-year-planning-dropdown>

  <div class="action-buttons-container">
    <button
      *ngIf="isUserCoAdmin"
      mat-flat-button
      color="accent"
      class="no-grow"
      (click)="mergeYearPlanItems()"
      i18n="yearplan-list button add-new-incident"
    >
      <mat-icon>add</mat-icon>
      Toevoegen standaard jaarplan items
    </button>

    <button
      mat-flat-button
      color="accent"
      class="no-grow"
      (click)="onEditIntent(undefined, $event)"
      i18n="yearplan-list button add-new-incident"
    >
      <mat-icon>add</mat-icon>
      Toevoegen nieuw jaarplan item
    </button>
  </div>
</div>

<mat-accordion
  displayMode="flat"
  multi
>
  <mat-expansion-panel
    class="mat-elevation-z0"
    *ngFor="let cat of planningCategories; let i = index"
    [expanded]="step === i"
    (opened)="setStep(i, cat.id)"
    multi
    multi="true"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>{{ cat.name }}</span>
      </mat-panel-title>
      <mat-panel-description>
        <span>{{ cat.description }}</span>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <table
      mat-table
      [dataSource]="dataSource"
      class="kms-list yearplan-list"
      *ngIf="crudR"
    >
      <colgroup>
        <col class="inset" />
        <col class="inset executioner" />
        <col
          span="12"
          class="month"
        />
      </colgroup>

      <ng-container matColumnDef="topic">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="inset"
        >
          Onderwerp
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="inset"
        >
          <div class="flex-rows">
            <span>
              {{ element.topic }}
            </span>
            <div class="no-grow">
              <button
                mat-icon-button
                color="accent"
                (click)="onEditIntent(element, $event)"
                *ngIf="crudR"
              >
                <mat-icon>create</mat-icon>
              </button>
              <button
                mat-icon-button
                color="warn"
                (click)="onDeleteIntent(element, $event, deleteDialog)"
                *ngIf="crudD"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="executioners">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="inset"
        >
          Uitvoerder
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="inset"
        >
          <p *ngFor="let exe of element.executioners">
            {{ exe.memberName }}
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="jan">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="month"
        >
          JAN
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="month planned-date"
        >
          <ng-container *ngFor="let date of element.plannedSessions">
            <p
              [ngClass]="{
                done: date.ready,
                late: !date.ready && date.plannedDate < today
              }"
              *ngIf="date.plannedDate[5] == '0' && date.plannedDate[6] == '1'"
            >
              {{ date.plannedDate | safariDateFormat | date: 'dd-MM' }}
            </p>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="feb">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="month"
        >
          FEB
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="month planned-date"
        >
          <ng-container *ngFor="let date of element.plannedSessions">
            <p
              [ngClass]="{
                done: date.ready,
                late: !date.ready && date.plannedDate < today
              }"
              *ngIf="date.plannedDate[5] == '0' && date.plannedDate[6] == '2'"
            >
              {{ date.plannedDate | safariDateFormat | date: 'dd-MM' }}
            </p>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="mar">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="month"
        >
          MRT
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="month planned-date"
        >
          <ng-container *ngFor="let date of element.plannedSessions">
            <p
              [ngClass]="{
                done: date.ready,
                late: !date.ready && date.plannedDate < today
              }"
              *ngIf="date.plannedDate[5] == '0' && date.plannedDate[6] == '3'"
            >
              {{ date.plannedDate | safariDateFormat | date: 'dd-MM' }}
            </p>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="apr">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="month"
        >
          APR
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="month planned-date"
        >
          <ng-container *ngFor="let date of element.plannedSessions">
            <p
              [ngClass]="{
                done: date.ready,
                late: !date.ready && date.plannedDate < today
              }"
              *ngIf="date.plannedDate[5] == '0' && date.plannedDate[6] == '4'"
            >
              {{ date.plannedDate | safariDateFormat | date: 'dd-MM' }}
            </p>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="may">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="month"
        >
          MEI
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="month planned-date"
        >
          <ng-container *ngFor="let date of element.plannedSessions">
            <p
              [ngClass]="{
                done: date.ready,
                late: !date.ready && date.plannedDate < today
              }"
              *ngIf="date.plannedDate[5] == '0' && date.plannedDate[6] == '5'"
            >
              {{ date.plannedDate | safariDateFormat | date: 'dd-MM' }}
            </p>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="jun">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="month"
        >
          JUN
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="month planned-date"
        >
          <ng-container *ngFor="let date of element.plannedSessions">
            <p
              [ngClass]="{
                done: date.ready,
                late: !date.ready && date.plannedDate < today
              }"
              *ngIf="date.plannedDate[5] == '0' && date.plannedDate[6] == '6'"
            >
              {{ date.plannedDate | safariDateFormat | date: 'dd-MM' }}
            </p>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="jul">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="month"
        >
          JUL
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="month planned-date"
        >
          <ng-container *ngFor="let date of element.plannedSessions">
            <p
              [ngClass]="{
                done: date.ready,
                late: !date.ready && date.plannedDate < today
              }"
              *ngIf="date.plannedDate[5] == '0' && date.plannedDate[6] == '7'"
            >
              {{ date.plannedDate | safariDateFormat | date: 'dd-MM' }}
            </p>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="aug">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="month"
        >
          AUG
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="month planned-date"
        >
          <ng-container *ngFor="let date of element.plannedSessions">
            <p
              [ngClass]="{
                done: date.ready,
                late: !date.ready && date.plannedDate < today
              }"
              *ngIf="date.plannedDate[5] == '0' && date.plannedDate[6] == '8'"
            >
              {{ date.plannedDate | safariDateFormat | date: 'dd-MM' }}
            </p>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="sep">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="month"
        >
          SEP
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="month planned-date"
        >
          <ng-container *ngFor="let date of element.plannedSessions">
            <p
              [ngClass]="{
                done: date.ready,
                late: !date.ready && date.plannedDate < today
              }"
              *ngIf="date.plannedDate[5] == '0' && date.plannedDate[6] == '9'"
            >
              {{ date.plannedDate | safariDateFormat | date: 'dd-MM' }}
            </p>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="oct">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="month"
        >
          OKT
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="month planned-date"
        >
          <ng-container *ngFor="let date of element.plannedSessions">
            <p
              [ngClass]="{
                done: date.ready,
                late: !date.ready && date.plannedDate < today
              }"
              *ngIf="date.plannedDate[5] == '1' && date.plannedDate[6] == '0'"
            >
              {{ date.plannedDate | safariDateFormat | date: 'dd-MM' }}
            </p>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="nov">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="month"
        >
          NOV
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="month planned-date"
        >
          <ng-container *ngFor="let date of element.plannedSessions">
            <p
              [ngClass]="{
                done: date.ready,
                late: !date.ready && date.plannedDate < today
              }"
              *ngIf="date.plannedDate[5] == '1' && date.plannedDate[6] == '1'"
            >
              {{ date.plannedDate | safariDateFormat | date: 'dd-MM' }}
            </p>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="dec">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="month"
        >
          DEC
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="month planned-date"
        >
          <ng-container *ngFor="let date of element.plannedSessions">
            <p
              [ngClass]="{
                done: date.ready,
                late: !date.ready && date.plannedDate < today
              }"
              *ngIf="date.plannedDate[5] == '1' && date.plannedDate[6] == '2'"
            >
              {{ date.plannedDate | safariDateFormat | date: 'dd-MM' }}
            </p>
          </ng-container>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="columnsToDisplay"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
      ></tr>
    </table>
  </mat-expansion-panel>
</mat-accordion>
