/** @format */

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { FilesListComponent } from '../files-list/files-list.component';
import { FilesLibraryService } from '../../services/files-library.service';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DocumentsService } from '../../services/documents.service';
import { Attachment } from '../../models/attachment';
import { Observable } from 'rxjs';
import { UserValidatorService } from '../../services/user-validator.service';
import { map, startWith } from 'rxjs/operators';
import { AddEditFileComponent } from '../../dialogs/add-edit-file/add-edit-file.component';
import { PagedResult } from '../../models/paged-result';

/**
 *  Show the list of attached documents.
 *
 * @export
 * @class AttachmentsListEmbeddedComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-attachments-list-embedded',
  templateUrl: './attachments-list-embedded.component.html',
  styleUrls: ['./attachments-list-embedded.component.scss'],
})
export class AttachmentsListEmbeddedComponent
  extends FilesListComponent
  implements OnInit, OnChanges {
  /**
   *  True/false if the link/unlik should be disabled
   *
   * @type                    {boolean}
   * @memberof                AttachmentsListEmbeddedComponent
   */
  @Input() formDisabled: boolean;

  /**
   *  The attachments list passed from the parent component
   *
   * @type                    {any[]}
   * @memberof                AttachmentsListEmbeddedComponent
   */
  @Input() listData: any[];

  /**
   *  Bind the info to the parent when item is removed.
   *
   * @memberof                AttachmentsListEmbeddedComponent
   */
  @Output() itemUnlinked = new EventEmitter<Attachment>();

  /**
   *  Bind the info to the parent when the item is linked
   *
   * @memberof                AttachmentsListEmbeddedComponent
   */
  @Output() itemLinked = new EventEmitter<Attachment>();

  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                AttachmentsListEmbeddedComponent
   */
  columnsToDisplay = ['title', 'category', 'CUD'];

  /**
   *  Form Control of the attachments filter
   *
   * @type                  {FormControl}
   * @memberof              AttachmentsListEmbeddedComponent
   */
  filterControl: UntypedFormControl = new UntypedFormControl();

  /**
   *  The list of items that can be linked to the parent element
   *
   * @type                    {Attachment[]}
   * @memberof                AttachmentsListEmbeddedComponent
   */
  linkableItems: Observable<Attachment[]>;

  /**
   *  The selected item from the linkable list
   *
   * @type                    {Improvement}
   * @memberof                AttachmentsListEmbeddedComponent
   */
  selectedItem: Attachment;

  constructor(
    private filesLibraryServiceA: FilesLibraryService,
    private formBuilderA: UntypedFormBuilder,
    private mdialogA: MatDialog,
    private documentsServiceA: DocumentsService,
    public userValidatorService: UserValidatorService,
  ) {
    super(filesLibraryServiceA, formBuilderA, mdialogA, documentsServiceA);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof AttachmentsListEmbeddedComponent
   */
  ngOnInit() {
    this.dialogParameters.data.message = `Weet je zeker dat je dit bestand wilt verwijderen?`;

    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    if (this.currentUserRights.currentTeamId) {
      this.emptyFormValues.teamId = this.currentUserRights.currentTeamId;
    }

    this.queryParams.push({
      name: 'teamId',
      value: this.currentUserRights.currentTeamId,
    });

    if (!this.formDisabled) {
      this.getLinkableItems();
    }
  }

  /**
   * Respond when Angular (re)sets data-bound input properties.
   * Called before ngOnInit() and whenever one or more data-bound input properties change.
   *
   * @memberof AttachmentsListEmbeddedComponent
   */
  ngOnChanges() {
    this.dataSource.data = this.listData;

    if (this.filterControl) {
      this.filterControl.reset('');
    }
  }

  /**
   *  Function that maps an option's control value to its display value in the trigger.
   *
   * @param    {Improvement} [item]
   * @returns  {(string | undefined)}
   * @memberof AttachmentsListEmbeddedComponent
   */
  displayFn(item?: Attachment): string | undefined {
    return item ? item.title : undefined;
  }

  /**
   *  Get the list of linkable items
   *
   * @memberof AttachmentsListEmbeddedComponent
   */
  getLinkableItems() {
    this.serviceSubscription = this.filesLibraryServiceA
      .getAll(undefined, undefined, { createDate: 'desc' }, this.queryParams)
      .pipe(map((resp: PagedResult<Attachment>) => resp))
      .subscribe((result) => {
        let linkableitems = [];

        linkableitems = result.payload.filter(this.notYetLinked, this);

        this.linkableItems = this.filterControl.valueChanges.pipe(
          startWith(''),
          map((val) => {
            linkableitems = result.payload.filter(this.notYetLinked, this);

            if (typeof val === 'string' && val === '') {
              this.selectedItem = null;

              return linkableitems;
            } else if (typeof val === 'string' && val !== '') {
              return this.filterLinkableItems(linkableitems, val);
            } else if (typeof val === 'object' && val.subject) {
              return this.filterLinkableItems(linkableitems, val.subject);
            }
          }),
        );
      });
  }

  /**
   *  Filter the list of items.
   *
   * @param         {string}            val
   * @returns       {Attachment[]}
   * @memberof      AttachmentsListEmbeddedComponent
   */
  filterLinkableItems(itemsList: Attachment[], val: string): Attachment[] {
    return itemsList.filter(
      (option) => option.title.toLowerCase().indexOf(val.toLowerCase()) !== -1,
    );
  }

  /**
   * Check if the member of team is already an executioner of current improvement
   *
   * @param {*} element
   * @param {*} index
   * @param {*} array
   * @memberof AttachmentsListEmbeddedComponent
   */
  notYetLinked(element: any, index: any, array: any) {
    let exsists = false;

    if (this.listData && this.listData.length) {
      for (const item of this.listData) {
        if (element.id === item.id) {
          exsists = true;

          break;
        }
      }
    }

    return !exsists;
  }

  /**
   *  Respond to the user editing a single item.
   *
   * @param       {*}                         row       The current row in the data tabl
   * @param       {Event}                     [event]   The (`click`) event signalling the user's intent.
   * @memberof    AttachmentsListEmbeddedComponent
   */
  onEditIntent(row?: any, event?: Event) {
    let isNew = true;
    let formValues = <any>{};
    let fileOwner = false;

    if (row) {
      isNew = false;
    }
    if (event) {
      event.stopImmediatePropagation();
    }

    if (!row) {
      formValues = this.emptyFormValues;
      fileOwner = true;
    } else {
      if (this.currentUserRights.currentMember.id === row.ownerMemberId) {
        fileOwner = true;
      }

      const editedRowFormValues = {
        id: [row.id],
        isHidden: row.isHidden,
        docName: row.docName,
        docId: row.docId,
        description: [row.description],
        category: [row.category ? row.category : null],
        allowedMemberIds: [row.allowedMemberIds ? row.allowedMemberIds : []],
        fileSize: row.fileSize ? row.fileSize : '',
        fileType: row.fileType ? row.fileType : '',
        teamId: row.teamId ? row.teamId : this.currentUserRights.currentTeamId,
        title: [row.title, Validators.required],
      };

      formValues = editedRowFormValues;
    }

    const dialogRef = this.mdialogA.open(AddEditFileComponent, {
      data: {
        formValues: formValues,
        isNew: isNew,
        attachement: row,
        fileOwner: fileOwner,
        allowHidden: true,
      },
      disableClose: true,
      height: '900px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'primary-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        if (results.resp && results.resp.id && isNew) {
          this.onLinkIntent(results.resp);
        } else if (results.resp && results.resp.id && !isNew && this.listData) {
          for (let i = 0; i < this.listData.length; i++) {
            if (this.listData[i].id === results.resp.id) {
              this.listData[i] = results.resp;

              this.dataSource.connect().next(this.listData);
            }
          }
        }
      }
    });
  }

  /**
   *  Respond to the link intent
   *
   * @param    {*}     [element]
   * @param    {Event} [event]
   * @memberof AttachmentsListEmbeddedComponent
   */
  onLinkIntent(value?: any, event?: Event) {
    if (value) {
      this.itemLinked.emit(value);
    } else {
      this.itemLinked.emit(this.selectedItem);
    }
  }

  /**
   *  Respond to the selection of the linkable list
   *
   * @param       {Improvement}              option
   * @memberof    AttachmentsListEmbeddedComponent
   */
  onOptionSelect(option?: Attachment) {
    if (option && option.id) {
      this.selectedItem = option;
    } else {
      this.selectedItem = null;
    }
  }

  /**
   *  Respond to the delete intent of the imrpovement item.
   *
   * @memberof AttachmentsListEmbeddedComponent
   */
  onUnlinkIntent(row: any, event?: Event, dialog?: any) {
    this.dialogParameters.data.name = row.subject;

    if (event) {
      event.stopImmediatePropagation();
    }

    const deleteDialogRef = this.mdialogA.open(dialog, this.dialogParameters);
    deleteDialogRef.afterClosed().subscribe((results) => {
      if (!results) {
        return;
      }

      this.itemUnlinked.emit(row);
    });
  }
}
