/** @format */

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ImprovementGroup } from '../models/improvement-group';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError as _throw } from 'rxjs';

import { environment } from '../../environments/environment';
import { PagedResult } from '../models/paged-result';
import { PageSetting } from '../models/paged-setting';
import { BaseModel } from '../models/base-model';
import { AbstractRestService } from './iq-package-services/abstract-rest-service.service';

const apiPath = environment.apiPath;

/**
 *  Service for managing the Improvement group.
 *
 * @export
 * @class     ImprovementGroupService
 * @extends   {AbstractRestService<ImprovementGroup>}
 */
@Injectable()
export class ImprovementGroupService extends AbstractRestService<ImprovementGroup> {
  /**
   * Creates an instance of ImprovementGroupService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      ImprovementGroupService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}improvement-groups`, 'improvement-groups');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<ImprovementGroup}
   * @memberof        ImprovementGroupService
   */
  protected mapToModel(json: any): PagedResult<ImprovementGroup> {
    //  If the Team array doesn't exist, create an empty Team object
    let payload = [];

    if (json._embedded) {
      payload = <ImprovementGroup[]>json._embedded.improvementGroups;
    }

    return new PagedResult<ImprovementGroup>(payload, <PageSetting>json.page);
  }

  /**
   *  Delete the group with the teamId query param.
   *
   * @param     {BaseModel}               aModel
   * @param     {string}                  teamId
   * @returns   {Observable<T>}
   * @memberof  ImprovementItemsService
   */
  deleteGroup(aModel: BaseModel, teamId: string): Observable<ImprovementGroup> {
    return this.http
      .delete<ImprovementGroup>(
        `${this.actionUrl}/${aModel.id}?teamId=${teamId}`,
      )
      .pipe(catchError((err) => _throw(err)));
  }
}
