<!-- @format -->
<section>
  <input
    type="file"
    class="document-upload__input"
    (change)="onFileSelected($event)"
    #fileUpload
    multiple
  />

  <div class="document-upload__container">
    <div *ngIf="proofsRequirements?.length">
      <span>De volgende bijlagen zijn vereist:</span>
      <ul>
        <li *ngFor="let proofsRequirement of proofsRequirements">
          <span>{{ proofsRequirement }}</span>
        </li>
      </ul>
    </div>

    <p
      class="document-upload__missing-attachment-error"
      *ngIf="displayMissingAttachment$ | async"
    >
      Please upload missing files!
    </p>

    <button
      *ngIf="isUserIPFTeamAdminOrTeamAdmin"
      mat-flat-button
      color="primary"
      [disabled]="isFileUploadDisabled"
      (click)="fileUpload.click()"
    >
      <mat-icon>attach_file</mat-icon>
      <span> Bijlage toevoegen </span>
    </button>
  </div>
  <div
    class="document-upload__list"
    *ngIf="attachmentsList.length"
  >
    <div
      *ngFor="let attachment of attachmentsList"
      class="document-upload__list-item"
    >
      <div (click)="downloadFile(attachment)">
        {{ attachment.fileName || '(file without name)' }}
      </div>

      <mat-icon
        *ngIf="isUserIPFTeamAdminOrTeamAdmin && !isFileUploadDisabled"
        (click)="deleteFile(attachment)"
        >delete</mat-icon
      >
    </div>
  </div>

  <div
    class="document-upload__comments-container"
    [formGroup]="proofsForm"
  >
    <mat-form-field appearance="fill">
      <mat-label>Toelichting bij bijlage(n)</mat-label>
      <textarea
        matInput
        formControlName="explanation"
        matTextareaAutosize
        matAutosizeMinRows="1"
        resizeToFitContent
        rows="5"
      ></textarea>
    </mat-form-field>
  </div>
</section>
