import {
  Component,
  ChangeDetectionStrategy,
  OnInit
} from '@angular/core';

@Component({
  selector: 'main',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.html',

})
export class AppComponentC implements OnInit {
  public menuItems: any[] = [];

  constructor() {
  }

  closeSidenav() {

  }

  openSidenav() {
  }

  logout() {
    this.closeSidenav();

  }

  ngOnInit() {
    console.log('helllllloooo2222');
  }
}
