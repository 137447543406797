import { Component, OnInit, OnDestroy } from '@angular/core';
// import { User } from '../models/user';
// import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
// import { Credentials } from '../models/credentials';
import { AuthService } from '../../services/iq-auth-services/auth.service';
import { malConfigService } from '../../services/iq-auth-services/config.service';
import { Credentials } from '../../models/iq-models/credentials';
import { LoaderService } from '../../services/iq-package-services/loader.service';
// import { malConfigService } from '../services/config.service';

@Component({
  selector: 'auth-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  usere = {};
  usere2 = {};
  usere3 = '';
  /*
   ,
   apiPaths: {
   forgotPassword: '',
   login:          '',
   refreshToken:   '',
   user:           ''
   }
   */
  constructor(
    private authService: AuthService,
    private loader: LoaderService,
    private router: Router,
    private projCFG: malConfigService
  ) {
    this.usere = projCFG;
  }

  ngOnInit() {
    this.authService.logout();
    console.log('[Auth-Login] Logged out');
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  loadDone() {
    this.loader.display(false);
  }

  onSubmit($event: Credentials) {

    this.loader.display(true);

    this.subscription = this.authService.login($event)
      .subscribe(
        (data) => {
          console.log(data);
          return data;
        },
        (err) => {
          console.log($event, err);
          this.loadDone();
        },
        () => {
          this.loadDone();

          //  Just after login get the identity of the user
          //
          this.authService.id().subscribe(resp => {

            this.authService.saveState();
            console.log('[Auth-Login] Logged in');

            return this.router.navigate(['home']);
          });
        }
      );
  }

}
