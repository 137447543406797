/** @format */

import { Component, OnInit } from '@angular/core';
import { malSetPasswordFormComponent } from '../../iq-auth-components';

/**
 *  Set password form component
 *
 * @export
 * @class       KmsSetPasswordFormComponent
 * @extends     {malSetPasswordFormComponent}
 * @implements  {OnInit}
 */
@Component({
  selector: 'kms-auth-set-password-form',
  templateUrl: './set-password-form.component.html',
  styleUrls: ['./set-password-form.component.scss'],
})
export class KmsSetPasswordFormComponent
  extends malSetPasswordFormComponent
  implements OnInit { }
