import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators'
import { QueryParam } from '../../models/query-param';
import { PagedResult } from '../../models/paged-result';
import { PageSetting } from '../../models/paged-setting';
import { AbstractRestService } from '../../services/iq-package-services/abstract-rest-service.service';


@Component({
  selector: 'malAbsMatTableComponent',
  template: '< br>'
})

export abstract class MarviqMatTableComponent<T> implements OnInit, OnDestroy {

  /**
   * Service subscript for retrieval of table rows data.
   */
  serviceSubscription: Subscription;

  /**
   * The columns to display in the table
   */
  columnsToDisplay: string[];

  /**
   * The pager info received from the HTTP request
   */
  usersPageSetting: PageSetting = new PageSetting(5, 0, 0, 0);

  /**
   * A string, string key,value pair object for storing the currently filtered columns.
   */
  filterValues: ColumnValue = {};

  /**
   * A string, string key,value pair object for storing the currently sorted columns.
   */
  sortValues: ColumnValue = {};

  /**
   * A string, string key,value pair object for storing the queryParams.
   */
  queryParams: QueryParam[] = [];

  /**
   * The material paginator that is synced with the PageSetting returned from every request.
   */
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /**
   * The Angular Material Table component data source used for storing the data for the table rows.
   */
  dataSource = new MatTableDataSource();

  constructor(private apiService: AbstractRestService<T>) { }

  ngOnInit() {
    this.getRows();
  }

  pageRows(pageEvent: PageEvent) {
    this.usersPageSetting.size = pageEvent.pageSize;
    this.usersPageSetting.number = pageEvent.pageIndex;
    this.getRows();
  }

  sortRows(sortEvent: { column: string, value: number }) {
    if (this.sortValues.hasOwnProperty(sortEvent.column) && sortEvent.value === 0) {
      delete this.sortValues[sortEvent.column];
    }
    else {
      switch (sortEvent.value) {
        case 1:
          this.sortValues[sortEvent.column] = 'asc';
          break;
        default:
        case 1:
          this.sortValues[sortEvent.column] = 'desc';
      }
    }
    this.getRows();
  }

  filterRows(filterEvent: { column: string, value: string }) {
    this.filterValues[filterEvent.column] = filterEvent.value;
    this.getRows();
  }

  getRows() {
    this.serviceSubscription = this.apiService.getAll(this.usersPageSetting, this.filterValues, this.sortValues, this.queryParams)
      .pipe(map((resp: PagedResult<T>) => resp))
      .subscribe((result: any) => {
        this.dataSource.data = result.payload;
        if (result.page) {
          this.usersPageSetting = result.page;
        }
      });
  }

  ngOnDestroy() {
    this.serviceSubscription.unsubscribe();
  }

}

export interface ColumnValue {
  [key: string]: string;
}
