/** @format */

import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Attachment } from '../../models/attachment';
import { FilesLibraryService } from '../../services/files-library.service';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { WarningDeleteDialogComponent } from '../../dialogs/warning-delete-dialog/warning-delete-dialog.component';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { AddEditFileComponent } from '../../dialogs/add-edit-file/add-edit-file.component';
import { SharedService } from '../../services/shared.service';
import { DocumentsService } from '../../services/documents.service';
import { UserRights } from '../../models/user-rights';
import { UserValidatorService } from '../../services/user-validator.service';
import { PageSetting } from '../../models/paged-setting';
import { MarviqMatTableInlineCrudComponent } from '../iq-package-components/marviq-mat-table-inline-crud.component';

/**
 *  Show the list of files
 *
 * @export
 * @class FilesListComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.scss'],
})
export class FilesListComponent
  extends MarviqMatTableInlineCrudComponent<Attachment>
  implements OnInit, OnDestroy {
  /**
   *  The download link DOM element
   *
   * @private
   * @type                  {ElementRef}
   * @memberof              FilesListComponent
   */
  @ViewChild('downloadLink') private downloadLink: ElementRef;

  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                FilesListComponent
   */
  columnsToDisplay = [
    'title',
    'category',
    'fileType',
    'fileSize',
    'createDate',
    'CUD',
  ];

  /**
   *  The rights of the current user
   *
   * @type                    {UserRights}
   * @memberof                FilesListComponent
   */
  currentUserRights: UserRights;

  /**
   *  The dialog component assigned to the delete request.
   *
   * @memberof                FilesListComponent
   */
  deleteDialog = WarningDeleteDialogComponent;

  /**
   *  The default values of the new Form.
   *
   * @memberof                FilesListComponent
   */
  emptyFormValues = {
    title: ['', Validators.required],
    docName: '',
    docId: '',
    category: [null],
    description: [''],
    allowedMemberIds: [[], Validators.required],
    isHidden: false,
    fileSize: '',
    fileType: '',
    teamId: '',
  };

  /**
   *  The form group storing filters
   *
   * @type                    {FormGroup}
   * @memberof                FilesListComponent
   */
  filterForm: UntypedFormGroup;

  /**
   * The pager info received from the HTTP request
   *
   * @memberof                FilesListComponent
   */
  usersPageSetting: PageSetting = new PageSetting(25, 0, 0, 0);

  /**
   * Creates an instance of FilesListComponent.
   *
   * @param    {FilesLibraryService} filesLibraryService
   * @param    {FormBuilder}         formBuilder
   * @param    {MatDialog}           dialog
   * @memberof FilesListComponent
   */
  constructor(
    private filesLibraryService: FilesLibraryService,
    private formBuilder: UntypedFormBuilder,
    private mdialog: MatDialog,
    private documentsService: DocumentsService,
    public userValidatorService?: UserValidatorService,
    public sharedService?: SharedService,
  ) {
    super(filesLibraryService, formBuilder, mdialog);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof FilesListComponent
   */
  ngOnInit() {
    this.dialogParameters.data.message = `Weet je zeker dat je dit bestand wilt verwijderen?`;

    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    if (this.currentUserRights.currentTeamId) {
      this.emptyFormValues.teamId = this.currentUserRights.currentTeamId;
    }

    this.queryParams.push({
      name: 'teamId',
      value: this.currentUserRights.currentTeamId,
    });
    this.queryParams.push({ name: 'sort', value: 'createDate,desc' });

    this.createFilterForm();

    this.getRows();
  }

  /**
   *  Create the form for filters
   *
   * @memberof FilesListComponent
   */
  createFilterForm() {
    this.filterForm = this.formBuilder.group({
      name: '',
      category: '',
    });

    this.filterForm.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((changes) => {
        for (const field of Object.keys(changes)) {
          if (changes[field]) {
            this.filterValues[field] = changes[field];

            if (field === 'category' && changes[field] === 'uncategorized') {
              delete this.filterValues[field];

              this.queryParams = [
                { name: 'teamId', value: this.currentUserRights.currentTeamId },
                { name: 'uncategorized', value: true },
              ];
            } else if (
              field === 'category' &&
              changes[field] !== 'uncategorized'
            ) {
              this.queryParams = [
                { name: 'teamId', value: this.currentUserRights.currentTeamId },
              ];
            }
          } else {
            delete this.filterValues[field];
          }
        }
        this.usersPageSetting.number = 0;
        this.paginator.firstPage();
        this.getRows();
      });
  }

  /**
   *  Respond to the user editing a single item.
   *
   * @param       {*}                         row       The current row in the data tabl
   * @param       {Event}                     [event]   The (`click`) event signalling the user's intent.
   * @memberof    FilesListComponent
   */
  onEditIntent(row?: any, event?: Event) {
    let isNew = true;
    let formValues = <any>{};
    let fileOwner = false;

    if (row) {
      isNew = false;
    }
    if (event) {
      event.stopImmediatePropagation();
    }

    if (!row) {
      formValues = this.emptyFormValues;
      fileOwner = true;
    } else {
      if (this.currentUserRights.currentMember.id === row.ownerMemberId) {
        fileOwner = true;
      }

      const editedRowFormValues = {
        id: [row.id],
        isHidden: row.isHidden,
        docName: row.docName,
        docId: row.docId,
        description: [row.description],
        category: [row.category ? row.category : null],
        allowedMemberIds: [row.allowedMemberIds ? row.allowedMemberIds : []],
        fileSize: row.fileSize ? row.fileSize : '',
        fileType: row.fileType ? row.fileType : '',
        teamId: row.teamId ? row.teamId : this.currentUserRights.currentTeamId,
        title: [row.title, Validators.required],
      };

      formValues = editedRowFormValues;
    }

    const dialogRef = this.mdialog.open(AddEditFileComponent, {
      data: {
        formValues: formValues,
        isNew: isNew,
        attachement: row,
        fileOwner: fileOwner,
      },
      disableClose: true,
      height: '900px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'primary-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        this.getRows();
      }
    });
  }

  /**
   * Respond to the clear intent on filters
   *
   * @memberof FilesListComponent
   */
  onFiltersClear() {
    this.queryParams = [
      { name: 'teamId', value: this.currentUserRights.currentTeamId },
    ];

    this.filterForm.reset({
      name: '',
      category: '',
    });
  }

  /**
   *  Respond to the download file intent
   *
   * @param {*} [file]
   * @param {*} [event]
   * @memberof FilesListComponent
   */
  async onFileDownload(
    attachment?: Attachment,
    event?: any,
    fetchAndDownload?: boolean,
  ) {
    if (event) {
      event.stopPropagation();
    }

    let file = null;

    if (attachment && attachment.docId) {
      file = await this.documentsService.getSingleFile(
        '/' + attachment.docId,
        '',
      );

      if (fetchAndDownload) {
        // IE & Edge
        if ((window.navigator as any)?.msSaveOrOpenBlob) {
          // msSaveBlob only available for IE & Edge
          (window.navigator as any).msSaveBlob(file, attachment.docName);
        } else {
          const url = window.URL.createObjectURL(file);
          const link = this.downloadLink.nativeElement;
          link.href = url;

          link.download = 'file_KMS';

          if (attachment.docName) {
            link.download = attachment.docName;
          }

          link.click();
          window.URL.revokeObjectURL(url);
        }
      }
    }

    return file;
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof          FilesListComponent
   */
  ngOnDestroy() {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
  }
}
