import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Credentials } from '../../models/iq-models/credentials';

@Component({
  selector: 'auth-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  @Input() errorMessage: string | null;

  @Output() submitted = new EventEmitter<Credentials>();

  form: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl('', Validators.required),
    password: new UntypedFormControl('', Validators.required),
  });
  formBot: UntypedFormGroup = new UntypedFormGroup({
    check: new UntypedFormControl(''),
  });

  constructor() { }

  ngOnInit() { }

  submit() {
    if (this.form.valid) {
      const credentials: Credentials = new Credentials(this.form.controls.username.value, this.form.controls.password.value);
      this.submitted.emit(credentials);
    }
  }
}
