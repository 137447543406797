/** @format */

import { Component, OnInit, ChangeDetectorRef, OnDestroy, inject } from '@angular/core';
import { UserRights } from '../../models/user-rights';
import { UserValidatorService } from '../../services/user-validator.service';
import { Team } from '../../models/team';
import { SubThemeOwnership } from '../../models/subtheme-ownership';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SubthemeOwnershipService } from '../../services/subtheme-ownership.service';
import { TeamsListService } from '../../services/teams-list.service';
import { Observable, Subject } from 'rxjs';
import { delay, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { Theme } from '../../models/theme';
import { SharedService } from '../../services/shared.service';
import { Member } from '../../models/member';
import { AddEditSubthemeOwnershipComponent } from '../../dialogs/add-edit-subtheme-ownership/add-edit-subtheme-ownership.component';
import { MembersService } from '../../services/members.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { WarningDeleteDialogComponent } from '../../dialogs/warning-delete-dialog/warning-delete-dialog.component';
import { ErrorDialogComponent } from '../../dialogs/error-dialog/error-dialog.component';
import { CacheUpdateService } from '../../services/cache-update.service';
import { LampService } from '../../services/lamp.service';
import { PagedResult } from '../../models/paged-result';
import { MarviqMatTableInlineCrudComponent } from '../iq-package-components/marviq-mat-table-inline-crud.component';

/**
 *  Display an overview of subtheme owners.
 *
 * @export
 * @class       SubthemeOwnersListComponent
 * @extends     {MarviqMatTableInlineCrudComponent<User>}
 * @implements  {OnInit}
 */
@Component({
  selector: 'app-subtheme-owners-list',
  templateUrl: './subtheme-owners-list.component.html',
  styleUrls: ['./subtheme-owners-list.component.scss'],
})
export class SubthemeOwnersListComponent
  extends MarviqMatTableInlineCrudComponent<SubThemeOwnership>
  implements OnInit, OnDestroy {
  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                UsersListComponent
   */
  columnsToDisplay = ['themeName', 'subThemeName', 'owner1', 'owner2', 'CUD'];

  /**
   *  The current user rights.
   *
   * @type                  {UserRights}
   * @memberof              SubthemeOwnersListComponent
   */
  currentUserRights: UserRights;

  /**
   *  The list of teams that user is a member.
   *
   * @type                  {Team[]}
   * @memberof              SubthemeOwnersListComponent
   */
  currentUserTeams: Team[];

  /**
   *  The default values of the new Form.
   *
   * @memberof                SubthemeOwnersListComponent
   */
  emptyFormValues = {
    theme: [null, Validators.required],
    subTheme: [null, Validators.required],
    team: <Team>{},
    owner1: [null],
    owner2: [null],
  };

  /**
   *  Form Control of the filter teams
   *
   * @type                  {FormControl}
   * @memberof              SubthemeOwnersListComponent
   */
  filterControl: UntypedFormControl = new UntypedFormControl();

  /**
   *  Filtered list of teams
   *
   * @type                  {Observable<Team[]>}
   * @memberof              UsersListComponent
   */
  filteredTeams: Observable<Team[]>;

  /**
   *  The currently selected team from the select list.
   *
   * @type                  {Team}
   * @memberof              SubthemeOwnersListComponent
   */
  selectedTeam: Team;

  /**
   *  The list of themes in the organisation
   *
   * @type                  {Theme[]}
   * @memberof              SubthemeOwnersListComponent
   */
  themesList: Theme[];

  /**
   *  The list of teamMembers
   *
   * @type                  {Member[]}
   * @memberof              SubthemeOwnersListComponent
   */
  currentTeamMembers: Member[];

  /**
   *  mobile Query
   *
   * @memberof    SubthemeOwnersListComponent
   */
  mobileQuery: MediaQueryList;
  isUserOfficeManagerOrQualityManager: boolean;

  errorDialogParameters = <any>{
    panelClass: 'delete-dialog',
    maxWidth: '800px',
    disableClose: true,
    data: {},
  };

  initCallForDefaultThemesFinished = false;

  deleteDialog = WarningDeleteDialogComponent;
  errorDialog = ErrorDialogComponent;


  /**
   *  The listener for mobileQuery changes
   *
   * @private
   * @memberof SubthemeOwnersListComponent
   */
  private _mobileQueryListener: (change: any) => void;

  /**
   * Creates an instance of SubthemeOwnersListComponent.
   *
   * @param     {UserValidatorService}          userValidatorService
   * @param     {SharedService}                 sharedService
   * @param     {SubthemeOwnershipService}      subThemeOwnershipService
   * @param     {TeamsListService}              teamsListService
   * @param     {FormBuilder}                   formBuilder
   * @param     {MatDialog}                     dg
   * @param     {MatDialog}                     editDialog
   * @memberof SubthemeOwnersListComponent
   */
  constructor(
    private userValidatorService: UserValidatorService,
    private sharedService: SharedService,
    private subThemeOwnershipService: SubthemeOwnershipService,
    private teamsListService: TeamsListService,
    private membersService: MembersService,
    private formBuilder: UntypedFormBuilder,
    private dg: MatDialog,
    private editDialog: MatDialog,
    private media: MediaMatcher,
    private ref: ChangeDetectorRef,
  ) {
    super(subThemeOwnershipService, formBuilder, dg);
  }

  #cacheUpdateService = inject(CacheUpdateService);
  #lampService = inject(LampService);
  #destroyRef = new Subject<void>();

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof      SubthemeOwnersListComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.isUserOfficeManagerOrQualityManager =
      this.currentUserRights.currentMember.teamRole.name === 'OFFICE_MANAGER' ||
      this.currentUserRights.currentMember.teamRole.name === 'QUALITY_MANAGER';

    this.queryParams = [
      { name: 'teamId', value: this.currentUserRights.currentTeamId },
    ];

    this.sharedService.currentThemesList.subscribe((result) => {
      if (result) {
        this.themesList = result;
      }
    });

    this.currentTeamMembers =
      this.sharedService.currentTeamMembersList.getValue();

    this.emptyFormValues.team = this.currentUserRights.currentMember.team;
    this.selectedTeam = this.currentUserRights.currentMember.team;

    //  Mobile query
    this.mobileQuery = this.media.matchMedia('( max-width: 768px )');
    this._mobileQueryListener = (change: any) => this.ref.detectChanges();

    this.mobileQuery.addListener(this._mobileQueryListener);

    //  Get currentUserTeams
    //
    if (
      this.currentUserRights.organisationAdmin ||
      this.isUserOfficeManagerOrQualityManager
    ) {
      this.teamsListService
        .getAll(undefined, undefined, { name: 'asc' }, undefined)
        .subscribe((response) => {
          this.currentUserTeams = response.payload;

          this.filteredTeams = this.filterControl.valueChanges.pipe(
            startWith(''),
            map((val) => {
              if (val === '') {
                this.onTeamSelect(this.currentUserRights.currentMember.team);

                this.queryParams = [
                  {
                    name: 'teamId',
                    value: this.currentUserRights.currentTeamId,
                  },
                ];

                return this.currentUserTeams;
              } else {
                return this.teamsListService.filterTeam(
                  this.currentUserTeams,
                  val,
                );
              }
            }),
          );
        });
    }

    this.#cacheUpdateService.updateThemesList();

    this.errorDialogParameters.data.message = `Something went wrong. Please try again.`; // not sure if we need this

    this.sharedService.currentThemesWithIds$.pipe(takeUntil(this.#destroyRef)).subscribe(list => {
      this.getRows();
    })
  }

  /**
   *  Overrides the getRows() method from MarviqMatTable component
   *  Get all rows for the table view
   *
   * @memberof    UsersListComponent
   */
  getRows() {
    this.serviceSubscription = this.subThemeOwnershipService
      .getAll(undefined, undefined, undefined, this.queryParams)
      .pipe(map((resp: PagedResult<SubThemeOwnership>) => resp))
      .subscribe((result) => {
        const results = result.payload;
        this.dataSource = new MatTableDataSource();

        for (const theme of this.themesList) {
          if (theme._embedded) {
            for (const subTheme of theme._embedded.subThemes) {
              const row = {
                theme: theme,
                subTheme: subTheme,
                team: this.selectedTeam,
                owner1: <Member>{},
                owner2: <Member>{},
                isNew: true,
                ownershipId: '',
              };

              if (results.length > 0) {
                for (const ownership of results) {
                  if (
                    ownership._embedded &&
                    ownership._embedded.subTheme.id === subTheme.id
                  ) {
                    if (ownership._embedded.members) {
                      row.owner1 = ownership._embedded.members[0];
                      row.owner2 = ownership._embedded.members[1];
                    } else if (ownership._embedded.member) {
                      row.owner1 = ownership._embedded.member;
                      row.owner2 = null;
                    } else {
                      row.owner1 = null;
                      row.owner2 = null;
                    }

                    row.ownershipId = ownership.id;
                    row.isNew = false;
                  }
                }
              }

              this.dataSource.data.push(row);
            }
          }
        }

        if (!this.initCallForDefaultThemesFinished && !this.dataSource.data.length) {
          this.initCallForDefaultThemesFinished = true;
          this.#lampService.createDefaultThemes().pipe(tap(() => {
            this.#cacheUpdateService.updateThemesList();
          })).subscribe();
        }

        if (result.page) {
          this.usersPageSetting = result.page;
        }
      });
  }

  /**
   *  Overrides the onEditIntent() method from the parent component
   *  Respond to the user editing a single item.
   *
   * @param         {*}               row
   * @param         {Event}           [event]
   * @returns
   * @memberof      UsersListComponent
   */
  onEditIntent(row?: any, event?: Event) {
    let isNew = true;
    let formValues = <any>{};

    if (row) {
      isNew = row.isNew;
    }

    if (event) {
      event.stopImmediatePropagation();
    }

    if (isNew) {
      formValues = this.emptyFormValues;

    } else {
      const editedRowFormValues = {
        id: row.ownershipId,
        team: row.team,
        theme: row.theme.name,
        subTheme: row.subTheme.name,
        owner1: [row.owner1, Validators.required],
        owner2: [row.owner2],
      };

      formValues = editedRowFormValues;
    }

    const dialogRef = this.editDialog.open(AddEditSubthemeOwnershipComponent, {
      data: {
        formValues: formValues,
        isNew: isNew,
        membersList: this.currentTeamMembers,
        theme: row?.theme || null,
        subTheme: row?.subTheme || null
      },
      disableClose: true,
      height: '500px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: ['primary-dialog', 'subtheme-owners-dialog'],
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        this.#cacheUpdateService.updateThemesList();
      }
    });
  }

  onDeleteIntent(row: any, event?: Event, dialog?: any) {
    this.dialogParameters.data.name = 'Weet je zeker dat je dit thema wilt verwijderen?';
    this.errorDialogParameters.data.name = 'Error occured'; // not sure if we need this

    if (event) {
      event.stopImmediatePropagation();
    }

    if (dialog) {
      const dialogRef = this.dg.open(dialog, this.dialogParameters);

      dialogRef.afterClosed().subscribe((results) => {
        if (results) {
          this.subThemeOwnershipService.deleteTheme(row.theme.id).subscribe(() => {
            this.#cacheUpdateService.updateThemesList();
          })
          return;
        }
      });
    }
  }

  /**
   *  Respond to the selection of the Team in team-select list
   *
   * @param       {Team}              team
   * @memberof    SubthemeOwnersListComponent
   */
  onTeamSelect(team: Team) {
    if (
      !(
        this.currentUserRights.organisationAdmin ||
        this.isUserOfficeManagerOrQualityManager
      )
    ) {
      return;
    }

    this.selectedTeam = team;
    this.queryParams = [{ name: 'teamId', value: team.id }];

    this.emptyFormValues.team = this.selectedTeam;

    this.membersService
      .getAll(undefined, undefined, undefined, this.queryParams)
      .subscribe((memberslist) => {
        this.currentTeamMembers = memberslist.payload;
      });

    this.getRows();
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof SubthemeOwnersListComponent
   */
  ngOnDestroy() {
    this.serviceSubscription.unsubscribe();
    this.ref.detach();
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.#destroyRef.next();
    this.#destroyRef.complete();
  }
}
