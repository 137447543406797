<mat-card>
  <span i18n="The title of the login window@@auth.loginWindowTitle">Login</span>
  <form [formGroup]="form" >
    <mat-form-field>
      <mat-label>Username</mat-label>
      <input matInput placeholder="Jan Kowalski" formControlName="username" required>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput type="password" placeholder="*********" formControlName="password" required>
    </mat-form-field>

    <button mat-button (click)="submit()"[disabled]="form.invalid || form.disabled" type="submit">
    <i class='fa fa-circle-o-notch fa-spin'></i>
    Login
    </button>
    <button mat-button routerLink='/auth/password/forgot'>
      <i class='fa fa-circle-o-notch fa-spin'></i>
      Forgot password
    </button>
  </form>
<mat-horizontal-stepper #stepper="matHorizontalStepper">




  <!--<mat-step [stepControl]="form">-->
    <!--<form [formGroup]="form">-->
      <!--<ng-template matStepLabel i18n="Label for the username input field@@auth.lblUsername">Fill out your username</ng-template>-->
      <!--<mat-form-field>-->
        <!--<input matInput placeholder="Username, nickname" formControlName="username" required>-->
      <!--</mat-form-field>-->
      <!--<div>-->
        <!--<button mat-button matStepperNext>Next</button>-->
      <!--</div>-->
    <!--</form>-->
  <!--</mat-step>-->
  <!--<mat-step [stepControl]="form">-->
    <!--<form [formGroup]="form">-->
      <!--<ng-template matStepLabel i18n="Label for the password input field@@auth.lblPassword'">Fill out your password</ng-template>-->
      <!--<mat-form-field>-->
        <!--<input matInput placeholder="***********" formControlName="password" required>-->
      <!--</mat-form-field>-->
      <!--<div>-->
        <!--<button mat-button matStepperPrevious>Back</button>-->
        <!--<button mat-button matStepperNext>Next</button>-->
      <!--</div>-->
    <!--</form>-->
  <!--</mat-step>-->
  <!--<mat-step>-->
    <!--<ng-template matStepLabel>Done</ng-template>-->
    <!--You are now done.-->
    <!--<div>-->
      <!--<button mat-button matStepperPrevious >Back</button>-->
      <!--<button mat-button (click)="stepper.reset()">Reset</button>-->
      <!--<button mat-button (click)="submit()"[disabled]="form.invalid || form.disabled" type="submit" i18n="Login button text@@auth.btnLogin">-->
        <!--<i class='fa fa-circle-o-notch fa-spin'></i>-->
        <!--Login-->
      <!--</button>-->
    <!--</div>-->
  <!--</mat-step>-->
</mat-horizontal-stepper>
</mat-card>
