import { Component, OnInit } from '@angular/core';
import * as Auth from '../../services/iq-auth-services/auth.service';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginPageComponent } from './login-page.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'auth-set-password-page',
  templateUrl: './set-password-page.component.html',
  styleUrls: ['./set-password-page.component.scss']
})
export class SetPasswordPageComponent implements OnInit {


  /**
   *  The set password token
   *
   * @memberof  SetPasswordPageComponent
   */
  token: Observable<string>;


  /**
   *  Subscription of the auth service.
   *
   * @memberof SetPasswordPageComponent
   */
  authSubs: Subscription;


  /**
   * Creates an instance of SetPasswordPageComponent.
   *
   * @param router
   * @param auth
   * @param rout
   * @memberof  SetPasswordPageComponent
   */
  constructor(private router: Router, private auth: Auth.AuthService, private rout: ActivatedRoute) {

  }


  ngOnInit() {
    this.token = this.rout.snapshot.queryParams['token'];
  }


  onSubmit(setData: any) {

    this.authSubs = this.auth.setPassword(setData.token, setData.password)
      .subscribe(
        (data) => {
          return this.router.navigate(['home']);
        },
        err => console.log(err),
        () => console.log('[Auth-Set] Update request sent')
      );
  }

}
