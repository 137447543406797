/** @format */

import { Component, OnInit } from '@angular/core';
import { DefaultPmrItem } from '../../models/pmr-item-default';
import { UserRights } from '../../models/user-rights';
import {
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import { PmrItemsDefaultService } from '../../services/pmr-items-default.service';
import { MatDialog } from '@angular/material/dialog';
import { UserValidatorService } from '../../services/user-validator.service';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { WarningDeleteDialogComponent } from '../../dialogs/warning-delete-dialog/warning-delete-dialog.component';
import { AddEditDefaultPmrItemComponent } from '../../dialogs/add-edit-default-pmr-item/add-edit-default-pmr-item.component';
import { PageSetting } from '../../models/paged-setting';
import { MarviqMatTableInlineCrudComponent } from '../iq-package-components/marviq-mat-table-inline-crud.component';

/**
 *  Show the default pmr items ( only for organisation admin )
 *
 * @export
 * @class PmrDefaultItemsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-pmr-default-items',
  templateUrl: './pmr-default-items.component.html',
  styleUrls: ['./pmr-default-items.component.scss'],
})
export class PmrDefaultItemsComponent
  extends MarviqMatTableInlineCrudComponent<DefaultPmrItem>
  implements OnInit {
  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                PmrDefaultItemsComponent
   */
  columnsToDisplay = ['title', 'description', 'CUD'];

  /**
   *  The current user rights.
   *
   * @type                    {UserRights}
   * @memberof                PmrDefaultItemsComponent
   */
  currentUserRights: UserRights;

  /**
   *  The dialog component assigned to the delete request.
   *
   * @memberof                PmrDefaultItemsComponent
   */
  deleteDialog = WarningDeleteDialogComponent;

  /**
   *  The default values of the new Form.
   *
   * @memberof                PmrDefaultItemsComponent
   */
  emptyFormValues = {
    description: [''],
    title: ['', Validators.required],
    sequence: [0],
    teamId: '',
  };

  /**
   *  The filters form
   *
   * @type                    {FormGroup}
   * @memberof                PmrDefaultItemsComponent
   */
  filterForm: UntypedFormGroup;

  /**
   * The pager info received from the HTTP request
   *
   * @memberof                PmrDefaultItemsComponent
   */
  usersPageSetting: PageSetting = new PageSetting(25, 0, 0, 0);

  /**
   * Creates an instance of PmrDefaultItemsComponent.
   *
   * @param    {PmrItemsDefaultService} pmrItemsDefaultService
   * @param    {FormBuilder}            formBuilder
   * @param    {MatDialog}              dg
   * @memberof PmrDefaultItemsComponent
   */
  constructor(
    private pmrItemsDefaultService: PmrItemsDefaultService,
    private formBuilder: UntypedFormBuilder,
    private dg: MatDialog,
    private userValidatorService: UserValidatorService,
  ) {
    super(pmrItemsDefaultService, formBuilder, dg);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof PmrDefaultItemsComponent
   */
  ngOnInit() {
    this.sortValues = { createDate: 'desc' };
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.usersPageSetting.size = 25;

    if (this.currentUserRights.currentTeamId) {
      this.queryParams.push({
        name: 'teamId',
        value: this.currentUserRights.currentTeamId,
      });

      this.emptyFormValues.teamId = this.currentUserRights.currentTeamId;
    }

    this.dialogParameters.data.message = `Weet je zeker dat je dit PMR item wilt verwijderen?`;

    this.addFiltersForm();

    this.getRows();
  }

  /**
   *  Add and subscribe the form group for list filters
   *
   * @memberof PmrDefaultItemsComponent
   */
  addFiltersForm() {
    this.filterForm = this.formBuilder.group({
      title: '',
    });

    this.filterForm.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((changes) => {
        for (const field of Object.keys(changes)) {
          this.filterValues[field] = changes[field];
        }

        this.usersPageSetting.number = 0;
        this.paginator.firstPage();
        this.getRows();
      });
  }

  /**
   *  Respond to the user editing a single item.
   *
   * @param       {*}                         row       The current row in the data tabl
   * @param       {Event}                     [event]   The (`click`) event signalling the user's intent.
   * @memberof    PmrDefaultItemsComponent
   */
  onEditIntent(row?: any, event?: Event) {
    let isNew = true;
    let formValues = <any>{};

    if (event) {
      event.stopImmediatePropagation();
    }

    if (!row) {
      formValues = this.emptyFormValues;
    } else {
      isNew = false;

      const editedRowFormValues = {
        id: [row.id],
        description: [row.description],
        title: [row.title, Validators.required],
        sequence: [row.sequence],
        teamId: row.teamId ? row.teamId : this.currentUserRights.currentTeamId,
      };

      formValues = editedRowFormValues;
    }

    const dialogRef = this.dg.open(AddEditDefaultPmrItemComponent, {
      data: { formValues: formValues, isNew: isNew, pmrItem: row },
      disableClose: true,
      height: '500px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'primary-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        this.getRows();
      }
    });
  }
}
