import { Component, OnDestroy, OnInit } from '@angular/core';
import { SunburstGraphService } from "../../services/sunburst-graph.service";
import { take, takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { CHART_TYPES } from "../../models/sunburst";

export interface TooltipData {
  id: string;
  improvementId: string;
  indicator: string;
  title: string;
  documentationUrl?: string;
  link?: string;
  linkText?: string;
}

@Component({
  selector: 'app-sunburst-graph-tooltip',
  templateUrl: './sunburst-graph-tooltip.component.html',
  styleUrls: ['./sunburst-graph-tooltip.component.scss'],
})
export class SunburstGraphTooltipComponent implements OnInit, OnDestroy {
  #destroyRef = new Subject<void>();

  currentGraphType: CHART_TYPES;
  tooltipData: TooltipData;
  isTooltipVisible = true;
  showExternalLink = false; // only for quality graph

  readonly CHART_TYPES = CHART_TYPES;

  constructor(private sunburstGraphService: SunburstGraphService, private router: Router) { }

  ngOnInit(): void {
    this.#checkForTooltipData();
    this.closeTooltip();
    this.showExternalLink = this.router.url.includes(CHART_TYPES.QUALITY) || this.router.url.includes(CHART_TYPES.COMPLIANCY);
    this.#setCurrentGraphType();

    if (this.router.url.includes(CHART_TYPES.INNOVATION)) {
      this.#showInitialMessage();
    }
  }

  ngOnDestroy(): void {
    this.#destroyRef.next();
    this.#destroyRef.complete();
  }

  #showInitialMessage(): void {
    this.sunburstGraphService.rawGraphData$.pipe(take(2), tap(data => {
      if (data?.innovationGraph) {
        const { message, title, link, linkText } = data.innovationGraph;

        if (message && title) {
          this.sunburstGraphService.tooltipData$.next(
            {
              id: null,
              improvementId: null,
              title,
              indicator: message,
              link,
              linkText
            }
          );
        }

      }
    })).subscribe();
  }

  #setCurrentGraphType(): void {
    const graphTypes: CHART_TYPES[] = [CHART_TYPES.INNOVATION, CHART_TYPES.QUALITY, CHART_TYPES.COMPLIANCY];
    this.currentGraphType = graphTypes.find(graphType => this.router.url.includes(graphType));
  }

  #checkForTooltipData(): void {
    this.sunburstGraphService.tooltipData$.pipe(takeUntil(this.#destroyRef), tap(val => {
      const shouldOpenTooltip = val && !this.isTooltipVisible;
      const shouldCloseTooltip = !val && this.isTooltipVisible;

      if (shouldOpenTooltip || shouldCloseTooltip) {
        this.#triggerTooltipVisibility();
      }

      if (this.isTooltipVisible) {
        this.tooltipData = val;
      }

    })).subscribe()
  }

  #triggerTooltipVisibility(): void {
    this.isTooltipVisible = !this.isTooltipVisible;
  }

  closeTooltip(): void {
    this.sunburstGraphService.tooltipData$.next(null);
  }

  navigateToCreatePractice(): void {
    this.router.navigate(['improvements/improvements-overview'], { state: { sunburstCellData: this.tooltipData, graphType: this.currentGraphType } });
  };

  navigateToPractice(): void {
    this.router.navigate(['improvements/improvements-overview'], { state: { improvementId: this.tooltipData.improvementId } }); //'2658092a-959f-4f88-8f20-6df4b629b591'
  };

  navigateToExternalPage(): void {
    window.open(this.tooltipData.documentationUrl, '_blank');
  }

  navigateToContactForm(): void {
    this.router.navigate(['graphs/contact-coach']);
  };

  navigateToIPFPartnerExternalPage(): void {
    window.open(this.tooltipData.link, '_blank');
  }
}
