import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'iqc-user-panel',
  template: `
    <div class="user-panel" color="primary">

      <div class="inline-user">
        <ng-content select="[avatar]">
        </ng-content>

        <div class="user">{{ nprefixMsg }} {{ nuser }}</div>
      </div>

      <div *ngIf="nmenuVersion != 'single'" class="inline-user">
        <mat-icon aria-label="expand" [matMenuTriggerFor]="menu">keyboard_arrow_down</mat-icon>
        <mat-menu #menu="matMenu" [overlapTrigger]="false">
          <iqc-menu-hor-item links="{{ nLinks }}" filterBy="{{ nfilterBy }}" filterValue="{{ nfilterValue }}">
          </iqc-menu-hor-item>
        </mat-menu>
      </div>
      <div *ngIf="nmenuVersion == 'single'">
        <iqc-menu-hor-item links="{{ nLinks }}" filterBy="{{ nfilterBy }}" filterValue="{{ nfilterValue }}">
        </iqc-menu-hor-item>
      </div>

    </div>
  `,
  styles: [
    `
      .example-icon {
        padding: 0 14px;
      }

      .example-spacer {
        flex: 1 1 auto;
      }

      .inline-user {

        float:  left;
      }
    `,
  ],
})

/** TODO: make multi-dropdown menu **/
export class UserPanelComponent implements OnInit {
  @Input() prefixMsg = '';
  @Input() user = '';
  @Input() menuVersion = '';
  @Input() filterBy = '';
  @Input() filterValue = '';
  @Input() links = [];


  public nprefixMsg = '';
  public nuser = '';
  public nmenuVersion = '';
  public nfilterBy = '';
  public nfilterValue = '';
  public nLinks = [];


  constructor() { }

  ngOnInit() {

    this.nprefixMsg = this.prefixMsg;
    this.nuser = this.user;
    this.nmenuVersion = this.menuVersion;
    this.nfilterBy = this.filterBy;
    this.nfilterValue = this.filterValue;
    this.nLinks = this.links;

  }

}
