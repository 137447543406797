<!-- @format -->

<h2
  mat-dialog-title
  i18n="add-edit-user-dialog dialog-title"
  *ngIf="formIsNew"
>
  Nieuwe gebruiker aanmaken
</h2>
<h2
  mat-dialog-title
  i18n="add-edit-user-dialog dialog-title"
  *ngIf="!formIsNew"
>
  Wijzigen gebruiker
</h2>

<mat-dialog-content *ngIf="initialFormValues">
  <div class="member-info flex-rows">
    <div class="pseudo-form-field team-group">
      {{ initialFormValues.team?.group }}
    </div>

    <div class="pseudo-form-field team-name">
      {{ initialFormValues.team?.name }}
    </div>
  </div>

  <div class="email-address">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Emailadres</mat-label>
        <input
          type="text"
          matInput
          formControlName="emailAddress"
          [readonly]="!data.isNew"
          required
        />
        <mat-error [hidden]="!formErrors.emailAddress">{{
          formErrors.emailAddress
        }}</mat-error>
      </mat-form-field>
    </form>
  </div>

  <div class="flex-rows">
    <form
      [formGroup]="newFormGroup"
      class="user-fullname"
    >
      <mat-form-field appearance="fill">
        <mat-label>Voornaam</mat-label>
        <input
          type="text"
          matInput
          formControlName="firstName"
          required
        />
        <mat-error [hidden]="!formErrors.firstName">{{
          formErrors.firstName
        }}</mat-error>
      </mat-form-field>
    </form>

    <form
      [formGroup]="newFormGroup"
      class="user-fullname"
    >
      <mat-form-field appearance="fill">
        <mat-label>Achternaam</mat-label>
        <input
          type="text"
          matInput
          formControlName="lastName"
          required
        />
        <mat-error [hidden]="!formErrors.lastName">{{
          formErrors.lastName
        }}</mat-error>
      </mat-form-field>
    </form>
  </div>

  <div class="team-role">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Rol</mat-label>
        <mat-select
          [readOnly]="data.isAuditorBeingAssigned"
          formControlName="teamRole"
          required
          [compareWith]="compareFn"
        >
          <mat-option
            *ngFor="
              let teamRole of data.isAuditorBeingAssigned
                ? [auditorTeamRole]
                : (newUserRoles$ | async)
            "
            [value]="teamRole"
          >
            {{ teamRole.description }}
          </mat-option>
        </mat-select>
        <mat-error [hidden]="!formErrors.teamRole">{{
          formErrors.teamRole
        }}</mat-error>
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  [matTooltip]="tooltipMessage"
  matTooltipClass="errors-list-tooltip"
  matTooltipPosition="above"
  [matTooltipDisabled]="!newFormGroup.invalid"
>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    (click)="onCancel()"
    i18n="cancel button @@buttonCancel"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="!formSubmitted"
    (click)="onSubmit()"
    [disabled]="!formChanged || newFormGroup.invalid"
    i18n="save button @@buttonSave"
  >
    Opslaan
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="formSubmitted"
    class="submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
  <button
    mat-icon-button
    mat-dialog-close
    class="dialog-action-close"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-dialog-actions>
