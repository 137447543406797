/** @format */

import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { KmsForgotPasswordPageComponent } from './containers/auth/forgot-password/forgot-password-page.component';
import { KmsSetPasswordPageComponent } from './containers/auth/set-password/set-password-page.component';
import { KmsResetPasswordPageComponent } from './containers/auth/reset-password/reset-password-page.component';
import { KmsLoginPageComponent } from './containers/auth/login-page/login-page.component';
import { KmsLoginFormComponent } from './components/auth/login-form/login-form.component';
import { KmsForgotPasswordFormComponent } from './components/auth/forgot-password/forgot-password-form.component';
import { KmsResetPasswordFormComponent } from './components/auth/reset-password/reset-password-form.component';
import { KmsSetPasswordFormComponent } from './components/auth/set-password/set-password-form.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderService } from './services/iq-package-services/loader.service';
import { CoreModule } from './iq-core.module';
import { IqAuthModule } from './iq-auth.module';

const MAT_MODULES = [
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatStepperModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
];

@NgModule({
  declarations: [
    KmsLoginPageComponent,
    KmsLoginFormComponent,
    KmsForgotPasswordPageComponent,
    KmsForgotPasswordFormComponent,
    KmsResetPasswordPageComponent,
    KmsResetPasswordFormComponent,
    KmsSetPasswordPageComponent,
    KmsSetPasswordFormComponent,
  ],
  imports: [
    MAT_MODULES,

    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    CoreModule,
    IqAuthModule,
    FlexLayoutModule,

    RouterModule.forChild([
      {
        path: 'auth',
        data: {
          group: 'auth',
          level: 0,
          name: 'User',
          safeLevel: 0,
        },
        children: [
          {
            path: '',
            redirectTo: '/auth/login',
            pathMatch: 'full',
          },
          {
            path: 'password',
            children: [
              {
                path: 'forgot',
                component: KmsForgotPasswordPageComponent,
                data: {
                  group: 'auth',
                  level: 1,
                  name: 'Forgot password',
                  safeLevel: 0,
                },
              },
              {
                path: 'set',
                component: KmsSetPasswordPageComponent,
                data: {
                  group: 'auth',
                  level: 1,
                  name: 'Set password - manual',
                  safeLevel: 0,
                },
              },
              {
                path: 'set/:token',
                component: KmsSetPasswordPageComponent,
                data: {
                  group: 'auth',
                  level: 1,
                  name: 'Set Password',
                  safeLevel: 0,
                },
              },
              {
                path: 'reset',
                component: KmsResetPasswordPageComponent,
                data: {
                  group: 'auth',
                  level: 1,
                  name: 'Reset password',
                  safeLevel: 0,
                },
              },
            ],
          },
          {
            path: 'login',
            component: KmsLoginPageComponent,
            data: {
              group: 'auth',
              level: 1,
              name: 'Login',
              safeLevel: 0,
            },
          },
          // {
          //   path: 'sign-up',
          //   component: SignUpPageComponent,
          //   data: {
          //     group: 'auth',
          //     level: 1,
          //     name: 'Register',
          //     safeLevel: 0
          //   },
          // }
        ],
      },
    ]),
  ],
  providers: [LoaderService],
})
export class KmsRouteModule { }
