import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'auth-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent implements OnInit {


  @Output() submitted = new EventEmitter<{ emailAddress: string, username: string }>();

  /**
   *  Forgot password form
   *
   * @memberof ForgotPasswordFormComponent
   */
  form: UntypedFormGroup = new UntypedFormGroup({
    emailAddress:   new UntypedFormControl('', [ Validators.required, Validators.email] )
  });

  /**
   *  The object of form validation errors.
   *
   * @memberof                ForgotPasswordFormComponent
   */
  formErrors                 = { 'emailAddress': '' };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                ForgotPasswordFormComponent
   */
  validationMessages          = {
    'emailAddress':  {
      'required':   'Email address is required',
      'email':      'The given value is not an email'
    }
  };

  ngOnInit() {

    this.form.valueChanges
    .subscribe( changes => this.onFormValueChanged( changes ) );

    this.onFormValueChanged();
  }

  /**
   *  Respond to the submit intent
   *
   * @memberof  ForgotPasswordFormComponent
   */
  submit() {

    if ( this.form.valid ) {
      const forgotPasswordData = { emailAddress: this.form.value.emailAddress, username: this.form.value.emailAddress };
      this.submitted.emit( forgotPasswordData );
    }
  }

    /**
   *  Respond to the change of the form value.
   *
   * @returns
   * @memberof          ForgotPasswordFormComponent
   */
  onFormValueChanged( data?: any ) {

    if ( !this.form ) { return; }

    const form =  this.form;

    for ( const field of Object.keys( this.formErrors ) ) {
      this.formErrors[ field ]  = ''; //  Clear all of messages
      const control             = form.get( field );

      if ( control && control.dirty && !control.valid ) {
        const messages          = this.validationMessages[ field ];

        for ( const key of Object.keys( control.errors )) {

          this.formErrors[ field ] += messages[ key ] + ' ';

        }
      }
    }

  }
}
