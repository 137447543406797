/** @format */

import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { throwError as _throw, BehaviorSubject, of } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserValidatorService } from './user-validator.service';
import { InsuranceCompanyContracted, NewPraciceInfo, PraciceInfoFromLocalStorage, PraciceInfoToSend, PracticeInfoForm } from '../models/practice-info';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs-compat';
import { Team } from '../models/team';

const apiPath = environment.apiPath;

type ExtendedTeam = Partial<PraciceInfoFromLocalStorage> & Team;

@Injectable()
export class PracticeInfoService {
  insuranceCompanies$ = new BehaviorSubject<Array<InsuranceCompanyContracted>>([]);

  #userValidatorService = inject(UserValidatorService);
  #fb = inject(FormBuilder);
  #http = inject(HttpClient);

  readonly #addressWrappedControls = ['street', 'city', 'postalCode', 'region'];

  practiceInfoForm: FormGroup = this.#fb.group<Partial<PracticeInfoForm>>({
    name: null,
    emailAddress: null,
    postalCode: null,
    numberOfEmployees: null,
    legalForm: null,
    professionalAssociation: null,
    city: null,
    street: null,
    practiceRegister: null,
    network: null,
    agbCode: null,
    region: null,
    insuranceCompaniesContracted: null,
    accountType: null
  })

  mapFieldsFromStorageToTheForm(): void {
    const team = this.#userValidatorService.currentUserRights.getValue().currentMember.team as ExtendedTeam;

    Object.keys(this.practiceInfoForm.value).forEach(key => {
      if (this.#addressWrappedControls.includes(key)) {
        if (team.address) {
          this.practiceInfoForm.get(key).setValue(team.address[key])
        }
      } else if (key === 'insuranceCompaniesContracted') {
        this.practiceInfoForm.get('insuranceCompaniesContracted').setValue(team.insuranceCompaniesContracted?.map(company => company.id))
      }

      else {
        this.practiceInfoForm.get(key).setValue(team[key])
      }
    });

    this.practiceInfoForm.updateValueAndValidity();
  }

  fetchListOfInsuranceCompanies(): Observable<Array<InsuranceCompanyContracted>> {
    return this.#http.get<Array<InsuranceCompanyContracted>>(`${apiPath}insurance-company`).pipe(tap(res => {
      this.insuranceCompanies$.next(res);
    }), catchError(() => of([])))
  }

  updateForm(): void {
    const team = this.#userValidatorService.currentUserRights.getValue().currentMember.team as ExtendedTeam;

    const updateUserRightsInLocalStorage = (extendedTeam: ExtendedTeam): void => {
      const newUserRights = this.#userValidatorService.currentUserRights.getValue();
      newUserRights.currentMember.team = extendedTeam;

      this.#userValidatorService.newUserRights(newUserRights);
      localStorage.setItem('userRights', JSON.stringify(newUserRights));
    }

    const {
      name,
      emailAddress,
      postalCode,
      numberOfEmployees,
      legalForm,
      professionalAssociation,
      city,
      street,
      practiceRegister,
      network,
      agbCode,
      region,
      insuranceCompaniesContracted,
      accountType } = this.practiceInfoForm.value;

    const insuranceCompaniesObjToSend = (insuranceCompaniesContracted as Array<string>).map(companyId => { return { id: companyId } });

    const dataFromFormToSend: PraciceInfoToSend = {
      accountType,
      agbCode,
      emailAddress,
      insuranceCompaniesContracted: insuranceCompaniesObjToSend,
      legalForm,
      network,
      numberOfEmployees,
      professionalAssociation,
      practiceRegister,
      name,
      address: {
        postalCode,
        city,
        street,
        region
      }
    }

    const finalObjToSend = { ...team, ...dataFromFormToSend };

    this.#http.put(`${apiPath}teams/${team.id}`, finalObjToSend).pipe(tap((res: ExtendedTeam) => {
      updateUserRightsInLocalStorage(res);
      this.mapFieldsFromStorageToTheForm();

    })).subscribe();
  }


  fetchNewPracticeInfo(teamId: string): Observable<NewPraciceInfo> {
    return this.#http
      .get<NewPraciceInfo>(
        `${apiPath}/teams/${teamId}`
      )
      .pipe(catchError((err) => _throw(err)));
  }
}
