/** @format */

import { Injectable } from '@angular/core';
import {
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';

import { UserValidatorService } from '../services/user-validator.service';
import { malAuthService } from '../services/iq-auth-services';
import { malAuthGuard } from './iq-guards';

/**
 *  The guard that checks if the user is authenticated.
 *
 * @export
 * @class       AuthGuard
 * @extends     {malAuthGuard}
 * @implements  {CanActivate}
 */
@Injectable()
export class AuthGuard extends malAuthGuard implements CanActivate {
  /**
   * Creates an instance of AuthGuard.
   *
   * @param     {malAuthService}        authService           The instance of malAuthService
   * @param     {Router}                rt                    The instance of Router object
   * @param     {UserValidatorService}  userValidatorService  The instance of the UserValidatorService
   * @memberof  AuthGuard
   */
  constructor(
    private authService: malAuthService,
    private rt: Router,
    private userValidatorService: UserValidatorService,
  ) {
    super(authService, rt);
  }

  /**
   *  Interface that a class can implement to be a guard deciding if a route can be activated
   *
   * @param       {ActivatedRouteSnapshot}  route The ActivatedRouteSnapshot instance.
   * @param       {RouterStateSnapshot}     state The instance of the RouterStateSnapshot
   * @returns     {boolean}
   * @memberof    AuthGuard
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    if (this.authService.isUserKnown()) {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (
        currentUser &&
        currentUser !== this.userValidatorService.currentUser.getValue()
      ) {
        this.userValidatorService.newUser(currentUser);
      }

      return true;
    } else {
      this.rt.navigate(['auth/login']);

      return false;
    }
  }
}
