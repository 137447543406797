import { Observable, throwError as _throw } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PagedResult } from '../../models/paged-result';
import { QueryParam } from '../../models/query-param';
import { PageSetting } from '../../models/paged-setting';
import { BaseModel } from '../../models/base-model';

/**
 * An generic REST API service.
 */
export abstract class AbstractRestService<T> {
  constructor(
    protected http: HttpClient,
    protected actionUrl: string,
    protected secondActionUrl: string
  ) { }

  protected abstract mapToModel(json: any): PagedResult<T>;

  /**
   * A generic GET method to retrieve all the models
   * @returns Observable<T>
   */

  getAll(pageSetting?: PageSetting, filterSetting?: any, sortSetting?: any, queryParams?: QueryParam[]): Observable<PagedResult<T>> {
    let requestUrl = this.actionUrl;

    if (pageSetting || filterSetting || sortSetting || queryParams) {

      requestUrl += '?';

    }

    // Append paging
    if (pageSetting) {
      if (!requestUrl.endsWith('?')) {
        requestUrl += '&';
      }
      requestUrl += `page=${pageSetting.number}&size=${pageSetting.size}`;
    }

    //Append queryParams
    if (queryParams) {
      for (let param of queryParams) {
        if (!requestUrl.endsWith('?')) {
          requestUrl += '&';
        }
        requestUrl += `${param.name}=${param.value}`;
      }
    }

    // Append filtering
    if (filterSetting && Object.keys(filterSetting).length > 0) {
      Object.keys(filterSetting).map(function (columnName) {
        if (!requestUrl.endsWith('?')) {
          requestUrl += '&';
        }
        requestUrl += `${columnName}-filter=${filterSetting[columnName]}`;
      });
    }

    // Append sorting
    if (sortSetting && Object.keys(sortSetting).length > 0) {

      Object.keys(sortSetting).map(function (columnName) {
        if (!requestUrl.endsWith('?')) {
          requestUrl += '&';
        }
        requestUrl += `sort=${columnName}%2C${sortSetting[columnName]}`;
      });
    }



    return this.http.get<any>(
      requestUrl)
      .pipe(
        map((response) => {
          return this.mapToModel(response);
        }),
        catchError((err) => _throw(err))
      )
  }

  /**
   * A generic GET method to retrieve a single model summary/
   * @returns Observable<R|T>
   */
  getSingle(aModelId: string): Observable<T> {
    let requestUrl = this.actionUrl;
    requestUrl += aModelId;
    return this.http.get<any>(requestUrl)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err) => _throw(err))
      )
  }

  /**
   * A generic POST method.
   * @returns Observable<T>
   */
  post(aModel: BaseModel): Observable<T> {
    console.log(aModel);
    return this.http.post<T>(
      `${this.actionUrl}`,
      aModel).pipe(
        catchError((err) => _throw(err))
      )
  }

  getFilesList(aModelId: string): Observable<T> {
    let requestUrl = this.actionUrl;
    requestUrl += aModelId + '/docs';
    return this.http.get<any>(requestUrl)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err) => _throw(err))
      )
  }

  setFilesList(aModel: any): Observable<T> {
    console.log(aModel);
    let patchURL = `${this.actionUrl}/${aModel.ownerId}/docs`;
    // Construct URL

    return this.http.post<T>(
      patchURL,
      aModel).pipe(
        catchError((err) => _throw(err))
      )
  }

  /**
   * A generic GET method to retrieve a single model summary/
   * @returns Observable<R|T>
   */
  public async getSingleFile(aModelId: string, setType?: string): Promise<Blob> {
    let urlType = '';
    if (setType) {
      urlType = '/' + setType;
    };

    let header = new HttpHeaders(
      {
        'IQFile': ''
      }
    );

    const file = await this.http.get<Blob>(
      `${this.actionUrl}` + aModelId + '/' + urlType,
      { headers: header, responseType: 'blob' as 'json' }
    ).toPromise();

    return file;
  }

  postFile(aModel: any): Observable<T> {
    console.log(aModel);
    let header = new HttpHeaders(
      {
        'IQFile': ''
      }
    );
    return this.http.post<T>(
      `${this.actionUrl}`,
      aModel,
      { headers: header }).pipe(
        catchError((err) => _throw(err))
      )
  }

  /**
   * A generic PUT method.
   * @param BaseModel aModel
   * @param BaseModel bModel
   * @returns Observable<T>
   */
  put(aModel: BaseModel, bModel?: BaseModel): Observable<T> {
    console.log(aModel);
    let putURL = `${this.actionUrl}/${aModel.id}`;
    // Construct URL
    if (bModel) {
      putURL += `/${bModel.actionUrl}/${bModel.id}`;
    }

    return this.http.put<T>(
      putURL,
      aModel).pipe(
        catchError((err) => _throw(err))
      )
  }

  /**
   * A generic PATCH method.
   * @param BaseModel aModel
   * @param BaseModel bModel
   * @returns Observable<T>
   */
  patch(aModel: BaseModel, bModel?: BaseModel): Observable<T> {
    console.log(aModel);
    let patchURL = `${this.actionUrl}/${aModel.id}`;
    // Construct URL
    if (bModel) {
      patchURL += `/${bModel.actionUrl}/${bModel.id}`;
    }

    return this.http.patch<T>(
      patchURL,
      aModel).pipe(
        catchError((err) => _throw(err))
      )
  }

  /**
   * A generic DELETE method.
   * @param BaseModel aModel
   * @returns Observable<T>
   */
  delete(aModel: BaseModel): Observable<T> {
    console.log(aModel);
    return this.http.delete<T>(
      `${this.actionUrl}/${aModel.id}`).pipe(
        catchError((err) => _throw(err))
      )
  }
}
