<div class="mat-header-column-container">
  <span (click)="headerClick()" class="mat-header-column-name">{{headerTitle}}</span>
  <mat-icon #sortIcon [ngClass]="{'hidden': currentSortDirection == 0 ,'rotated' : currentSortDirection == 2}">arrow_upward</mat-icon>
</div>
<mat-form-field class="form-field">
  <input *ngIf="dropDownChoices.length == 0" matInput id="{{columnName}}" type="text" class="form-control"
         name="{{columnName}}"
         (keyup)="filterKeyUp($event)">
  <mat-select *ngIf="dropDownChoices.length > 0" (change)="filterSelectChange($event)">
    <mat-option *ngFor="let choice of dropDownChoices" [value]="choice">
      {{ choice }}
    </mat-option>
  </mat-select>
</mat-form-field>
