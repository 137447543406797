/** @format */

import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { AddEditAbstractDialog } from '../add-edit-abstract-dialog';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { SharedService } from '../../services/shared.service';

import { Member } from '../../models/member';
import { map, startWith } from 'rxjs/operators';
import {
  YearPlanning,
  YearPlanningMember,
  YearPlanningSession,
} from '../../models/year-planning';
import * as moment from 'moment';
import { YearPlanningService } from '../../services/year-planning.service';
import { YearPlanningCategoriesService } from '../../services/year-planning-categories.service';
import { YearPlanningCategories } from '../../models/year-planning-categories';
import { Subscription, Observable } from 'rxjs';
import { UserValidatorService } from '../../services/user-validator.service';
import { PagedResult } from '../../models/paged-result';

/**
 *  Add/edit yearplan item dialog.
 *
 * @export
 * @class AddEditImprovementComponent
 * @extends {AddEditAbstractDialog<Task>}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-add-edit-year-event',
  templateUrl: './add-edit-year-event.component.html',
  styleUrls: ['./add-edit-year-event.component.scss'],
})
export class AddEditYearEventComponent
  extends AddEditAbstractDialog<YearPlanning>
  implements OnInit, OnDestroy {
  /**
   *  The object of form validation errors.
   *
   * @memberof               AddEditYearEventComponent
   */
  formErrors = {
    actionPoint: '',
    categoryName: '',
    categoryId: '',
    description: '',
    executioners: '',
    notificationText: '',
    plannedSessions: '',
    standard: '',
    topic: '',
    year: '',
  };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AddEditYearEventComponent
   */
  validationMessages = {
    topic: {
      required: 'Onderwerp is een verplicht veld.',
    },
    categoryId: {
      required: 'Planningscategorie is een verplicht veld.',
    },
  };

  /**
   *  The improvement created by the API
   *
   * @type                    {YearPlanning}
   * @memberof                AddEditYearEventComponent
   */
  createdYearPlan: YearPlanning;

  /**
   *  The list of the planning categories
   *
   * @type                    {YearPlanningCategories[]}
   * @memberof                AddEditYearEventComponent
   */
  categoryNameList: YearPlanningCategories[];

  /**
   *  The list of executioners members
   *
   * @memberof               AddEditYearEventComponent
   */
  executionersMembers: string[];

  /**
   *  The subscription of filtered Members
   *
   * @memberof                AddEditYearEventComponent
   */
  filteredMembersSubs: Subscription;

  /**
   *  Form Control of the filter teams
   *
   * @type                  {FormControl}
   * @memberof              AddEditYearEventComponent
   */
  filterControl: UntypedFormControl = new UntypedFormControl();

  /**
   *  The list of team members
   *
   * @memberof                AddEditYearEventComponent
   */
  filteredMembers: Observable<Member[]>;

  /**
   *
   */
  exeList: YearPlanningMember[] = [];
  dateList: YearPlanningSession[] = [];

  /**
   *  The index of new subject
   *
   * @memberof            AddEditYearEventComponent
   */
  newSubjectIndex = 1;
  newDateIndex = 1;

  /**
   *  The member selected in the filter form
   *
   * @type {Member}
   * @memberof AddEditYearEventComponent
   */
  selectedMember: Member;

  /**
   *  True/false if any child form is invalid
   *
   * @memberof AddEditYearEventComponent
   */
  childInvalid = false;

  /**
   *  The expansion panel step.
   *
   * @memberof                AddEditYearEventComponent
   */
  step = 0;

  /**
   *  Current date
   *
   * @memberof                AddEditYearEventComponent
   */
  today = new Date();

  /**
   * Creates an instance of AddEditImprovementComponent.
   *
   * @param        {MatDialogRef<AddEditImprovementComponent>}   dialogRefA              The instance of MatDialogRef
   * @param        {*}                                           data                    The data passed from parent component
   * @param        {IncidentsService}                            improvementsService
   * @param        {FormBuilder}                                 fbA
   * @param        {SharedService}                               sharedService           The instance of sharedService
   * @memberof     AddEditYearEventComponent
   */
  constructor(
    public dialogRefA: MatDialogRef<AddEditYearEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private yearPlanningService: YearPlanningService,
    private yearPlanningCategoriesService: YearPlanningCategoriesService,
    private ref: ChangeDetectorRef,
    private fbA: UntypedFormBuilder,
    private mdialog: MatDialog,
    public sharedService: SharedService,
    private userValidatorservice: UserValidatorService,
  ) {
    super(dialogRefA, data, yearPlanningService, fbA, mdialog);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof        AddEditYearEventComponent
   */
  ngOnInit() {
    this.initialFormValues = this.data.formValues;
    this.formIsNew = this.data.isNew;
    this.createdYearPlan = this.data.yearPlanItem;

    this.currentUserRights =
      this.userValidatorservice.currentUserRights.getValue();

    this.warningDialogParameters.data.message = `Er zijn nog niet opgeslagen wijzigingen. Weet je zeker dat je wilt annuleren?`;

    this.yearPlanningCategoriesService
      .getAll()
      .pipe(map((resp: PagedResult<YearPlanningCategories>) => resp))
      .subscribe((result) => {
        const results = result.payload;
        this.categoryNameList = results;
      });

    if (this.initialFormValues.executioners[0] && !this.formIsNew) {
      this.exeList = this.initialFormValues.executioners[0];
    } else {
      this.initialFormValues.executioners[0] = [];
      this.exeList = this.initialFormValues.executioners[0];
    }

    if (this.initialFormValues.plannedSessions[0] && !this.formIsNew) {
      this.dateList = this.initialFormValues.plannedSessions[0];
    } else {
      this.initialFormValues.plannedSessions[0] = [];
      this.dateList = this.initialFormValues.plannedSessions[0];
    }

    this.addExecutionerFilter();
    this.createForm(this.initialFormValues);

    if (!this.formIsNew && !this.data.canUpdate) {
      this.newFormGroup.disable();
    }

    //  Add the child component
    this.childComponentsList = [
      {
        value: this.exeList,
        fieldName: 'executioners',
        hasChanged: false,
        invalid: false,
        separateRequests: false,
        teamId: this.currentUserRights.currentTeamId,
        emptyFormValues: {
          teamId: this.currentUserRights.currentTeamId,
          memberId: '',
          memberName: '',
          temporaryFields: {
            changed: false,
            removed: false,
            isNew: true,
            expanded: true,
            invalid: false,
          },
        },
      },
      {
        value: this.dateList,
        fieldName: 'plannedSessions',
        hasChanged: false,
        invalid: false,
        separateRequests: false,
        teamId: this.currentUserRights.currentTeamId,
        emptyFormValues: {
          teamId: this.currentUserRights.currentTeamId,
          plannedDate: '',
          plannedEndDate: '',
          ready: false,
          temporaryFields: {
            changed: false,
            removed: false,
            isNew: true,
            expanded: true,
            invalid: false,
          },
        },
      },
    ];
  }

  /**
   *  Add the filter of executioners
   *
   * @memberof AddEditYearEventComponent
   */
  addExecutionerFilter() {
    // Create list of executioners members
    this.executionersMembers = [];
    if (this.createdYearPlan && this.createdYearPlan.executioners) {
      for (const executioner of this.createdYearPlan.executioners) {
        this.executionersMembers.push(executioner.memberId);
      }
    }

    this.filteredMembersSubs =
      this.sharedService.currentTeamMembersList$.subscribe((membersList) => {
        let memberslist = [];

        memberslist = membersList.filter(this.notCurrentExecutioner, this);

        this.filteredMembers = this.filterControl.valueChanges.pipe(
          startWith(''),
          map((val) => {
            memberslist = membersList.filter(this.notCurrentExecutioner, this);

            if (typeof val === 'string' && val === '') {
              this.onOptionSelect();

              return memberslist;
            } else if (typeof val === 'string' && val !== '') {
              return this.filterMember(memberslist, val);
            } else if (typeof val === 'object' && val.memberName) {
              return this.filterMember(memberslist, val.memberName);
            }
          }),
        );
      });
  }

  /**
   *  Function that maps an option's control value to its display value in the trigger.
   *
   * @param    {Member} [member]
   * @returns  {(string | undefined)}
   * @memberof AddEditYearEventComponent
   */
  displayFn(member?: Member): string | undefined {
    return member ? member.memberName : undefined;
  }

  /**
   * Check if the member of team is already an executioner of current improvement
   *
   * @param {*} element
   * @param {*} index
   * @param {*} array
   * @memberof AddEditYearEventComponent
   */
  notCurrentExecutioner(element: any, index: any, array: any) {
    let exsists = false;

    for (const executioner of this.executionersMembers) {
      if (element.id === executioner) {
        exsists = true;

        break;
      }
    }

    return !exsists;
  }

  /**
   *  Filter the list of Members.
   *
   * @param         {string}            val
   * @returns       {Member[]}
   * @memberof      AddEditYearEventComponent
   */
  filterMember(membersList: Member[], val: string): Member[] {
    return membersList.filter(
      (option) =>
        option.memberName.toLowerCase().indexOf(val.toLowerCase()) !== -1,
    );
  }

  /**
   *  Set the step of the expansion panel.
   *
   * @param     {number}                    index
   * @memberof  AddEditYearEventComponent
   */
  setStep(index: number) {
    this.step = index;
  }

  /**
   *  Set the next step of the expansion panel.
   *
   * @memberof AddEditYearEventComponent
   */
  nextStep() {
    this.step++;
  }

  /**
   *  Set the previous step of the expansion panel.
   *
   * @memberof AddEditYearEventComponent
   */
  prevStep() {
    this.step--;
  }

  /**
   *  Add a new child item to the childComponent list
   *
   * @param    {Event}  [event]
   * @param    {number} [childsListIndex]
   * @memberof AddEditYearEventComponent
   */
  onChildItemAdd(event?: Event, childsListIndex?: number) {
    if (event) {
      event.stopImmediatePropagation();
    }

    if (
      this.childComponentsList &&
      this.childComponentsList.length &&
      this.childComponentsList[childsListIndex]
    ) {
      const emptyFormValuesClone = JSON.parse(
        JSON.stringify(
          this.childComponentsList[childsListIndex].emptyFormValues,
        ),
      );

      if (childsListIndex === 0 && !this.selectedMember) {
        return;
      }

      this.childComponentsList[childsListIndex].value.push(
        emptyFormValuesClone,
      );
      this.childComponentsList[childsListIndex].hasChanged = true;
      this.childChanged = true;
      this.formSubmitted = false;

      if (childsListIndex === 0 && this.selectedMember) {
        this.executionersMembers.push(this.selectedMember.id);
        this.selectedMember = null;
        this.childComponentsList[childsListIndex].emptyFormValues.memberId = '';
        this.childComponentsList[childsListIndex].emptyFormValues.memberName =
          '';
        this.childComponentsList[childsListIndex].invalid = false;
        this.filterControl.reset('');
      } else {
        this.childComponentsList[childsListIndex].invalid = true;
        this.childInvalid = true;
      }
    }
  }

  /**
   *  Respond to the executioner delete intent
   *
   * @param    {*}                 item
   * @param    {number}            childListIndex
   * @param    {Event}             [event]
   * @param    {MatExpansionPanel} [element]
   * @param    {number}            [ind]
   * @memberof AddEditYearEventComponent
   */
  onExecutionerDelete(
    item: any,
    childListIndex: number,
    event?: Event,
    element?: MatExpansionPanel,
    ind?: number,
  ) {
    if (
      item &&
      item.memberId &&
      item.temporaryFields &&
      item.temporaryFields.isNew
    ) {
      const index = this.executionersMembers.indexOf(item.memberId);

      if (index > -1) {
        this.executionersMembers.splice(index, 1);

        this.filterControl.reset('');
      }
    }

    this.onChildItemDelete(item, childListIndex, event, element, ind);
  }

  /**
   *  Respond to the selection of the Member in members list
   *
   * @param       {Member}              option
   * @memberof    AddEditYearEventComponent
   */
  onOptionSelect(option?: Member) {
    if (!option) {
      this.selectedMember = null;
      this.childComponentsList[0].emptyFormValues.memberId = '';
      this.childComponentsList[0].emptyFormValues.memberName = '';
    } else if (option.id) {
      this.selectedMember = option;
      this.childComponentsList[0].emptyFormValues.memberId = option.id;
      this.childComponentsList[0].emptyFormValues.memberName =
        option.memberName;
    }
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof AddEditYearEventComponent
   */
  ngOnDestroy() {
    if (this.filteredMembersSubs) {
      this.filteredMembersSubs.unsubscribe();
    }
  }
}
