import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
export const InterceptorSkipHeader = 'X-Skip-Interceptor';
export const FileHeader = 'IQFile';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public auth: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(InterceptorSkipHeader)) {

      let headers = request.headers.delete(InterceptorSkipHeader);

      return next.handle(request.clone({headers}));

    } else {
      let headers = request.headers;
      if (request.headers.has(FileHeader)) {
        headers = request.headers.delete(FileHeader);
      } else {
        headers = request.headers.append('Content-Type', 'application/json');
      }

      if (this.auth.isAuthenticated()) {
        headers = headers.append('X-Auth-Token', this.auth.getToken().token);
        headers = headers.append('Authorization', 'Bearer ' + this.auth.getToken().token);
      }
      const cloneReq = request.clone({ headers });
      return next.handle(cloneReq);

    }

  }
}
