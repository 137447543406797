import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';

import { MatSelectChange } from '@angular/material/select';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-marviq-mat-table-filter',
  templateUrl: './marviq-mat-table-filter.component.html',
  styleUrls: ['./marviq-mat-table-filter.component.scss']
})
export class MarviqMatTableFilterComponent implements OnInit {

  @Input() columnName: string;
  @Input() headerTitle: string;
  @Input() dropDownChoices: string[] = [];

  @Output() sortColumn = new EventEmitter<object>();
  @Output() filterColumn = new EventEmitter<object>();

  currentSortDirection = SortDirection.NONE;

  private keyUpStream: Subject<string> = new Subject();

  constructor() { }

  ngOnInit() {
    this.keyUpStream.pipe(debounceTime(500)).subscribe( filterText => {
      this.filterColumn.emit({ column: this.columnName, value: filterText});
    });
  }

  filterKeyUp(event: KeyboardEvent) {
    this.keyUpStream.next((<HTMLInputElement>event.target).value);
  }

  filterSelectChange(event: any) {
    this.keyUpStream.next(event.value);
  }

  headerClick() {
    this.currentSortDirection++;
    if (this.currentSortDirection >= Object.keys(SortDirection).length / 2) {
      this.currentSortDirection = SortDirection.NONE;
    }
    this.sortColumn.emit({ column: this.columnName, value: this.currentSortDirection});
  }
}

export enum SortDirection {
  NONE = 0 ,
  ASC = 1,
  DESC = 2
}
