import {Component, Injectable, Optional} from '@angular/core';

let nextCfg = 1;

export class ProjConfig {
  userName        = 'Philip Marlowe';
  apiUrl          = 'null model';
  apiPath         = 'null model';
  forgotPassword  = 'null model';
  setPassword     = 'null model';
  login           = 'null model';
  refreshToken    = 'null model';
  user            = 'null model';
}

@Injectable()
export class malConfigService {

  id = nextCfg++;
  private _userName       = 'Sherlock Holmes';
  private _apiUrl         = 'null service';
  private _apiPath        = 'null service';
  private _forgotPassword = 'null service';
  private _setPassword    = 'null service';
  private _login          = 'null service';
  private _refreshToken   = 'null service';
  private _user           = 'null service';
  private _projData = {
    userName        : '',
    apiUrl          : '',
    apiPath         : '',
    forgotPassword  : '',
    setPassword     : '',
    login           : '',
    refreshToken    : '',
    user            : ''
  };

  constructor(@Optional() config: ProjConfig) {
    if ( config ) {
      this._userName = config.userName;
      this._apiPath = config.apiPath;
      this._apiUrl = config.apiUrl;
      this._forgotPassword = config.forgotPassword;
      this._setPassword = config.setPassword;
      this._login = config.login;
      this._refreshToken = config.refreshToken;
      this._user = config.user;

      this._projData = {
        userName        : config.userName,
        apiUrl          : config.apiUrl,
        apiPath         : config.apiPath,
        forgotPassword  : config.forgotPassword,
        setPassword     : config.setPassword,
        login           : config.login,
        refreshToken    : config.refreshToken,
        user            : config.user
      };

    }

  }

  get userName() {
    // Demo: add a suffix if this service has been created more than once
    const suffix = this.id > 1 ? ` times ${this.id}` : '';
    return this._userName;
  }

  get apiUrl() {
    return this._apiUrl;
  }

  get apiPath() {
    return this._apiPath;
  }

  get forgotPassword() {
    return this._forgotPassword;
  }

  get setPassword() {
    return this._setPassword;
  }

  get login() {
    return this._login;
  }

  get refreshToken() {
    return this._refreshToken;
  }

  get user() {
    return this._user;
  }

}



