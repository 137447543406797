/** @format */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { throwError as _throw } from 'rxjs';
import { YearPlanningCategories } from '../models/year-planning-categories';
import { PagedResult } from '../models/paged-result';
import { PageSetting } from '../models/paged-setting';
import { AbstractRestService } from './iq-package-services/abstract-rest-service.service';

const apiPath = environment.apiPath;

/**
 *  Service for managing the agenda templates.
 *
 * @export
 * @class   YearPlanningCategoriesService
 * @extends {AbstractRestService<YearPlanningCategories>}
 */
@Injectable()
export class YearPlanningCategoriesService extends AbstractRestService<YearPlanningCategories> {
  /**
   * Creates an instance of YearPlanningCategoriesService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      YearPlanningCategoriesService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}yearPlanningCategories`, 'yearPlanningCategories');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<>}
   * @memberof
   */
  protected mapToModel(json: any): PagedResult<YearPlanningCategories> {
    //  If the array doesn't exist, create an empty object
    let payload = [];

    if (json._embedded) {
      payload = <YearPlanningCategories[]>json._embedded.yearPlanningCategories;
    }

    return new PagedResult<YearPlanningCategories>(
      payload,
      <PageSetting>json.page,
    );
  }
}
