/** @format */

import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { PmrService } from '../../services/pmr.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PMR } from '../../models/pmr';
import { MatDialog } from '@angular/material/dialog';
import { UserRights } from '../../models/user-rights';
import { UserValidatorService } from '../../services/user-validator.service';
import { AddEditPmrItemComponent } from '../../dialogs/add-edit-pmr-item/add-edit-pmr-item.component';
import {
  PMRITEM_STATUSES,
  PMRITEM_CONCLUSIONS,
  PMRItem,
} from '../../models/pmr-item';
import { PageSetting } from '../../models/paged-setting';
import { MarviqMatTableInlineCrudComponent } from '../iq-package-components/marviq-mat-table-inline-crud.component';

/**
 *  Show the list of pmr items attached to PMR
 *
 * @export
 * @class PmrItemsListComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-pmr-items-list',
  templateUrl: './pmr-items-list.component.html',
  styleUrls: ['./pmr-items-list.component.scss'],
})
export class PmrItemsListComponent
  extends MarviqMatTableInlineCrudComponent<PMR>
  implements OnInit, OnDestroy, OnChanges {
  /**
   *  The PMR object
   *
   * @type                    {PMR}
   * @memberof                PmrItemsListComponent
   */
  @Input() pmr: PMR;

  /**
   *  The output sent to the parent component that refreshes the pmrs list
   *
   * @memberof PmrItemsListComponent
   */
  @Output() pmrItemChanged = new EventEmitter<PMRItem>();

  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                PmrItemsListComponent
   */
  columnsToDisplay = [
    'containsCopiedImprovements',
    'title',
    'description',
    'status',
    'conclusion',
    'IVM',
    'CUD',
  ];

  /**
   *  The current user rights.
   *
   * @type                    {UserRights}
   * @memberof                PmrItemsListComponent
   */
  currentUserRights: UserRights;

  /**
   *  The default values of the new Form.
   *
   * @memberof                PmrItemsListComponent
   */
  emptyFormValues = {
    coRemark: [''],
    conclusion: [0],
    copiedFromPrevious: false,
    improvementIds: [[]],
    status: [0],
    description: [''],
    newLinkedAttachmentIds: [[]],
    newUnLinkedAttachmentIds: [[]],
    pmrId: '',
    title: ['', Validators.required],
    teamId: '',
    checkbox: [false]
  };

  /**
   *  The statuses translations of the pmr items
   *
   * @memberof PmrItemsListComponent
   */
  pmrItemStatuses = PMRITEM_STATUSES;

  /**
   *  The conclusions translations of the pmr
   *
   * @memberof PmrItemsListComponent
   */
  pmrItemConclusions = PMRITEM_CONCLUSIONS;

  /**
   * The pager info received from the HTTP request
   *
   * @memberof                PmrItemsListComponent
   */
  usersPageSetting: PageSetting = new PageSetting(25, 0, 0, 0);

  /**
   * Creates an instance of PmrItemsListComponent.
   *
   * @param {PmrService}  pmrService
   * @param {FormBuilder} formBuilder
   * @param {MatDialog}   dg
   * @memberof PmrItemsListComponent
   */
  constructor(
    private pmrService: PmrService,
    private formBuilder: UntypedFormBuilder,
    private dg: MatDialog,
    private userValidatorService: UserValidatorService,
  ) {
    super(pmrService, formBuilder, dg);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof PmrItemsListComponent
   */
  ngOnInit() {
    this.getRows();
  }

  /**
   * Respond when Angular (re)sets data-bound input properties.
   * Called before ngOnInit() and whenever one or more data-bound input properties change.
   *
   * @memberof PmrItemsListComponent
   */
  ngOnChanges() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();
    this.getRows();
  }

  /**
   *  Get the list of pmr_items from the PMR component
   *
   * @memberof PmrItemsListComponent
   */
  getRows() {
    if (this.pmr && this.pmr._embedded && this.pmr._embedded['pmr-items']) {
      this.dataSource.data = this.pmr._embedded['pmr-items'];
    } else {
      this.dataSource.data = [];
    }
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof          PmrItemsListComponent
   */
  ngOnDestroy() {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
  }

  /**
   *  Respond to the user editing a single item.
   *
   * @param       {*}                         row       The current row in the data tabl
   * @param       {Event}                     [event]   The (`click`) event signalling the user's intent.
   * @param       {number}                    [index]   The index of the edited row.
   * @memberof    PmrItemsListComponent
   */
  onEditIntent(row?: any, event?: Event, index?: number) {
    let isNew = true;
    let formValues = <any>{};

    if (event) {
      event.stopImmediatePropagation();
    }

    if (!row) {
      formValues = this.emptyFormValues;
    } else {
      isNew = false;

      const editedRowFormValues = {
        id: [row.id],
        coRemark: [row.coRemark ? row.coRemark : ''],
        conclusion: [row.conclusion ? row.conclusion : 0],
        copiedFromPrevious: row.copiedFromPrevious,
        improvementIds: [row.improvementIds ? row.improvementIds : []],
        status: [row.status ? row.status : 0],
        description: [row.description ? row.description : ''],
        pmrId: row.pmrId,
        newLinkedAttachmentIds: [[]],
        newUnLinkedAttachmentIds: [[]],
        title: [row.title ? row.title : '', Validators.required],
        teamId: row.teamId ? row.teamId : this.currentUserRights.currentTeamId,
        checkbox: [row.checkbox ? row.checkbox : false]
      };

      formValues = editedRowFormValues;
    }

    const dialogRef = this.dg.open(AddEditPmrItemComponent, {
      data: {
        formValues: formValues,
        isNew: isNew,
        pmrItem: row,
        pmrArchived: this.pmr ? this.pmr.archived : false,
      },
      disableClose: true,
      height: '900px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'primary-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        if (results.resp && results.resp.id) {
          this.dataSource.data[index] = results.resp;

          //  This is a tricky solution to refresh the material table
          //
          this.dataSource.filter = '';

          //  Emit the changed pmr item but only if it has status Closed
          //  It will prevent PMR list to be fetched everytime when pmrItem changes
          //  PMRs list should be refetched only when the PMR has been just finished
          if (results.resp && results.resp.status === 3) {
            this.pmrItemChanged.emit(results.resp);
          } else {
            this.pmrItemChanged.emit(undefined);
          }
        } else {
          this.getRows();
          this.pmrItemChanged.emit(undefined);
        }
      }
    });
  }
}
