import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

@Injectable()
export class DashboardQuestionnaireGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const permissions: string[] = JSON.parse(localStorage.getItem('userRights')).currentMember.teamRole.permissions || [];

    if (permissions.includes('READ_DASHBOARD')) {
      return true;
    } else if (!permissions.includes('READ_DASHBOARD') && permissions.includes('READ_QUESTIONNAIRE')) {
      this.router.navigate(['questionnaire']);
      return false;

    }
    else {
      this.router.navigate(['403']);
      return false;
    }
  }
}
