/** @format */

import { map, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TeamGroup } from '../../models/team-group';
import { TeamGroupsListService } from '../../services/team-groups-list.service';
import { SelectionModel } from '@angular/cdk/collections';
import { SharedService } from '../../services/shared.service';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { WarningDeleteDialogComponent } from '../../dialogs/warning-delete-dialog/warning-delete-dialog.component';
import { AddEditTeamGroupComponent } from '../../dialogs/add-edit-team-group/add-edit-team-group.component';
import { ErrorDialogComponent } from '../../dialogs/error-dialog/error-dialog.component';
import { UserValidatorService } from '../../services/user-validator.service';
import { PagedResult } from '../../models/paged-result';
import { PageSetting } from '../../models/paged-setting';
import { MarviqMatTableInlineCrudComponent } from '../iq-package-components/marviq-mat-table-inline-crud.component';

/**
 *  Display an overview of team groups.
 *
 * @export
 * @class       TeamGroupsListComponent
 * @implements  {OnInit}
 */
@Component({
  selector: 'app-team-groups-list',
  templateUrl: './team-groups-list.component.html',
  styleUrls: ['./team-groups-list.component.scss'],
})
export class TeamGroupsListComponent
  extends MarviqMatTableInlineCrudComponent<TeamGroup>
  implements OnInit, OnDestroy {
  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                TeamGroupsListComponent
   */
  columnsToDisplay = ['index', 'name', 'coach', 'teamsCount', 'CUD'];

  /**
   *  The default values of the new Form.
   *
   * @memberof                TeamGroupsListComponent
   */
  emptyFormValues = {
    name: ['', Validators.required],
    admins: [null, Validators.required],
  };

  /**
   *  The dialog component assigned to the delete request.
   *
   * @memberof                TeamGroupsListComponent
   */
  deleteDialog = WarningDeleteDialogComponent;

  /**
   *  The dialog component assigned to the error.
   *
   * @memberof                TeamGroupsListComponent
   */
  errorDialog = ErrorDialogComponent;

  /**
   *  The form group storing filters
   *
   * @type                    {FormGroup}
   * @memberof                TeamGroupsListComponent
   */
  filterForm: UntypedFormGroup;

  /**
   *  The subscription of teamGroups
   *
   * @type                    {Subscription}
   * @memberof                TeamGroupsListComponent
   */
  teamGroupsSubscription: Subscription;

  /**
   * The pager info received from the HTTP request
   *
   * @memberof                TeamGroupsListComponent
   */
  usersPageSetting: PageSetting = new PageSetting(25, 0, 0, 0);

  /**
   *  Error dialog paramters
   *
   * @memberof TeamGroupsListComponent
   */
  errorDialogParameters = <any>{
    panelClass: 'delete-dialog',
    maxWidth: '800px',
    disableClose: true,
    data: {},
  };

  isUserACoCoach: boolean;

  /**
   * Creates an instance of TeamGroupsListComponent.
   *
   * @param       {TeamGroupsListService}     teamGroupsListService the service that handles the team-groups list logic
   * @param       {SharedService}             sharedService         the shared service instance that shares values between components
   * @param       {FormBuilder}               fb                    The formBuilder instance
   * @memberof    TeamGroupsListComponent
   */
  constructor(
    private teamGroupsListService: TeamGroupsListService,
    private sharedService: SharedService,
    private formBuilder: UntypedFormBuilder,
    private fbuild: UntypedFormBuilder,
    private editDialog: MatDialog,
    private dg: MatDialog,
    private userValidatorService: UserValidatorService,
  ) {
    super(teamGroupsListService, formBuilder, dg);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof      TeamGroupsListComponent
   */
  ngOnInit() {
    const userRights = this.userValidatorService.currentUserRights.getValue();
    this.isUserACoCoach =
      userRights.currentMember.teamRole.name === 'TEAM_GROUP_ADMIN' ||
      userRights.currentMember.teamRole.name === 'ORG_COACH'; // ORG_COACH taken from jira, TEAM_GROUP_ADMIN taken from application storage

    this.sortValues = { updateDate: 'desc' };
    this.selection = new SelectionModel<TeamGroup>(
      this.allowMultiSelect,
      this.initialSelection,
    );

    this.dialogParameters.data.message = `Weet je zeker dat je deze praktijkgroep wilt verwijderen? Hiermee worden ook de toegewezen praktijken verwijderd!`;
    this.errorDialogParameters.data.message = `Deze praktijkgroep bevat praktijken. Je kunt deze niet verwijderen`;

    this.filterForm = this.fbuild.group({
      name: '',
      admins: null,
    });

    this.filterForm.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((changes) => {
        for (const field of Object.keys(changes)) {
          this.filterValues[field] = changes[field];
        }

        this.usersPageSetting.number = 0;
        this.paginator.firstPage();
        this.getRows();
      });

    this.getAllTeamGroups();
    this.getRows();
  }

  /**
   *  Get all teamGroups without pagination.
   *
   * @memberof      TeamGroupsListComponent
   */
  getAllTeamGroups() {
    this.teamGroupsSubscription = this.teamGroupsListService
      .getAll(undefined, undefined, { name: 'asc' }, this.queryParams)
      .subscribe((currentTeamGroupsList) => {
        if (
          this.sharedService.currentTeamGroupsList.getValue() !==
          currentTeamGroupsList.payload
        ) {
          this.sharedService.newSelectedTeamGroupsList(
            currentTeamGroupsList.payload,
          );
        }
      });
  }

  /**
   *  Overrides the getRows() method from MarviqMatTable component
   *  Get all rows for the table view
   *
   * @memberof      TeamGroupsListComponent
   */
  getRows() {
    //  Clear selection on the TeamGroup table
    if (this.selection && this.selection.hasValue()) {
      this.selection.clear();
      this.sharedService.newSelectedTeamGroup(this.selection);
    }

    //  Get all rows in the list
    this.serviceSubscription = this.teamGroupsListService
      .getAll(
        this.usersPageSetting,
        this.filterValues,
        this.sortValues,
        this.queryParams,
      )
      .pipe(map((resp: PagedResult<TeamGroup>) => resp))
      .subscribe((result) => {
        this.dataSource.data = result.payload;

        if (this.newFormGroup) {
          this.dataSource.data.unshift(
            Object.assign({ editMode: true }, this.newFormGroup.value),
          );
          this.dataSource.filter = '';
        }
        if (result.page) {
          this.usersPageSetting = result.page;
        }
      });
  }

  /**
   *  Responds to the user click event on the row.
   *
   * @param       {any}                   row   The current row in the data table
   * @memberof    TeamGroupsListComponent
   */
  onRowClick(row: any) {
    //  Prevent selection if the row is in editMode.
    //
    if (!row.editMode) {
      this.selection.toggle(row);
      this.sharedService.newSelectedTeamGroup(this.selection);
    }
  }

  /**
   *  Respond to the user editing a single item.
   *
   * @param       {*}                         row       The current row in the data tabl
   * @param       {Event}                     [event]   The (`click`) event signalling the user's intent.
   * @memberof    TeamGroupsListComponent
   */
  onEditIntent(row?: any, event?: Event) {
    let isNew = true;
    let formValues = <any>{};

    if (row) {
      isNew = false;
    }
    if (event) {
      event.stopImmediatePropagation();
    }

    if (!row) {
      formValues = this.emptyFormValues;
    } else {
      const editedRowFormValues = {
        id: [row.id],
        name: [row.name, Validators.required],
        admins: [row.admins, Validators.required],
      };

      formValues = editedRowFormValues;
    }

    const dialogRef = this.editDialog.open(AddEditTeamGroupComponent, {
      data: { formValues: formValues, isNew: isNew },
      disableClose: true,
      height: '500px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'users-teams-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        this.getRows();
      }
    });
  }

  /**
   *  Respond to the user deleting a single item.
   *
   * @param         event The (`click`) event signalling the user's intent.
   * @param         row   The current row in the data table
   * @memberof      TeamGroupsListComponent
   */
  onDeleteIntent(row: any, event?: Event, dialog?: any) {
    this.dialogParameters.data.name = row.name;
    this.errorDialogParameters.data.name = row.name;

    if (event) {
      event.stopImmediatePropagation();
    }

    if (dialog) {
      const dialogRef = this.dg.open(dialog, this.dialogParameters);

      dialogRef.afterClosed().subscribe((results) => {
        if (!results) {
          return;
        }

        this.teamGroupsListService.delete(row).subscribe(
          (resp) => {
            row.hasError = false;
            this.updateTable(row, resp, 'delete');
          },
          (err) => {
            if (err.error && err.error.code === 409) {
              const dialogRefA = this.dg.open(
                this.errorDialog,
                this.errorDialogParameters,
              );
            }

            row.hasError = true;
          },
        );
      });
    } else {
      this.teamGroupsListService.delete(row).subscribe(
        (resp) => {
          row.hasError = false;
          this.updateTable(row, resp, 'delete');
        },
        (err) => {
          if (err.error && err.error.code === 409) {
            const dialogRefA = this.dg.open(
              this.errorDialog,
              this.errorDialogParameters,
            );
          }

          row.hasError = true;
        },
      );
    }
  }

  /**
   *  Update the MatTable after success post/put/delete request.
   *
   * @param         {*}     row                 The current row in the data table
   * @param         {*}     results             The API response after success request
   * @memberof      TeamGroupsListComponent
   */
  updateTable(row: any, results: any, method?: string) {
    const rowIndex = this.dataSource.data.indexOf(row);

    if (method === 'delete') {
      this.dataSource.data.splice(rowIndex, 1);
      this.usersPageSetting.totalElements -= 1;
    } else {
      this.dataSource.data[rowIndex] = results;
      if (method === 'post') {
        this.usersPageSetting.totalElements += 1;
      }
    }

    this.tableInEditMode = { isEditMode: false, row: {} };

    //  This is a tricky solution to refresh the material table
    //
    this.dataSource.filter = '';

    delete this.newFormGroup;

    this.getAllTeamGroups();
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof        TeamGroupsListComponent
   */
  ngOnDestroy() {
    this.serviceSubscription.unsubscribe();
    this.teamGroupsSubscription.unsubscribe();
  }
}
