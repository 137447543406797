import { PageSetting } from "./paged-setting";

export class PagedResult<T> {
  payload: T[];
  page?: PageSetting;
  constructor(payload: T[], page?: PageSetting) {
    this.payload = payload;
    this.page = page;
  };
}
