/** @format */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Observable, throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Improvement } from '../models/improvement';
import { PagedResult } from '../models/paged-result';
import { PageSetting } from '../models/paged-setting';
import { AbstractRestService } from './iq-package-services/abstract-rest-service.service';

const apiPath = environment.apiPath;

/**
 *  Service for managing the improvements.
 *
 * @export
 * @class   ImprovementsService
 * @extends {AbstractRestService<Incident>}
 */
@Injectable()
export class ImprovementsService extends AbstractRestService<Improvement> {
  /**
   * Creates an instance of ImprovementsService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      ImprovementsService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}improvements`, 'improvements');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<Improvement>}
   * @memberof        ImprovementsService
   */
  protected mapToModel(json: any): PagedResult<Improvement> {
    //  If the array doesn't exist, create an empty object
    let payload = [];

    if (json._embedded) {
      payload = <Improvement[]>json._embedded.improvements;
    }

    return new PagedResult<Improvement>(payload, <PageSetting>json.page);
  }

  /**
   *  get single improvement with the teamId param
   *
   * @param       {string}      aModelId  The id of the improvement
   * @param       {string}      teamId    The id of the team
   * @returns
   * @memberof    ImprovementsService
   */
  getSingleImprovement(
    aModelId: string,
    teamId: string,
  ): Observable<Improvement> {
    return this.http
      .get<Improvement>(`${this.actionUrl}/${aModelId}?teamId=${teamId}`)
      .pipe(catchError((err) => _throw(err)));
  }

  /**
   * A generic DELETE method.
   * @param BaseModel aModel
   * @returns Observable<T>
   */
  delete(aModel: any): Observable<Improvement> {
    const deleteURL = `${this.actionUrl}/${aModel.id}?teamId=${aModel._embedded.team.id}`;

    return this.http
      .delete<Improvement>(deleteURL)
      .pipe(catchError((err) => _throw(err)));
  }

  getImprovementsForReviewPage(): Observable<Improvement[]> {
    return this.http.get<Improvement[]>(`${apiPath}improvements/team-group-admin`).pipe(catchError((err) => _throw(err)));
  }
}
