import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'auth-sign-up-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sign-up-page.component.html',
  styleUrls: ['./sign-up-page.component.scss']
})

export class SignUpPageComponent  {

}
