<!-- @format -->

<h1
  class="mat-h1"
  i18n="dashboard page-title"
>
  IPF vragenlijst
</h1>

<section class="main-content change-on-mobile">
  <ng-container
    *ngIf="
      questionnaireLoading$ | async;
      then loading;
      else questionnaireContainer
    "
  ></ng-container>
</section>

<ng-template #questionnaireContainer>
  <ng-container
    *ngIf="questionnaireDetails$ | async; then questionnaire; else noData"
  ></ng-container>
</ng-template>

<ng-template #questionnaire>
  <section
    *ngIf="!(questionnaireLoading$ | async)"
    [formGroup]="questionnaireForm"
  >
    <section class="questionnaire__menu">
      <div>
        <h4>Vragenlijst</h4>
      </div>
      <div></div>
      <div class="questionnaire__menu__buttons">
        <button
          *ngIf="
            canUserAssignAuditor &&
            isTeamWithExtendedAccountType &&
            questionnaireResultStatus ===
              QUESTIONNAIRE_RESULT_STATUS.WAITING_FOR_AUDIT
          "
          mat-flat-button
          color="accent"
          class="no-grow"
          (click)="assignAuditor()"
          i18n="tasks-templates-list button add-new-cyclic-task"
        >
          <mat-icon>policy_alert</mat-icon>
          Assign auditor
        </button>
        <button
          *ngIf="
            isUserTeamAdminOrIPFTeamAdmin &&
            isQuestionnaireNotStartedYet &&
            !isQuestionnaireCompleted
          "
          mat-flat-button
          color="accent"
          class="no-grow"
          (click)="publishQuestionaire()"
        >
          <mat-icon>people</mat-icon>
          Vragenlijst start
        </button>
        <button
          mat-flat-button
          color="accent"
          class="no-grow"
          [disabled]="!questionnaireForm.valid"
          (click)="completeQuestionaire()"
          *ngIf="
            isUserTeamAdminOrIPFTeamAdmin &&
            !isQuestionnaireNotStartedYet &&
            !isQuestionnaireCompleted
          "
        >
          <mat-icon>check</mat-icon>
          Vragenlijst afronden
        </button>

        <button
          mat-flat-button
          color="accent"
          class="no-grow"
          (click)="audit(true)"
          [disabled]="
            (questionnaireDetails$ | async).results[0].audit.completed
          "
          *ngIf="
            isUserAuditor &&
            questionnaireResultStatus ===
              QUESTIONNAIRE_RESULT_STATUS.WAITING_FOR_AUDIT
          "
        >
          <mat-icon>check</mat-icon>
          Complete audit
        </button>

        <!-- temporary commented out save button due to dynamic form saving on any form change -->
        <!-- <button
          mat-flat-button
          color="accent"
          class="no-grow"
          (click)="saveQuestionaire()"
          i18n="tasks-templates-list button add-new-cyclic-task"
          *ngIf="!isQuestionnaireNotStartedYet && !isQuestionnaireCompleted"
        >
          <mat-icon>save</mat-icon>
          Antwoorden opslaan
        </button> -->
      </div>
    </section>

    <div
      style="margin: 20px 0"
      *ngIf="
        (questionnaireDetails$ | async).results[0]?.audit?.auditedBy as auditor
      "
    >
      <div
        *ngIf="
          canUserAssignAuditor &&
          isTeamWithExtendedAccountType &&
          questionnaireResultStatus ===
            QUESTIONNAIRE_RESULT_STATUS.WAITING_FOR_AUDIT
        "
      >
        <span>
          Momenteel geselecteerde auditor: {{ auditor.firstName }}
          {{ auditor.lastName }} ({{ auditor.emailAddress }})
        </span>
      </div>
    </div>

    <div
      *ngIf="(message$ | async) && !isUserAuditor"
      class="questionnaire__message-banner"
    >
      <span>{{ message$ | async }}</span>
    </div>

    <section *ngIf="questionnaireDetails$ | async as questionnaireDetails">
      <div class="questionnaire__section">
        <h4>{{ questionnaireDetails.title }}</h4>
        <h5>{{ questionnaireDetails.description }}</h5>
      </div>

      <ng-container *ngFor="let dimension of questionnaireDetails.dimensions">
        <div
          *ngIf="
            (dimension.type === DIMENSION_TYPE.TEAM_ADMIN_ONLY &&
              isUserTeamAdminOrIPFTeamAdmin) ||
            dimension.type !== DIMENSION_TYPE.TEAM_ADMIN_ONLY
          "
        >
          <h4>{{ dimension.title }}</h4>
          <div
            *ngFor="let subdimension of dimension.subdimensions"
            class="questionnaire__section"
          >
            <h4>{{ subdimension.title }}</h4>
            <ng-container
              *ngFor="let singleQuestionGroup of subdimension.questionGroups"
            >
              <app-questionnarie-radio
                [questionGroup]="singleQuestionGroup"
              ></app-questionnarie-radio>

              <app-questionnarie-file-upload
                *ngIf="showFileUploadSection && subdimension.provable"
                [subDimensionId]="subdimension.id"
                [submissionId]="submissionId"
                [results]="questionnaireDetails.results"
                [isFileUploadDisabled]="
                  (isQuestionnaireNotStartedYet && !isQuestionnaireCompleted) ||
                  questionnaireResultStatus ===
                    QUESTIONNAIRE_RESULT_STATUS.WAITING_FOR_AUDIT ||
                  questionnaireResultStatus ===
                    QUESTIONNAIRE_RESULT_STATUS.COMPLETED
                "
                [proofsRequirements]="subdimension.proofsRequirements"
              ></app-questionnarie-file-upload>
              <app-questionnaire-auditor-form
                [questionGroup]="singleQuestionGroup"
                [averageQuestionGroupScores]="
                  questionnaireDetails.results[0].resultScoreDetails
                    .averageQuestionGroupScores
                "
                [existingAudit]="questionnaireDetails.results[0].audit"
                *ngIf="
                  isUserAuditor &&
                  isTeamWithExtendedAccountType &&
                  (questionnaireResultStatus ===
                    QUESTIONNAIRE_RESULT_STATUS.WAITING_FOR_AUDIT ||
                    questionnaireResultStatus ===
                      QUESTIONNAIRE_RESULT_STATUS.COMPLETED)
                "
              ></app-questionnaire-auditor-form>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </section>
  </section>
</ng-template>

<ng-template #loading>
  <h2>Ophalen vragenlijst</h2>
</ng-template>

<ng-template #noData>
  <h2>Geen vragenlijst beschikbaar op dit moment</h2>
</ng-template>
