/** @format */

import { Injectable } from '@angular/core';
import { ScoreItem } from '../models/scoreitem';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { PagedResult } from '../models/paged-result';
import { PageSetting } from '../models/paged-setting';
import { AbstractRestService } from './iq-package-services/abstract-rest-service.service';

const apiPath = environment.apiPath;

/**
 *  Service for managing the Score items list.
 *
 * @export
 * @class         ScoreItemsListService
 * @extends       {AbstractRestService<ScoreItem>}
 */
@Injectable()
export class ScoreItemsListService extends AbstractRestService<ScoreItem> {
  /**
   * Creates an instance of ScoreItemsListService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      ScoreItemsListService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}score-items`, 'score-items');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<ScoreItem>}
   * @memberof        ScoreItemsListService
   */
  protected mapToModel(json: any): PagedResult<ScoreItem> {
    //  If the Team array doesn't exist, create an empty Team object
    let payload = [];

    if (json._embedded) {
      payload = <ScoreItem[]>json._embedded.scoreItems;
    }

    return new PagedResult<ScoreItem>(payload, <PageSetting>json.page);
  }
}
