/** @format */

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { throwError as _throw, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TasksTemplate } from '../models/tasks-template';
import { TasksTemplateFrequency } from '../models/tasks-template-frequency';
import { forkJoin } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { ChildrenComponentList } from '../models/children-component-list';
import { PagedResult } from '../models/paged-result';
import { PageSetting } from '../models/paged-setting';
import { AbstractRestService } from './iq-package-services/abstract-rest-service.service';

const apiPath = environment.apiPath;

/**
 *  Service for managing the tasks templates.
 *
 * @export
 * @class TasksTemplateService
 * @extends {AbstractRestService<TasksTemplate>}
 */
@Injectable()
export class TasksTemplateService extends AbstractRestService<TasksTemplate> {
  /**
   * Creates an instance of TasksTemplateService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      TasksTemplateService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}task-templates`, 'task-templates');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<ScoreItem>}
   * @memberof        TasksTemplateService
   */
  protected mapToModel(json: any): PagedResult<TasksTemplate> {
    //  If the Team array doesn't exist, create an empty Team object
    let payload = [];

    if (json._embedded) {
      payload = <TasksTemplate[]>json._embedded.taskTemplates;
    }

    return new PagedResult<TasksTemplate>(payload, <PageSetting>json.page);
  }

  /**
   *  Fetch the list of frequencies
   *
   * @returns     {Observable<TasksTemplateFrequency[]>}
   * @memberof    TasksTemplateService
   */
  getFrequencies(): Observable<TasksTemplateFrequency[]> {
    return this.http.get<any>(apiPath + 'frequencies').pipe(
      map((frequencies) => {
        if (frequencies._embedded) {
          return frequencies._embedded.taskTemplateFrequencies;
        } else {
          return [];
        }
      }),
    );
  }

  /**
   * A generic DELETE method.
   * @param         BaseModel             aModel
   * @returns       {Observable<T>}
   */
  deleteTasksTemplate(aModel: any, teamId: string): Observable<TasksTemplate> {
    const deleteURL = `${this.actionUrl}/${aModel.id}?teamId=${teamId}`;

    return this.http
      .delete<TasksTemplate>(deleteURL)
      .pipe(catchError((err) => _throw(err)));
  }

  /**
   *  Join the promises while updating the task templates in the parent form
   *
   * @param    {TasksTemplate[]} taskTemplatesList
   * @returns  {Observable<any>}
   * @memberof TasksTemplateService
   */
  forkJoinChildRequests(
    taskTemplatesList: ChildrenComponentList,
    formGroup: UntypedFormGroup,
  ): Observable<any> {
    const promisesList = [];
    const tasktemplateIds = [];

    if (taskTemplatesList.value && taskTemplatesList.value.length) {
      for (const taskTemplate of taskTemplatesList.value) {
        if (
          taskTemplate.temporaryFields &&
          taskTemplate.temporaryFields.removed
        ) {
          const teamid = taskTemplate.teamId
            ? taskTemplate.teamId
            : taskTemplatesList.teamId;

          promisesList.push(this.deleteTasksTemplate(taskTemplate, teamid));
        } else if (
          taskTemplate.temporaryFields &&
          taskTemplate.temporaryFields.isNew
        ) {
          promisesList.push(this.postSingleTemplate(taskTemplate));
        } else if (
          taskTemplate.temporaryFields &&
          taskTemplate.id &&
          taskTemplate.temporaryFields.changed
        ) {
          promisesList.push(this.put(taskTemplate));
        } else {
          tasktemplateIds.push(taskTemplate.id);
        }
      }
    }

    if (tasktemplateIds && formGroup.get(taskTemplatesList.fieldName)) {
      const obj = {};
      obj[taskTemplatesList.fieldName] = tasktemplateIds;

      formGroup.patchValue(obj);
    }

    return forkJoin(promisesList);
  }

  /**
   * Post the single task template
   *
   * @param    {*} taskTemplate
   * @returns  {Observable<TasksTemplate>}
   * @memberof TasksTemplateService
   */
  postSingleTemplate(taskTemplate: any): Observable<TasksTemplate> {
    return this.http
      .post<TasksTemplate>(`${this.actionUrl}/single`, taskTemplate)
      .pipe(catchError((err) => _throw(err)));
  }
}
