/** @format */

import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { throwError as _throw } from 'rxjs';
import { PMR } from '../models/pmr';
import { map, catchError } from 'rxjs/operators';
import { PagedResult } from '../models/paged-result';
import { PageSetting } from '../models/paged-setting';
import { AbstractRestService } from './iq-package-services/abstract-rest-service.service';

const apiPath = environment.apiPath;

/**
 *  Service for managing the PMRs.
 *
 * @export
 * @class         PmrService
 * @extends       {AbstractRestService<PMR>}
 */
@Injectable()
export class PmrService extends AbstractRestService<PMR> {
  /**
   * Creates an instance of PmrService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      PmrService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}pmr`, 'pmr');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<PMR>}
   * @memberof        PmrService
   */
  protected mapToModel(json: any): PagedResult<PMR> {
    //  If the PMR array doesn't exist, create an empty PMR object
    let payload = [];

    if (json._embedded) {
      payload = <PMR[]>json._embedded.pmr;
    }

    return new PagedResult<PMR>(payload, <PageSetting>json.page);
  }

  /**
   *  Get the active PMR
   *
   * @param    {string} teamid
   * @returns
   * @memberof PmrService
   */
  getCurrentPmr(teamid: string) {
    const getURL = `${apiPath}pmr/current/${teamid}`;

    return this.http.get<PMR>(getURL).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => _throw(err)),
    );
  }
}
