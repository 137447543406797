<auth-forgot-password-form (submitted)="onSubmit($event)" *ngIf="!requestSent">
</auth-forgot-password-form>

<mat-card class="request-password-confirmation" *ngIf="requestSent">
  <span i18n="The title of the forgot password window@@auth.forgotPasswordWindowTitle">Forgot password</span>
  <div i18n="The request password confirmation window@@auth.requestPasswordConfirmation" class="request-password-confirmation">
    If the supplied e-mail address is correct, a password reset token has been sent to that address.
  </div>
  <button mat-button routerLink='/auth/login'>
    Go back to login
  </button>
</mat-card>