/** **/
import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

/** **/
// import { LoginPageComponent } from './containers/login-page.component';
// import { LoginFormComponent } from './components/login-form.component';
// import { ResetPasswordPageComponent } from './containers/reset-password-page.component';
// import { ResetPasswordFormComponent } from './components/reset-password-form.component';
// import { SetPasswordPageComponent } from './containers/set-password-page.component';
// import { SetPasswordFormComponent } from './components/set-password-form.component';
// import { SignUpPageComponent } from './containers/sign-up-page.component';
// import { SignUpFormComponent } from './components/sign-up-form.component';
// import { ForgotPasswordPageComponent } from './containers/forgot-password-page.component';
// import { ForgotPasswordFormComponent } from './components/forgot-password-form.component';
import { malConfigService, ProjConfig } from './services/iq-auth-services/config.service';
import { CoreModule } from './iq-core.module';
import { LoaderService } from './services/iq-package-services/loader.service';
import { LoginPageComponent } from './components/iq-auth-components/login-page.component';
import { LoginFormComponent } from './components/iq-auth-components/login-form.component';
import { ForgotPasswordPageComponent } from './components/iq-auth-components/forgot-password-page.component';
import { ForgotPasswordFormComponent } from './components/iq-auth-components/forgot-password-form.component';
import { ResetPasswordPageComponent } from './components/iq-auth-components/reset-password-page.component';
import { ResetPasswordFormComponent } from './components/iq-auth-components/reset-password-form.component';
import { SetPasswordPageComponent } from './components/iq-auth-components/set-password-page.component';
import { SetPasswordFormComponent } from './components/iq-auth-components/set-password-form.component';
import { SignUpPageComponent } from './components/iq-auth-components/sign-up-page.component';
import { SignUpFormComponent } from './components/iq-auth-components/sign-up-form.component';

/** **/
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/iq-auth-services/auth.service';
/** **/

/**
 *
 * type [any<component>,any<component>]
 */
const COMPONENTS = [
  LoginPageComponent,
  LoginFormComponent,
  ForgotPasswordPageComponent,
  ForgotPasswordFormComponent,
  ResetPasswordPageComponent,
  ResetPasswordFormComponent,
  SetPasswordPageComponent,
  SetPasswordFormComponent,
  SignUpPageComponent,
  SignUpFormComponent
];

@NgModule({
  imports: [
    CoreModule
  ],
  declarations: [COMPONENTS],
  providers: [LoaderService, malConfigService],
  exports: [COMPONENTS],
})
export class IqAuthModule {

  constructor(@Optional() @SkipSelf() parentModule: IqAuthModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }

  static forRoot(config?: ProjConfig): ModuleWithProviders<IqAuthModule> {
    return {
      ngModule: RootAuthModule,
      providers: [
        { provide: malConfigService, useValue: config },
        AuthService,
        AuthGuard,
        LoaderService
      ]
    };
  }

}

@NgModule({
  imports: [
    IqAuthModule,
    RouterModule.forChild([
      {
        path: 'auth',
        data: {
          group: 'auth',
          level: 0,
          name: 'User',
          safeLevel: 0
        },
        children: [
          {
            path: '',
            redirectTo: '/auth/login',
            pathMatch: 'full'
          },
          {
            path: 'password',
            children: [
              {
                path: 'forgot',
                component: ForgotPasswordPageComponent,
                data: {
                  group: 'auth',
                  level: 1,
                  name: 'Forgot password',
                  safeLevel: 0
                },
              },
              {
                path: 'set',
                component: SetPasswordPageComponent,
                data: {
                  group: 'auth',
                  level: 1,
                  name: 'Set password - manual',
                  safeLevel: 0
                },
              },
              {
                path: 'set/:token',
                component: SetPasswordPageComponent,
                data: {
                  group: 'auth',
                  level: 1,
                  name: 'Set Password',
                  safeLevel: 0
                },
              },
              {
                path: 'reset',
                component: ResetPasswordPageComponent,
                data: {
                  group: 'auth',
                  level: 1,
                  name: 'Reset password',
                  safeLevel: 0
                },
              }
            ]
          },
          {
            path: 'login',
            component: LoginPageComponent,
            data: {
              group: 'auth',
              level: 1,
              name: 'Login',
              safeLevel: 0
            },
          },
          {
            path: 'sign-up',
            component: SignUpPageComponent,
            data: {
              group: 'auth',
              level: 1,
              name: 'Register',
              safeLevel: 0
            },
          }
        ]
      }
    ]),
  ],
  providers: [LoaderService],
})
export class RootAuthModule { }
