<mat-card>
  <span i18n="The title of the forgot password window@@auth.forgotPasswordWindowTitle">Forgot password</span>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>Email address</mat-label>
      <input matInput type="email" placeholder="youremail@email.com" formControlName="emailAddress" required>
      <mat-error [hidden]="!(formErrors.emailAddress)">{{ formErrors.emailAddress }}</mat-error>
    </mat-form-field>

    <button mat-button (click)="submit()" [disabled]="form.invalid || form.disabled" type="submit">
      <i class='fa fa-circle-o-notch fa-spin'></i>
      Send
    </button>
  </form>
  <mat-horizontal-stepper #stepper="matHorizontalStepper">
  </mat-horizontal-stepper>
</mat-card>