import { Component, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../services/iq-package-services/layout.service';

@Component({
  selector: 'marviq-topnav',
  template: `
    <mat-toolbar *ngIf="nlines != 2" color="primary">
      <mat-toolbar-row>

        <!--<img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu">-->
        <div class="logo-container">
          <ng-content select="[firLogo]">
          </ng-content>
        </div>

        <span class="example-spacer"></span>

        <iqc-menu-hor-item links="{{ ntLinks }}" filterBy="{{ nfilterBy }}" filterValue="{{ nfilterValue }}">
        </iqc-menu-hor-item>


        <ng-content select="[userPanel]"></ng-content>

      </mat-toolbar-row>
    </mat-toolbar>

    <mat-toolbar *ngIf="lines != 1" color="mat-accent">
      <mat-toolbar-row>

        <ng-content select="[secLogo]">
        </ng-content>

        <span class="example-spacer"></span>

        <iqc-menu-hor-item links="{{ nbLinks }}" filterBy="{{ nfilterBy }}" filterValue="{{ nfilterValue }}">
        </iqc-menu-hor-item>

        <span class="example-spacer"></span>

        <iqc-menu-hor-item links="{{ nbbLinks }}" filterBy="{{ nfilterBy }}" filterValue="{{ nfilterValue }}">
        </iqc-menu-hor-item>

      </mat-toolbar-row>
    </mat-toolbar>
  `,
  styles: [
    `
      .example-icon {
        padding: 0 14px;
      }

      .example-spacer {
        flex: 1 1 auto;
      }
    `,
  ],
})
export class ToolbarComponent implements OnInit {
  @Input() lines = 0;
  @Input() filterBy = '';
  @Input() filterValue = '';
  @Input() tLinks = [];
  @Input() bLinks = [];
  @Input() tbLinks = [];
  @Input() bbLinks = [];

  public nlines = 0;
  public nfilterBy = '';
  public nfilterValue = '';
  public ntLinks = [];
  public nbLinks = [];
  public ntbLinks = [];
  public nbbLinks = [];

  constructor(private layoutService: LayoutService) { }

  ngOnInit() {

    this.nlines = this.lines;
    this.nfilterBy = this.filterBy;
    this.nfilterValue = this.filterValue;
    this.ntLinks = this.tLinks;
    this.nbLinks = this.bLinks;
    this.ntbLinks = this.tbLinks;
    this.nbbLinks = this.bbLinks;

  }



  navOpen() {
    this.layoutService.menuOther(true);
  }

}
