import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotFoundPageComponent } from './components/iq-package-components/not-found-page';
import { LayoutComponent } from './components/iq-package-components/layout-component/layout';
import { NavItemComponent } from './components/iq-package-components/nav-item';
import { SidenavComponent } from './components/iq-package-components/sidenav';
import { ToolbarComponent } from './components/iq-package-components/toolbar';
import { ContainerComponent } from './components/iq-package-components/container';
import { FooterComponent } from './components/iq-package-components/footer';
import { ToolItemComponent } from './components/iq-package-components/tool-item';
import { UserPanelComponent } from './components/iq-package-components/user-panel';
import { AppComponentC as malCApp } from './components/iq-package-components/app-component/app';
import { MarviqMatTableComponent } from './components/iq-package-components/marviq-mat-table.component';
import { MarviqMatTableInlineCrudComponent } from './components/iq-package-components/marviq-mat-table-inline-crud.component';
import { LayoutService } from './services/iq-package-services/layout.service';
import { SnackbarService } from './services/iq-package-services/snackbar.service';
import { LoaderService } from './services/iq-package-services/loader.service';
import { MarviqMatTableFilterComponent } from './components/iq-package-components/marviq-mat-table-filter/marviq-mat-table-filter.component';

const COMPONENTS = [
  NotFoundPageComponent,
  LayoutComponent,
  NavItemComponent,
  SidenavComponent,
  ToolbarComponent,
  ContainerComponent,
  FooterComponent,
  ToolItemComponent,
  UserPanelComponent,
  malCApp
];

const OTHER = [
  MarviqMatTableComponent,
  MarviqMatTableInlineCrudComponent,
  MarviqMatTableFilterComponent
];

const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,

  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatStepperModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
];
@NgModule({
  imports: [MODULES],
  declarations: [COMPONENTS, OTHER],
  exports: [COMPONENTS, MODULES, OTHER],
  providers: [LayoutService, SnackbarService, LoaderService]
})
export class CoreModule { }
