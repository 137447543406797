/** @format */

import { OnInit, Inject, Component } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import { UserRights } from '../models/user-rights';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { ChildrenComponentList } from '../models/children-component-list';
import { WarningDeleteDialogComponent } from './warning-delete-dialog/warning-delete-dialog.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CHART_TYPES } from '../models/sunburst';
import { AbstractRestService } from '../services/iq-package-services/abstract-rest-service.service';

/**
 *  Abstract class for add/edit dialogs
 *  The data passed from the parent element: { formValues: formValues, isNew: isNew }
 *
 * @export
 * @class       AddEditAbstractDialog
 * @implements  {OnInit}
 */
// ! added component decorator
@Component({
  template: '',
})
export abstract class AddEditAbstractDialog<T> implements OnInit {

  /**
   *  The list of child components - shold be specified if we want to use the
   *
   * @type {any[]}
   * @memberof AddEditAbstractDialog
   */
  childComponentsList: ChildrenComponentList[];

  /**
   *  True/false if any of the child components has been changed
   *
   * @memberof AddEditAbstractDialog
   */
  childChanged = false;

  /**
   *  True/false if at least one of the child components is invalid
   *
   * @memberof AddEditAbstractDialog
   */
  childInvalid = false;

  /**
   *  The current user rights.
   *
   * @type                  {UserRights}
   * @memberof              AddEditAbstractDialog
   */
  currentUserRights: UserRights;

  /**
   *  True/false if the dialog state has been changed
   *  If yes the add/put/ delete/ request was successfully sent
   *
   * @memberof                AddEditAbstractDialog
   */
  dialogStateChanged = false;

  /**
   *  The results of the dialog
   *
   * @memberof AddEditAbstractDialog
   */
  dialogResults = <any>{
    changed: false,
    resp: <any>{},
  };

  /**
   *  True/false if the form is changed.
   *
   * @memberof                AddEditAbstractDialog
   */
  formChanged = false;

  /**
   *  True/false if the form is successfully submited
   *
   * @memberof                AddEditAbstractDialog
   */
  formSubmitted = false;

  /**
   *  True/false if the form is new.
   *
   * @memberof                AddEditAbstractDialog
   */
  formIsNew = false;

  /**
   *  The object of form validation errors.
   *
   * @memberof                AddEditAbstractDialog
   */
  formErrors = <any>{};

  /**
   *  The initial values of the form that will be compared to the current
   *
   * @memberof                AddEditAbstractDialog
   */
  initialFormValues = <any>{};

  /**
   *  The list of empty fields shown in the tooltip message
   *
   * @memberof                AddEditAbstractDialog
   */
  tooltipMessage = '';

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AddEditAbstractDialog
   */
  validationMessages = <any>{};

  /**
   *  The form group.
   *
   * @memberof                AddEditAbstractDialog
   */
  newFormGroup: UntypedFormGroup;

  /**
   *  Compare the prev and the current option from the MatSelect element.
   *
   * @memberof                AddEditAbstractDialog
   */
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareValues;

  /**
   *  The data passed to the dialog.
   *
   * @memberof                AddEditAbstractDialog
   */
  warningDialogParameters = <any>{
    panelClass: 'delete-dialog',
    maxWidth: '1000px',
    disableClose: true,
    data: {},
  };

  /**
   *  The warning dialog instance
   *
   * @memberof AddEditAbstractDialog
   */
  warnDialog = WarningDeleteDialogComponent;

  /**
   * Creates an instance of AddEditAbstractDialog.
   *
   * @param       {MatDialogRef<AddEditAbstractDialog<T>>}  dialogRef             The instance of MatDialogRef
   * @param       {*}                                       data                  The data passed from the parent
   * @param       {AddEditAbstractDialog<T>}                dialogService
   * @param       {FormBuilder}                             fb
   * @memberof    AddEditAbstractDialog
   */
  constructor(
    public dialogRef: MatDialogRef<AddEditAbstractDialog<T>>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: AbstractRestService<T>,
    private fb: UntypedFormBuilder,
    private dialogMat?: MatDialog,
    public http?: HttpClient
  ) { }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof        AddEditAbstractDialog
   */
  ngOnInit() {
    this.initialFormValues = this.data.formValues;
    this.formIsNew = this.data.isNew;

    this.createForm(this.initialFormValues);
  }

  /**
   *  Creates the form instance with initial values.
   *
   * @memberof AddEditAbstractDialog
   */
  createForm(data?: any): void {
    this.newFormGroup = this.fb.group(data);

    this.newFormGroup.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((changes) => this.onFormValueChanged(changes));

    this.onFormValueChanged();
  }

  /**
   *  Compare two select options form the MatSelect form field.
   *
   * @param         {*}           f1
   * @param         {*}           f2
   * @returns
   * @memberof      AddEditAbstractDialog
   */
  compareValues(f1: any, f2: any) {
    return f1 && f2 && f1.id === f2.id;
  }

  /**
   *  Check if the form has been changed
   *
   * @memberof AddEditAbstractDialog
   */
  formHasChanged(externalChildChanged?: boolean) {
    this.formChanged = false;

    for (const field of Object.keys(this.formErrors)) {
      if (
        this.initialFormValues[field] &&
        this.newFormGroup.get(field) &&
        this.newFormGroup.get(field).value !== this.initialFormValues[field][0]
      ) {
        this.formChanged = true;
        this.formSubmitted = false;

        break;
      }
    }

    this.childChanged = false;

    if (this.childComponentsList && this.childComponentsList.length) {
      for (const child of this.childComponentsList) {
        child.hasChanged = false;

        for (const item of child.value) {
          if (
            item.temporaryFields &&
            (item.isNew ||
              item.temporaryFields.changed ||
              item.temporaryFields.removed)
          ) {
            this.formSubmitted = false;
            child.hasChanged = true;
            this.childChanged = true;

            break;
          }
        }
      }

      if (externalChildChanged) {
        this.childChanged = true;
      }
    }
  }

  /**
   *  Respond to the cancel on the dialog
   *
   * @returns
   * @memberof AddEditUserComponent
   */
  onCancel(withWarning?: boolean, stateChanged?: boolean) {
    if ((this.formChanged || this.childChanged) && !this.formSubmitted) {
      if (withWarning) {
        const dialogWar = this.dialogMat.open(
          this.warnDialog,
          this.warningDialogParameters,
        );

        dialogWar.afterClosed().subscribe((results) => {
          if (!results) {
            return;
          }

          this.dialogStateChanged = true;
          this.dialogResults.changed = this.dialogStateChanged;
          this.dialogRef.close(this.dialogResults);
        });
      } else {
        this.dialogResults.changed = this.dialogStateChanged;
        this.dialogRef.close(this.dialogResults);
      }
    } else {
      if (stateChanged === true) {
        this.dialogStateChanged = true;
      } else if (stateChanged === false) {
        this.dialogStateChanged = false;
      }

      this.dialogResults.changed = this.dialogStateChanged;
      this.dialogRef.close(this.dialogResults);
    }
  }

  /**
   *  Add a new child item to the childComponent list
   *
   * @param    {Event}  [event]
   * @param    {number} [childsListIndex]
   * @memberof AddEditAbstractDialog
   */
  onChildItemAdd(event?: Event, childsListIndex?: number) {
    if (event) {
      event.stopImmediatePropagation();
    }

    if (
      this.childComponentsList &&
      this.childComponentsList.length &&
      this.childComponentsList[childsListIndex]
    ) {
      const emptyFormValuesClone = JSON.parse(
        JSON.stringify(
          this.childComponentsList[childsListIndex].emptyFormValues,
        ),
      );

      this.childComponentsList[childsListIndex].value.push(
        emptyFormValuesClone,
      );

      this.childComponentsList[childsListIndex].invalid = true;
      this.childComponentsList[childsListIndex].hasChanged = true;
      this.childInvalid = true;
      this.childChanged = true;
      this.formSubmitted = false;
    }
  }

  /**
   *  Respond to the change of the child component item
   *
   * @param    {*}                 [item]
   * @param    {number}            [childListIndex]
   * @param    {number}            [index]
   * @param    {MatExpansionPanel} [expPanel]
   * @memberof AddEditAbstractDialog
   */
  onChildItemChange(
    item?: any,
    childListIndex?: number,
    index?: number,
    expPanel?: MatExpansionPanel,
  ) {
    //  Open the expansion panel after change
    if (expPanel && expPanel.expanded) {
      item.temporaryFields.expanded = true;
    }

    if (this.childComponentsList && this.childComponentsList.length) {
      if (item && (childListIndex || childListIndex === 0)) {
        if (this.childComponentsList[childListIndex].value[index] !== item) {
          for (const field of Object.keys(item)) {
            if (this.childComponentsList[childListIndex].value[index][field]) {
              if (
                this.childComponentsList[childListIndex].value[index][field] !==
                item[field]
              ) {
                this.childComponentsList[childListIndex].value[index][field] =
                  item[field];
              }
            } else {
              this.childComponentsList[childListIndex].value[index][field] =
                item[field];
            }
          }
        }
      }

      this.childInvalid = false;

      for (const child of this.childComponentsList) {
        child.invalid = false;

        for (const childitem of child.value) {
          if (
            childitem.temporaryFields &&
            childitem.temporaryFields.invalid &&
            !childitem.temporaryFields.removed
          ) {
            this.childInvalid = true;
            child.invalid = true;

            break;
          }
        }
      }
    }

    this.formHasChanged();
  }

  /**
   *  Respond to the user deleting a single item.
   *
   * @param    {*}                 item
   * @param    {*}                 childListIndex
   * @param    {Event}             [event]
   * @param    {MatExpansionPanel} [element]
   * @param    {number}            [ind]
   * @memberof AddEditAbstractDialog
   */
  onChildItemDelete(
    item: any,
    childListIndex: number,
    event?: Event,
    element?: MatExpansionPanel,
    ind?: number,
  ) {
    if (event) {
      event.stopImmediatePropagation();
    }

    if (element && element.expanded) {
      element.close();

      if (item.temporaryFields) {
        item.temporaryFields.expanded = false;
      } else {
        item.temporaryFields = {
          expanded: false,
        };
      }
    }

    if (this.childComponentsList && this.childComponentsList.length) {
      if (item.id) {
        if (!item.temporaryFields) {
          item.temporaryFields = {
            removed: true,
          };
        } else {
          item.temporaryFields.removed = true;
        }

        this.onChildItemChange(item, childListIndex, ind);
      } else {
        if (item.temporaryFields && item.temporaryFields.isNew) {
          this.childComponentsList[childListIndex].value.splice(ind, 1);

          this.onChildItemChange();
        }
      }
    }
  }

  /**
   *  Respond to the user restore a single item.
   *
   * @param    {*}      subject
   * @param    {*}      [parentList]
   * @param    {Event}  [event]
   * @param    {number} [ind]
   * @memberof AddEditAbstractDialog
   */
  onChildItemRestore(
    item: any,
    childListIndex?: number,
    event?: Event,
    ind?: number,
  ) {
    if (event) {
      event.stopImmediatePropagation();
    }

    if (item.id && item.temporaryFields && item.temporaryFields.removed) {
      item.temporaryFields.removed = false;

      this.onChildItemChange(item, childListIndex, ind);
    }
  }

  /**
   *  Respond to the close intent on the dialog.
   *
   * @memberof AddEditAbstractDialog
   */
  onDetailsClose(withWarning?: boolean) {
    this.onCancel(withWarning);
  }

  /**
   *  Respond to the change of the form value.
   *
   * @param       {*}                       [data]
   * @returns
   * @memberof    AddEditAbstractDialog
   */
  onFormValueChanged(data?: any) {
    if (!this.newFormGroup) {
      return;
    }

    const form = this.newFormGroup;
    this.tooltipMessage = '';

    for (const field of Object.keys(this.formErrors)) {
      this.formErrors[field] = ''; //  Clear all of messages
      const control = form.get(field);

      if (control && control.invalid) {
        const messages = this.validationMessages[field];

        for (const key of Object.keys(control.errors)) {
          this.formErrors[field] += messages[key] + ' ';
        }

        this.tooltipMessage += this.formErrors[field] + '\n';
      }

      if (form.errors && form.errors[field]) {
        this.formErrors[field] += this.validationMessages[field] + ' ';
      }
    }

    this.formHasChanged();
  }

  /**
   *  Submit the form with the child components
   *
   * @memberof AddEditAbstractDialog
   */
  onParentSubmit() {
    this.updateSimpleChildComponents();

    if (this.formIsNew) {
      this.dialogService.post(this.newFormGroup.value).subscribe(
        (response) => {
          this.formSubmitted = true;
          this.dialogStateChanged = true;
          this.formIsNew = false;
          this.formChanged = false;
          this.dialogResults.resp = response;

          this.updateFormGroupAfterSave(response);

          this.submitSeparateChild();
        },

        () => {
          this.formSubmitted = false;
          this.dialogResults.resp = {};

          if (this.childComponentsList && this.childComponentsList.length) {
            for (const child of this.childComponentsList) {
              if (
                child.hasChanged &&
                !child.invalid &&
                !child.separateRequests
              ) {
                child.value = child.clonedValue;
                const obj = {};

                obj[child.fieldName] = child.value;

                this.newFormGroup.patchValue(obj);
              }
            }
          }
        },
      );
    } else {
      this.dialogService.put(this.newFormGroup.value).subscribe(
        (response) => {
          this.formSubmitted = true;
          this.dialogStateChanged = true;
          this.formIsNew = false;
          this.formChanged = false;
          this.dialogResults.resp = response;

          this.updateFormGroupAfterSave(response);

          this.submitSeparateChild();
        },

        () => {
          this.formSubmitted = false;
          this.dialogResults.resp = {};

          if (this.childComponentsList && this.childComponentsList.length) {
            for (const child of this.childComponentsList) {
              if (
                child.hasChanged &&
                !child.invalid &&
                !child.separateRequests
              ) {
                child.value = child.clonedValue;
                const obj = {};

                obj[child.fieldName] = child.value;

                this.newFormGroup.patchValue(obj);
              }
            }
          }
        },
      );
    }
  }

  /**
   *  Respond to the user submit intent of the form
   *
   * @param       {boolean}                       [saveAndStay]     true/false if the dialog should be opened after save
   * @memberof    AddEditAbstractDialog
   */
  onSubmit(saveAndStay?: boolean, graphCellId?: string, graphType?: CHART_TYPES, editWithPatch?: boolean) {
    const apiPath = environment.apiPath;

    if (
      this.childComponentsList &&
      this.childComponentsList.length &&
      this.childChanged
    ) {
      this.onParentSubmit();
    } else {
      if (this.formIsNew) {
        this.dialogService.post(this.newFormGroup.value).subscribe(
          (response) => {
            this.formSubmitted = true;
            this.dialogStateChanged = true;
            this.formIsNew = false;
            this.childInvalid = false;
            this.formChanged = false;
            this.dialogResults.resp = response;

            if (!saveAndStay) {
              this.onCancel(false, true);
            }

            if (graphCellId) {
              const urlPrefix = graphType === CHART_TYPES.INNOVATION ? 'innovatie' : graphType === CHART_TYPES.QUALITY ? 'quality' : '' // add url for compliancy chart in the end

              this.http.put(`${apiPath}${urlPrefix}-benchmark-results/improvement/${this.currentUserRights.currentTeamId}`, { [graphCellId]: (response as any).id }, {
              }).subscribe();
            }
          },

          () => {
            this.formSubmitted = false;
            this.dialogResults.resp = {};
          },
        );
      } else {
        if (editWithPatch) {
          this.dialogService.patch(this.newFormGroup.value).subscribe(
            (response) => {
              this.formSubmitted = true;
              this.dialogStateChanged = true;
              this.formChanged = false;
              this.dialogResults.resp = response;

              if (!saveAndStay) {
                this.onCancel(false, true);
              }
            },

            () => {
              this.formSubmitted = false;
              this.dialogResults.resp = {};
            }
          );
        } else {
          this.dialogService.put(this.newFormGroup.value).subscribe(
            (response) => {
              this.formSubmitted = true;
              this.dialogStateChanged = true;
              this.formChanged = false;
              this.dialogResults.resp = response;

              if (!saveAndStay) {
                this.onCancel(false, true);
              }
            },

            () => {
              this.formSubmitted = false;
              this.dialogResults.resp = {};
            }
          );
        }

      }
    }
  }

  /**
   *  Submit the more complicated children in the separate request
   *
   * @memberof AddEditAbstractDialog
   */
  submitSeparateChild() {
    if (this.childComponentsList && this.childComponentsList.length) {
      let hasSeparate = false;

      for (const child of this.childComponentsList) {
        child.clonedValue = JSON.parse(JSON.stringify(child.value));

        if (child.hasChanged && !child.invalid && child.separateRequests) {
          hasSeparate = true;

          if (child.value && child.value.length && child.componentService) {
            child.componentService
              .forkJoinChildRequests(child, this.newFormGroup)
              .subscribe(
                (response) => {
                  let childItemsIds = [];

                  if (
                    this.newFormGroup &&
                    this.newFormGroup.get(child.fieldName) &&
                    this.newFormGroup.get(child.fieldName).value
                  ) {
                    childItemsIds = this.newFormGroup.get(
                      child.fieldName,
                    ).value;
                  }

                  if (response.length) {
                    //  Go through the list of child put/post/delete requests responses
                    //  Prepare the list of child ids for given meeting
                    for (const childItem of response) {
                      if (childItem && childItem.id) {
                        childItemsIds.push(childItem.id);
                      }
                    }

                    //  Add the list of childIds to the form
                    const obj = {};
                    obj[child.fieldName] = childItemsIds;
                    this.newFormGroup.patchValue(obj);

                    //  Send PUT request for the current meeting with the new list of taskTemplates
                    this.dialogService.put(this.newFormGroup.value).subscribe(
                      (resp) => {
                        this.childInvalid = false;
                        this.formChanged = false;
                        this.formSubmitted = true;
                        this.dialogResults.resp = resp;

                        this.onCancel(false, this.dialogStateChanged);
                      },

                      () => {
                        this.formSubmitted = false;
                        this.dialogResults.resp = {};
                      },
                    );
                  }
                },

                () => {
                  this.formSubmitted = false;
                  this.dialogResults.resp = {};
                },
              );
          }
        }
      }

      if (!hasSeparate) {
        this.formSubmitted = true;
        this.formIsNew = false;
        this.childInvalid = false;
        this.formChanged = false;

        this.onCancel(false, true);
      }
    }
  }

  /**
   *  Update the simple child components, which don't require sepparate requests
   *
   * @memberof AddEditAbstractDialog
   */
  updateSimpleChildComponents() {
    if (this.childComponentsList && this.childComponentsList.length) {
      for (const child of this.childComponentsList) {
        child.clonedValue = JSON.parse(JSON.stringify(child.value));

        if (child.hasChanged && !child.invalid && !child.separateRequests) {
          if (child.value && child.value.length) {
            //  Remove children from the request
            child.value = child.value.filter((value) => {
              if (value.temporaryFields && value.temporaryFields.removed) {
                return false;
              } else {
                return true;
              }
            });

            if (
              child.value &&
              child.value.length !== child.clonedValue.length
            ) {
              const obj = {};

              obj[child.fieldName] = child.value;

              this.newFormGroup.patchValue(obj);
            }
          }
        }
      }
    }
  }

  /**
   *  Update the formGroup after the successful save
   *
   * @param {*} response
   * @memberof AddEditAbstractDialog
   */
  updateFormGroupAfterSave(response: any) {
    if (this.newFormGroup.value && !this.newFormGroup.value.id) {
      this.newFormGroup.addControl('id', new UntypedFormControl(response.id));
    }
  }
}
