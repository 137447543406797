/** @format */

import { Routes } from '@angular/router';

import { UsersComponent } from './containers/users/users.component';
import { AuthGuard } from './guards/auth.guard';
import { MainContainerComponent } from './containers/main-container/main-container.component';
import { TeamIdGuard } from './guards/teamId.guard';
import { ChooseTeamPanelComponent } from './components/choose-team-panel/choose-team-panel.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { ActionsListComponent } from './containers/actions-list/actions-list.component';
import { IncidentsComponent } from './containers/incidents/incidents.component';
import { ImprovementsComponent } from './containers/improvements/improvements.component';
import { AgendaComponent } from './containers/agenda/agenda.component';
import { YearPlanningComponent } from './containers/year-planning/year-planning.component';
import { FilesLibraryComponent } from './containers/files-library/files-library.component';
import { PmrComponent } from './containers/pmr/pmr.component';
import { NotRightsPageComponent } from './components/not-rights-page/not-rights-page.component';
import { NoRightsGuard } from './guards/no-rights.guard';
import { ImprovementsListComponent } from './components/improvements-list/improvements-list.component';
import { ImprovementAnalysisListComponent } from './components/improvement-analysis-list/improvement-analysis-list.component';
import { IncidentsListComponent } from './components/incidents-list/incidents-list.component';
import { IncidentAnalysisListComponent } from './components/incident-analysis-list/incident-analysis-list.component';
import { TasksOverviewComponent } from './components/tasks-overview/tasks-overview.component';
import { TasksManagementComponent } from './components/tasks-management/tasks-management.component';
import { AgendaMeetingsComponent } from './components/agenda-meetings/agenda-meetings.component';
import { AgendaTemplatesComponent } from './components/agenda-templates/agenda-templates.component';
import { PmrTabComponent } from './components/pmr-tab/pmr-tab.component';
import { PmrDefaultItemsComponent } from './components/pmr-default-items/pmr-default-items.component';
import { PmrItemsCoOverviewComponent } from './components/pmr-items-co-overview/pmr-items-co-overview.component';
import { GraphsComponent } from './containers/graphs/graphs.component';
import { GraphListComponent } from './components/graph-list/graph-list.component';
import { GraphDetailsComponent } from './components/graph-details/graph-details.component';
import { ImprovementReviewGuard } from './guards/improvement-review.guard';
import { GraphContactFormComponent } from './components/graph-contact-form/graph-contact-form.component';
import { QuestionnaireComponent } from './containers/questionnaire/questionnaire.component';
import { ExternalRedirectGuard } from './guards/external-redirect.guard';
import { BlankComponent } from './containers/blank/blank.component';
import { NotFoundPageComponent } from './components/iq-package-components/not-found-page';
import { DashboardQuestionnaireGuard } from './guards/dashboard-questionnaire.guard';

export const routes: Routes = [
  {
    path: '',
    component: MainContainerComponent,
    canActivate: [AuthGuard, TeamIdGuard],
    data: {
      group: 'kms-2',
      level: 0,
      name: '',
      safeLevel: 0,
    },
    children: [
      {
        path: 'home',
        component: DashboardComponent,
        canActivate: [DashboardQuestionnaireGuard], // removed NoRightsGuard from here
        data: {
          group: 'kms',
          readRights: ['READ_DASHBOARD'],
          level: 0,
          name: 'Dashboard',
          safeLevel: 0,
          icon: 'dashboard',
        },
      },
      {
        path: 'graphs',
        component: GraphsComponent,
        canActivate: [NoRightsGuard],
        data: {
          group: 'kms-top-menu',
          readRights: ['READ_GRAPHS'],
          level: 0,
          name: 'Graphs',
          safeLevel: 0,
          icon: 'dashboard',
        },
        children: [
          {
            path: 'list',
            component: GraphListComponent,
            canActivate: [NoRightsGuard],
            data: {
              group: 'kms2',
              level: 1,
              name: 'Graph list',
              safeLevel: 0,
              icon: 'dashboard',
            },
          },
          {
            path: 'contact-coach',
            component: GraphContactFormComponent,
            canActivate: [NoRightsGuard],
            data: {
              group: 'kms2', // which group?
              level: 1,
              name: 'Contact coach',
              safeLevel: 0,
              icon: 'dashboard',
            },
          },
          {
            path: ':chart',
            component: GraphDetailsComponent,
            canActivate: [NoRightsGuard],
            data: {
              group: 'kms2', // which group?
              level: 1,
              name: 'Graph details',
              safeLevel: 0,
              icon: 'dashboard',
            },
          },
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full',
          },
          {
            path: '**',
            redirectTo: 'list',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'tasks-list',
        component: ActionsListComponent,
        canActivate: [NoRightsGuard],
        data: {
          group: 'kms',
          readRights: ['READ_TASKSLIST'],
          level: 0,
          name: 'Takenlijst',
          safeLevel: 0,
          icon: 'task_alt',
        },
        children: [
          {
            path: 'tasks',
            component: TasksOverviewComponent,
            data: {
              group: 'kms2',
              level: 1,
              name: 'Tasks-overview',
              safeLevel: 0,
              icon: 'task_alt',
              animation: 'animatedTab1',
            },
          },
          {
            path: 'task-templates',
            component: TasksManagementComponent,
            data: {
              group: 'kms2',
              level: 1,
              name: 'Task templates',
              safeLevel: 0,
              icon: 'task_alt',
              animation: 'animatedTab2',
            },
          },
          {
            path: '',
            redirectTo: 'tasks',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'year-planning',
        component: YearPlanningComponent,
        canActivate: [NoRightsGuard],
        data: {
          group: 'kms-top-menu',
          readRights: ['READ_YEARPLAN'],
          level: 0,
          name: 'Jaarplan',
          safeLevel: 0,
          icon: 'calendar_today',
        },
      },
      {
        path: 'year-planning/:viewType/:year',
        component: YearPlanningComponent,
        canActivate: [NoRightsGuard],
        data: {
          group: 'year-f',
          readRights: ['READ_YEARPLAN'],
          level: 0,
          name: 'year-category',
          safeLevel: 0,
          icon: 'stars',
        },
      },
      {
        path: 'agenda',
        component: AgendaComponent,
        canActivate: [NoRightsGuard],
        data: {
          group: 'kms',
          readRights: ['READ_AGENDA', 'READ_ALL_AGENDA'],
          level: 0,
          name: 'Agenda',
          safeLevel: 0,
          icon: 'calendar_today',
        },
        children: [
          {
            path: 'meetings',
            component: AgendaMeetingsComponent,
            data: {
              group: 'kms2',
              level: 1,
              name: 'Meetings',
              safeLevel: 0,
              icon: 'calendar_today',
              animation: 'animatedTab1',
            },
          },
          {
            path: 'agenda-templates',
            component: AgendaTemplatesComponent,
            data: {
              group: 'kms2',
              level: 1,
              name: 'Agenda templates',
              safeLevel: 0,
              icon: 'calendar_today',
              animation: 'animatedTab2',
            },
          },
          {
            path: '',
            redirectTo: 'meetings',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'incidents',
        component: IncidentsComponent,
        canActivate: [NoRightsGuard],
        data: {
          group: 'kms',
          readRights: ['READ_INCIDENT', 'READ_ALL_INCIDENT', 'READ_ANALYSIS'],
          level: 0,
          name: 'Meldingen',
          safeLevel: 0,
          icon: 'problem',
        },
        children: [
          {
            path: 'incidents-overview',
            component: IncidentsListComponent,
            canActivate: [NoRightsGuard],
            data: {
              group: 'kms2',
              level: 1,
              name: 'Incidents-overview',
              readRights: ['READ_INCIDENT', 'READ_ALL_INCIDENT'],
              safeLevel: 0,
              icon: 'problem',
              animation: 'animatedTab1',
            },
          },
          {
            path: 'incidents-analysis',
            component: IncidentAnalysisListComponent,
            canActivate: [NoRightsGuard],
            data: {
              group: 'kms2',
              level: 1,
              name: 'Incidents-analysis',
              readRights: ['READ_ANALYSIS'],
              safeLevel: 0,
              icon: 'problem',
              animation: 'animatedTab2',
            },
          },
          {
            path: '',
            redirectTo: 'incidents-overview',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'improvements',
        component: ImprovementsComponent,
        canActivate: [NoRightsGuard],
        data: {
          group: 'kms',
          readRights: [
            'READ_IMPROVEMENT',
            'READ_ALL_IMPROVEMENT',
            'READ_ANALYSIS',
          ],
          level: 0,
          name: 'Verbetermaatregelen',
          safeLevel: 0,
          icon: 'chrome_reader_mode',
        },
        children: [
          {
            path: 'improvements-overview',
            component: ImprovementsListComponent,
            canActivate: [NoRightsGuard],
            data: {
              group: 'kms2',
              level: 1,
              name: 'Improvements-overview',
              readRights: ['READ_IMPROVEMENT', 'READ_ALL_IMPROVEMENT'],
              safeLevel: 0,
              icon: 'chrome_reader_mode',
              animation: 'animatedTab1',
            },
          },
          {
            path: 'improvements-review',
            component: ImprovementsListComponent,
            canActivate: [NoRightsGuard, ImprovementReviewGuard],
            data: {
              group: 'kms2',
              level: 1,
              name: 'Improvements-review',
              readRights: ['READ_IMPROVEMENT', 'READ_ALL_IMPROVEMENT'],
              safeLevel: 0,
              icon: 'chrome_reader_mode',
              animation: 'animatedTab2',
            },
          },
          {
            path: 'improvements-analysis',
            component: ImprovementAnalysisListComponent,
            canActivate: [NoRightsGuard],
            data: {
              group: 'kms2',
              level: 1,
              name: 'Improvements-analysis',
              readRights: ['READ_ANALYSIS'],
              safeLevel: 0,
              icon: 'chrome_reader_mode',
              animation: 'animatedTab3',
            },
          },
          {
            path: '',
            redirectTo: 'improvements-overview',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'questionnaire',
        component: QuestionnaireComponent,
        canActivate: [NoRightsGuard],
        data: {
          group: 'kms',
          readRights: ['READ_QUESTIONNAIRE'],
          level: 0,
          name: 'IPF vragenlijst',
          safeLevel: 0,
          icon: 'list',
        },
      },
      {
        path: 'pmr',
        component: PmrComponent,
        canActivate: [NoRightsGuard],
        data: {
          group: 'kms-top-menu',
          readRights: ['READ_PMR'],
          level: 0,
          name: 'PMR',
          safeLevel: 0,
          icon: 'notes',
        },
        children: [
          {
            path: 'pmr',
            component: PmrTabComponent,
            canActivate: [NoRightsGuard],
            data: {
              group: 'kms2',
              level: 1,
              name: 'PMR overview',
              readRights: ['READ_PMR'],
              safeLevel: 0,
              icon: 'notes',
              animation: 'animatedTab1',
            },
          },
          {
            path: 'pmr-items-overview',
            component: PmrItemsCoOverviewComponent,
            canActivate: [NoRightsGuard],
            data: {
              group: 'kms2',
              level: 1,
              name: 'Pmr items overzicht',
              readRights: ['ONLY_ORG_ADMIN'],
              safeLevel: 0,
              icon: 'notes',
              animation: 'animatedTab2',
            },
          },
          {
            path: 'default-pmr-items',
            component: PmrDefaultItemsComponent,
            canActivate: [NoRightsGuard],
            data: {
              group: 'kms2',
              level: 1,
              name: 'Default pmr items',
              readRights: ['ONLY_ORG_ADMIN'],
              safeLevel: 0,
              icon: 'notes',
              animation: 'animatedTab3',
            },
          },
          {
            path: '',
            redirectTo: 'pmr',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [NoRightsGuard],
        data: {
          group: 'kms-top-menu',
          readRights: ['READ_ALL_TEAM', 'READ_TEAM'],
          level: 0,
          name: 'Gebruikers',
          safeLevel: 0,
          icon: 'people',
        },
      },
      {
        path: 'handboek',
        canActivate: [ExternalRedirectGuard, NoRightsGuard],
        component: BlankComponent,
        data: {
          group: 'kms',
          readRights: ['READ_HANDBOOK'],
          level: 0,
          name: 'Handboek',
          safeLevel: 0,
          icon: 'book',
          externalUrl: 'https://handboek.inkwala.nl/'
        },
      },
      {
        path: 'files-library',
        component: FilesLibraryComponent,
        canActivate: [NoRightsGuard],
        data: {
          group: 'kms',
          readRights: ['READ_FILES_LIBRARY'],
          level: 0,
          name: 'Overige bestanden',
          safeLevel: 0,
          icon: 'folder',
        },
      },
      {
        path: '403',
        component: NotRightsPageComponent,
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'team-choose',
    component: ChooseTeamPanelComponent,
    canActivate: [AuthGuard],
    data: {
      group: 'kms-2',
      level: 0,
      name: 'Choose team',
      safeLevel: 0,
    },
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];
