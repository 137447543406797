import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/iq-auth-services/auth.service';

// import 'rxjs/add/operator/take';
// import 'rxjs/add/operator/map';


@Injectable()
export class AuthGuard implements CanActivate {

  logged: boolean;

  constructor(private auth: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (this.auth.isUserKnown()) {
      return true;
    } else {
      this.router.navigate(['login']);
      return false;
    }

  }


}
