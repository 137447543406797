import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MarviqMatTableComponent } from './marviq-mat-table.component';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators'
import { PagedResult } from '../../models/paged-result';
import { AbstractRestService } from '../../services/iq-package-services/abstract-rest-service.service';

@Component({
  selector: 'malAbsMatTableInlineCrudComponent',
  template: '< br>'
})

export abstract class MarviqMatTableInlineCrudComponent<T> extends MarviqMatTableComponent<T> implements OnInit, OnDestroy {

  /**
   *  True/ false if the multi selection is allowed
   *
   * @memberof                TeamGroupsListComponent
   */
  allowMultiSelect: false;

  /**
   *  The data passed to the dialog.
   *
   * @memberof                TeamGroupsListComponent
   */
  dialogParameters = <any>{

    panelClass: 'delete-dialog',
    minHeight: '320px',
    width: '500px',
    disableClose: true,
    data: {}

  };

  /**
   *  The default values of the new Form.
   *
   * @memberof                TeamGroupsListComponent
   */
  emptyFormValues = {};

  /**
   *  The object of form validation errors.
   *
   * @memberof                TeamGroupsListComponent
   */
  formErrors = {};

  /**
   *  The initial selection in the TeamGroup table
   *
   * @memberof                TeamGroupsListComponent
   */
  initialSelection: Array<T> = [];

  /**
   *  The form group storing the new Score.
   *
   * @memberof                TeamGroupsListComponent
   */
  newFormGroup?: FormGroup;

  /**
   *  The current selection in the TeamGroup table
   *
   * @memberof                TeamGroupsListComponent
   */
  selection: SelectionModel<T>;

  /**
   *  True/false if data table is in the editMode.
   *
   * @memberof                TeamGroupsListComponent
   */
  tableInEditMode = { isEditMode: false, row: {} };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                TeamGroupsListComponent
   */
  validationMessages = {};

  /**
   * Creates an instance of MarviqMatTableInlineCrudComponent.
   *
   * @memberof    TeamGroupsListComponent
   */
  constructor(private apiService2: AbstractRestService<T>,
    private fb: FormBuilder,
    private dialog?: MatDialog) {
    super(apiService2);
  }


  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof      TeamGroupsListComponent
   */
  ngOnInit() {

    this.selection = new SelectionModel<T>(this.allowMultiSelect, this.initialSelection);
    this.getRows();

  }

  /**
   *  Creates the form in the FormGroup
   *
   * @memberof      TeamGroupsListComponent
   */
  createForm(data?: any): void {

    this.newFormGroup = this.fb.group(data);

    this.newFormGroup.valueChanges
      .subscribe(changes => this.onFormValueChanged(changes));

    this.onFormValueChanged();

  }


  /**
   *  Overrides the getRows() method from MarviqMatTable component
   *  Get all rows for the table view
   *
   * @memberof      TeamGroupsListComponent
   */
  getRows() {

    //  Clear selection on the TeamGroup table
    if (this.selection && this.selection.hasValue()) {
      this.selection.clear();
    }

    //  Get all rows in the list
    this.serviceSubscription = this.apiService2
      .getAll(this.usersPageSetting, this.filterValues, this.sortValues, this.queryParams).pipe(
        map((resp: PagedResult<T>) => resp)
      )
      .subscribe((result) => {
        this.dataSource.data = result.payload;

        if (this.newFormGroup) {
          this.dataSource.data.unshift(Object.assign({ editMode: true }, this.newFormGroup.value));
          this.dataSource.filter = '';
        }
        if (result.page) {
          this.usersPageSetting = result.page;
        }
      });
  }


  /**
   *  Responds to the user click event on the row.
   *
   * @param       row   The current row in the data table
   * @memberof    TeamGroupsListComponent
   */
  onRowClick(row: any) {

    //  Prevent selection if the row is in editMode.
    //
    if (!row.editMode) {
      this.selection.toggle(row);
    }

  }


  /**
   *  Respond to the addition of a row
   *
   * @param         event   The (`click`) event signalling the user's intent.
   * @memberof      TeamGroupsListComponent
   */
  onAddIntent(event?: Event) {

    if (event) { event.stopImmediatePropagation(); }

    if (!this.newFormGroup) {

      this.createForm(this.emptyFormValues);
      const newForm = Object.assign({ isNew: true }, (this.newFormGroup as any).value);

      this.dataSource.data.unshift(newForm);

      //  This is a tricky solution to refresh the material table
      //
      this.dataSource.filter = '';

      //  Trigger the editIntent on the created row.
      const rowIndex = this.dataSource.data.indexOf(newForm);
      this.onEditIntent(this.dataSource.data[rowIndex]);

    }
  }


  /**
   *  Respond to the user deleting a single item.
   *
   * @param         event The (`click`) event signalling the user's intent.
   * @param         row   The current row in the data table
   * @memberof      TeamGroupsListComponent
   */
  onDeleteIntent(row: any, event?: Event, dialog?: any) {

    this.dialogParameters.data.name = row.name;

    if (event) { event.stopImmediatePropagation(); }

    if (dialog) {

      const dialogRef = this.dialog?.open(dialog, this.dialogParameters);

      dialogRef?.afterClosed().subscribe(results => {

        if (!results) { return; }

        this.apiService2.delete(row).subscribe(

          resp => {

            row.hasError = false;
            this.updateTable(row, resp, 'delete');

          },
          err => {

            row.hasError = true;
          }
        );
      });

    } else {

      this.apiService2.delete(row).subscribe(

        resp => {

          row.hasError = false;
          this.updateTable(row, resp, 'delete');

        },
        err => {

          row.hasError = true;
        }
      );
    }

  }


  /**
   *  Respond to the user editing a single item.
   *
   * @param       row       The current row in the data tabl
   * @param       [event]   The (`click`) event signalling the user's intent.
   * @memberof    TeamGroupsListComponent
   */
  onEditIntent(row: any, event?: Event) {

    const editedRowFormValues = {};

    if (event) { event.stopImmediatePropagation(); }

    //  Prevent editing new row if table is currently in the editMode
    //
    if (!this.tableInEditMode.isEditMode) {

      row.editMode = true;
      this.tableInEditMode = { isEditMode: true, row: row };


      if (!row.isNew) {
        this.createForm(editedRowFormValues);
      }

      //  This is a tricky solution to refresh the material table
      //
      this.dataSource.filter = '';
    }
  }


  /**
   *  Respond to the user canceling the form edition.
   *
   * @param       row       The current row in the data tabl
   * @param       [event]   The (`click`) event signalling the user's intent.
   * @memberof    TeamGroupsListComponent
   */
  onFormCancel(row: any, event?: Event) {

    if (event) {
      event.stopImmediatePropagation();
    }

    this.tableInEditMode = { isEditMode: false, row: {} };
    row.editMode = false;
    row.hasError = false;

    delete this.newFormGroup;

    if (row.isNew) {

      const rowIndex = this.dataSource.data.indexOf(row);
      this.dataSource.data.splice(rowIndex, 1);

      //  This is a tricky solution to refresh the material table
      //
      this.dataSource.filter = '';
    }
  }


  /**
   *  Respond to the user submitting the form.
   *
   * @param       row       The current row in the data table
   * @param       [event]   The (`click`) event signalling the user's intent.
   * @memberof    TeamGroupsListComponent
   */
  onFormSubmit(row: any, event?: Event) {

    if (row.isNew) {

      this.apiService2.post((this.newFormGroup as any).value).subscribe(

        newgroup => {

          row.hasError = false;
          this.updateTable(row, newgroup, 'post');

        },

        err => {

          row.hasError = true;

        }
      );
    } else {

      this.apiService2.put((this.newFormGroup as any).value).subscribe(

        updatedgroup => {

          row.hasError = false;
          this.updateTable(row, updatedgroup, 'put');

        },

        err => {

          row.hasError = true;

        }
      );
    }

  }


  /**
   *  Respond to the change of the form value.
   *
   * @param             [data]
   * @returns
   * @memberof          TeamGroupsListComponent
   */
  onFormValueChanged(data?: any) {

    if (!this.newFormGroup) { return; }

    const form = this.newFormGroup;

    for (const field of Object.keys(this.formErrors)) {
      this.formErrors[field] = ''; //  Clear all of messages
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key of Object.keys(control.errors || [])) {

          this.formErrors[field] += messages[key] + ' ';

        }
      }
    }

  }


  /**
   *  Update the MatTable after success post/put/delete request.
   *
   * @param         row                 The current row in the data table
   * @param         results             The API response after success request
   * @memberof      TeamGroupsListComponent
   */
  updateTable(row: any, results: any, method?: string) {

    const rowIndex = this.dataSource.data.indexOf(row);

    if (method === 'delete') {

      this.dataSource.data.splice(rowIndex, 1);
      if (this.usersPageSetting.totalElements) {
        this.usersPageSetting.totalElements -= 1;
      }

    } else {

      this.dataSource.data[rowIndex] = results;
      if (method === 'post' && this.usersPageSetting.totalElements) { this.usersPageSetting.totalElements += 1; }

    }

    this.tableInEditMode = { isEditMode: false, row: {} };

    //  This is a tricky solution to refresh the material table
    //
    this.dataSource.filter = '';

    delete this.newFormGroup;
  }


}

