<!-- @format -->
<marviq-dashboard
  toggleIt="1"
  menuOpened="true"
  menuDMode="side"
  *ngIf="menuItems"
>
  <mat-toolbar
    class="navbar"
    topNav
  >
    <mat-toolbar-row class="navbar__row">
      <div class="nav-buttons">
        <div *ngFor="let topNavMenuItem of filteredTopNavButtons">
          <button
            class="menu-button"
            title="{{ topNavMenuItem.title }}"
            mat-fab
            [routerLink]="topNavMenuItem.link"
            [routerLinkActive]="['menu-button--active']"
          >
            <mat-icon>{{ topNavMenuItem.icon }}</mat-icon>
          </button>
        </div>
      </div>

      <div class="user">
        <div class="user__practice-container">
          <app-practice-select-dropdown
            [practiceName]="userRights?.currentTeamName"
          ></app-practice-select-dropdown>
        </div>
        <app-nav-user-dropdown [userName]="userPanelSettings.user">
        </app-nav-user-dropdown>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <marviq-sidebar sideNav>
    <div block2>
      <div class="only-mobile mobile-user-panel">
        <iqc-user-panel
          userPanel
          menuVersion="multi"
          prefixMsg="{{ userPanelSettings.prefixMsg }}"
          user="{{ userPanelSettings.user }}"
          filterBy="user"
          filterValue="user"
          links="{{ userPanelSettings.links }}"
        >
        </iqc-user-panel>
      </div>

      <div class="semi-mobile mobile-user-panel">
        <iqc-menu-por-item
          block3
          filterBy="group"
          filterValue="kms-top-menu"
          links="{{ menuItems | json }}"
        >
        </iqc-menu-por-item>
      </div>
    </div>

    <iqc-menu-por-item
      block3
      filterBy="group"
      filterValue="kms"
      links="{{ menuItems | json }}"
    >
    </iqc-menu-por-item>
  </marviq-sidebar>

  <marviq-container mainContent>
    <router-outlet></router-outlet>
  </marviq-container>
</marviq-dashboard>
