/** @format */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError as _throw } from 'rxjs';
import { environment } from '../../environments/environment';
import { Executioner } from '../models/executioner';
import { PagedResult } from '../models/paged-result';
import { PageSetting } from '../models/paged-setting';
import { AbstractRestService } from './iq-package-services/abstract-rest-service.service';

const apiPath = environment.apiPath;

/**
 *  Service for managing the improvement executioners
 *
 * @export
 * @class     ExecutionersService
 * @extends   {AbstractRestService<ImprovementItem>}
 */
@Injectable()
export class ExecutionersService extends AbstractRestService<Executioner> {
  /**
   * Creates an instance of ExecutionersService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      ExecutionersService
   */
  constructor(http: HttpClient) {
    super(
      http,
      `${apiPath}improvement_executioners`,
      'improvement_executioners',
    );
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<Executioner}
   * @memberof        ExecutionersService
   */
  protected mapToModel(json: any): PagedResult<Executioner> {
    let payload = [];

    if (json._embedded) {
      payload = <Executioner[]>json._embedded.executioners;
    }

    return new PagedResult<Executioner>(payload, <PageSetting>json.page);
  }
}
