<!-- @format -->

<section class="auditor-form__container">
  <div class="auditor-form__scores">
    <!-- above this the question is considered as completed positively -->
    <span>{{ 'Norm: ' + questionGroup.norm }}</span>
    <span>{{ 'Score: ' + score }}</span>
    <!-- Todo to be resolved later -->
    <!-- <span>{{ 'Fysiotherapeut: ' + fysiotherapistScore }}</span> -->
  </div>
  <div
    class="auditor-form__controls"
    [formGroup]="auditorForm"
  >
    <mat-form-field appearance="fill">
      <mat-label>Auditor score</mat-label>
      <input
        type="number"
        matInput
        min="0"
        formControlName="value"
      />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Opmerkingen</mat-label>
      <textarea
        matInput
        formControlName="comment"
        matTextareaAutosize
        matAutosizeMinRows="1"
        resizeToFitContent
        rows="5"
      ></textarea>
    </mat-form-field>
  </div>
</section>
