export class Credentials {
    constructor(
      public username: string,
      public password: string,
      public grant_type?: string,
      public scope?: string,
      public client_id?: string
    ) {

    }
}
