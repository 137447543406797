/** @format */

import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { throwError as _throw } from 'rxjs';
import { DefaultPmrItem } from '../models/pmr-item-default';
import { PagedResult } from '../models/paged-result';
import { PageSetting } from '../models/paged-setting';
import { AbstractRestService } from './iq-package-services/abstract-rest-service.service';

const apiPath = environment.apiPath;

/**
 *  Service for managing the default pmr items.
 *
 * @export
 * @class         PmrItemsDefaultService
 * @extends       {AbstractRestService<DefaultPmrItem>}
 */
@Injectable()
export class PmrItemsDefaultService extends AbstractRestService<DefaultPmrItem> {
  /**
   * Creates an instance of PmrItemsDefaultService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      PmrItemsDefaultService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}default_pmr_items`, 'default_pmr_items');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<DefaultPmrItem>}
   * @memberof        PmrItemsDefaultService
   */
  protected mapToModel(json: any): PagedResult<DefaultPmrItem> {
    //  If the DefaultPmrItem array doesn't exist, create an empty DefaultPmrItem object
    let payload = [];

    if (json._embedded) {
      payload = <DefaultPmrItem[]>json._embedded.defaultPMRitems;
    }

    return new PagedResult<DefaultPmrItem>(payload, <PageSetting>json.page);
  }
}
