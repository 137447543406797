/** @format */

import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { throwError as _throw, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { QuestionnaireService } from './questionnaire.service';
import { UserValidatorService } from './user-validator.service';

const apiPath = environment.apiPath;

@Injectable()
export class QuestionnaireFileUploadService {

  #http = inject(HttpClient);
  #questionnaireService = inject(QuestionnaireService);
  #userValidatorService = inject(UserValidatorService);

  #refreshQuestionnaireData(): void {
    const teamId = this.#userValidatorService.currentUserRights.getValue().currentTeamId;
    this.#questionnaireService.fetchQuestionnaireDetails(this.#questionnaireService.questionnaireDetails.getValue().id, teamId).subscribe();
  }

  uploadFiles(submissionId: string, subdimensionId: string, dataToSend: FormData, isLastCall = true): Observable<any> {
    const headers = new HttpHeaders({
      'IQFile': '' // it is probably a workaround to reset default Content-Type: application/json header that is not accepted here
    })

    return this.#http.post(`${apiPath}questionnaire-submission/${submissionId}/attachments?questionnaireSubdimensionId=${subdimensionId}`,
      dataToSend, { headers }
    ).pipe(tap(() => {

      if (isLastCall) {
        this.#refreshQuestionnaireData();
      }
    }), catchError(() => of(null)));
  }

  deleteFile(attachmentId: string): Observable<any> {
    return this.#http.delete(`${apiPath}questionnaire-submission/attachments/${attachmentId}`).pipe(tap(() => this.#refreshQuestionnaireData()), catchError(() => of(null)));
  }

  downloadFile(attachmentId: string): Observable<any> {
    return this.#http.get(`${apiPath}questionnaire-submission/attachments/${attachmentId}`, { responseType: 'blob' }).pipe(catchError(() => of(null)));
  }
}
