/** @format */

import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  Incident,
  INCIDENT_RESPONSE_STATUS,
  INCIDENT_STATUS,
} from '../../models/incident';
import { IncidentsService } from '../../services/incidents.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AddEditIncidentComponent } from '../../dialogs/add-edit-incident/add-edit-incident.component';
import { UserRights } from '../../models/user-rights';
import { distinctUntilChanged, debounceTime, filter } from 'rxjs/operators';

import * as _ from 'lodash';

import * as moment from 'moment';
import { WarningDeleteDialogComponent } from '../../dialogs/warning-delete-dialog/warning-delete-dialog.component';
import { SharedService } from '../../services/shared.service';
import { Theme } from '../../models/theme';
import { Subscription } from 'rxjs';
import { PageSetting } from '../../models/paged-setting';
import { MarviqMatTableInlineCrudComponent } from '../iq-package-components/marviq-mat-table-inline-crud.component';

/**
 *  The main component for managing the scoreItems list.
 *
 * @export
 * @class         IncidentsListComponent
 * @implements    {OnInit}
 * @implements    {OnDestroy}
 */
@Component({
  selector: 'app-incidents-list',
  templateUrl: './incidents-list.component.html',
  styleUrls: ['./incidents-list.component.scss'],
})
export class IncidentsListComponent
  extends MarviqMatTableInlineCrudComponent<Incident>
  implements OnInit, OnDestroy {
  /**
   *  The default values of the new Form.
   *
   * @memberof                IncidentsListComponent
   */
  emptyFormValues = {
    subject: ['', Validators.required],
    description: [''],
    theme: [null],
    subTheme: [null],
    internal: [false],
    improvementIds: [[]],
    newLinkedAttachmentIds: [[]],
    newUnLinkedAttachmentIds: [[]],
    origin: [0],
    reporterName: ['', Validators.required],
    reporterPhone: [''],
    reporterEmail: ['', Validators.email],
    occurredOn: [moment(), Validators.required],
    registeredOn: [moment(), Validators.required],
    plannedOn: [moment(), Validators.required],
    executioner: [null, Validators.required],
    guard: [null],
    conclusion: [''],
    status: [0],
    responseStatus: [0],
    appealCommittee: [false],
    teamId: '',
  };

  /**
   *  The dialog component assigned to the delete request.
   *
   * @memberof                IncidentsListComponent
   */
  deleteDialog = WarningDeleteDialogComponent;

  /**
   *  The list of incident response statuses
   *
   * @memberof                IncidentsListComponent
   */
  incidentResponseStatuses = INCIDENT_RESPONSE_STATUS;

  /**
   *  The list of incident statuses
   *
   * @memberof IncidentsListComponent
   */
  incidentStatuses = INCIDENT_STATUS;

  /**
   *  Compare the prev and the current option from the MatSelect element.
   *
   * @memberof                AddEditIncidentComponent
   */
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareValues;

  /**
   *  The Theme list of incident
   *
   * @type                    {Theme[]}
   * @memberof                AddEditIncidentComponent
   */
  currentThemesList: Theme[];

  /**
   *  The current user rights.
   *
   * @type                  {UserRights}
   * @memberof              IncidentsListComponent
   */
  currentUserRights: UserRights;

  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                IncidentsListComponent
   */
  columnsToDisplay = [
    'subject',
    'registeredOn',
    'type',
    'done',
    'responseStatus',
    'status',
    'plannedOn',
    'ivm',
    'CUD',
  ];

  /**
   * The form group storing the filters
   *
   * @type                      {FormGroup}
   * @memberof                  IncidentsListComponent
   */
  filterForm: UntypedFormGroup;

  /**
   *  The object of form validation errors.
   *
   * @memberof IncidentsListComponent
   */
  filterFormErrors = {
    beginDate: '',
    endDate: '',
    responseStatus: '',
    guardId: '',
    executionerId: '',
    internal: '',
    themeId: '',
    subThemeId: '',
    status: '',
    subject: '',
    notValidDates: '',
  };

  /**
   * The form validation messages
   *
   * @memberof IncidentsListComponent
   */
  filterValidationMessages = {
    beginDate: {
      matDatepickerParse: 'De waarde is niet correct',
    },
    endDate: {
      matDatepickerParse: 'De waarde is niet correct',
    },
    notValidDates: 'Begindatum kan niet later zijn dan de einddatum',
  };

  /**
   *  The initial values of the filters
   *
   * @memberof IncidentsListComponent
   */
  initialFilterValues = {
    beginDate: '',
    endDate: '',
    responseStatus: '',
    guardId: '',
    executionerId: '',
    internal: '',
    themeId: '',
    subThemeId: '',
    status: '',
    subject: '',
  };

  /**
   *  The object of queryParams passed through the route
   *
   * @type {{}}
   * @memberof IncidentsListComponent
   */
  routeQueryParams: {};

  /**
   *  The subscription of the router
   *
   * @type {Subscription}
   * @memberof IncidentsListComponent
   */
  routerSubs: Subscription;

  /**
   * The pager info received from the HTTP request
   *
   * @memberof                IncidentsListComponent
   */
  usersPageSetting: PageSetting = new PageSetting(25, 0, 0, 0);

  /**
   *  The list of themes identified by ids
   *
   * @type {*}
   * @memberof IncidentsListComponent
   */
  themesListWithIds: any;

  /**
   *  User's permissions
   *
   * @memberof IncidentsListComponent
   */
  public crudC = false;
  public crudR = false;
  public crudU = false;
  public crudD = false;

  /**
   *
   * @param formBuilder
   * @param incidentsService
   * @param dg
   * @param activatedRoute
   * @param router
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    private incidentsService: IncidentsService,
    private dg: MatDialog,
    public sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    super(incidentsService, formBuilder, dg);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof IncidentsListComponent
   */
  ngOnInit() {
    this.currentThemesList = this.sharedService.currentThemesList.getValue();
    this.sharedService.currentThemesWithIds.subscribe((themes) => {
      this.themesListWithIds = themes;
    });

    this.sortValues = { createDate: 'desc' };

    this.currentUserRights = JSON.parse(localStorage.getItem('userRights'));

    if (this.currentUserRights.currentTeamId) {
      this.emptyFormValues.teamId = this.currentUserRights.currentTeamId;
      this.queryParams.push({
        name: 'teamId',
        value: this.currentUserRights.currentTeamId,
      });
    }

    this.dialogParameters.data.message = `Weet je zeker dat je deze melding wilt verwijderen?`;

    this.checkPermissions();

    if (this.crudR) {
      this.checkRouteParams();
    }

    //  Subscribe the changes of the router
    this.routerSubs = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (this.filterForm) {
          this.onFiltersClear();
        }
      });
  }

  /**
   *  Validate if the endDate is earlier than startDate
   *
   * @param     {FormGroup}                     group
   * @returns
   * @memberof  IncidentsListComponent
   */
  checkDates(group: UntypedFormGroup) {
    if (
      group.controls.endDate.value &&
      group.controls.endDate.value < group.controls.beginDate.value
    ) {
      return { notValidDates: true };
    }
  }

  /**
   *  Check the current user permissions
   *
   * @memberof IncidentsListComponent
   */
  checkPermissions() {
    const perm = this.currentUserRights.currentMember.teamRole;

    if (perm.permissions) {
      // READ_INCIDENT
      // DELETE_INCIDENT
      // CREATE_INCIDENT
      // UPDATE_INCIDENT
      // READ_ALL_INCIDENT
      // DELETE_ALL_INCIDENT
      // UPDATE_ALL_INCIDENT
      if (
        perm.permissions.indexOf('READ_INCIDENT') > -1 ||
        perm.permissions.indexOf('READ_ALL_INCIDENT') > -1
      ) {
        this.crudR = true;
      }
      if (
        perm.permissions.indexOf('DELETE_INCIDENT') > -1 ||
        perm.permissions.indexOf('DELETE_ALL_INCIDENT') > -1
      ) {
        this.crudD = true;
      }
      if (perm.permissions.indexOf('CREATE_INCIDENT') > -1) {
        this.crudC = true;
      }
      if (
        perm.permissions.indexOf('UPDATE_INCIDENT') > -1 ||
        perm.permissions.indexOf('UPDATE_ALL_INCIDENT') > -1
      ) {
        this.crudU = true;
      }
    }
  }

  /**
   *  Check the params sent through the activatedRoute
   *
   * @memberof IncidentsListComponent
   */
  checkRouteParams() {
    this.activatedRoute.queryParams.subscribe((qparams) => {
      if (qparams && qparams instanceof Object) {
        this.routeQueryParams = qparams;

        for (const filter of Object.keys(qparams)) {
          if (this.initialFilterValues[filter] !== undefined) {
            if (filter === 'guardId' || filter === 'executionerId') {
              this.initialFilterValues[filter] =
                this.currentUserRights.currentMember.id;
            } else {
              this.initialFilterValues[filter] = qparams[filter];
            }
          }
        }
      }

      this.createFilterForm();
      this.getRows();
    });
  }

  /**
   *  Create the form for the filtering
   *
   * @memberof IncidentsListComponent
   */
  createFilterForm() {
    this.filterForm = this.formBuilder.group(this.initialFilterValues, {
      validator: this.checkDates,
    });

    this.filterForm.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((changes) => {
        this.onFilterValueChange(changes);
      });

    if (this.routeQueryParams) {
      this.filterForm.markAsDirty();
      this.onFilterValueChange(this.initialFilterValues);
    }
  }

  /**
   * Respond to the change of the filter values
   *
   * @param {*} changes
   * @memberof IncidentsListComponent
   */
  onFilterValueChange(changes: any) {
    this.queryParams = [
      { name: 'teamId', value: this.currentUserRights.currentTeamId },
    ];
    this.filterValues = {};

    for (const field of Object.keys(this.filterFormErrors)) {
      const control = this.filterForm.get(field);
      this.filterFormErrors[field] = '';

      if (control && !control.valid) {
        const messages = this.filterValidationMessages[field];

        for (const key of Object.keys(control.errors)) {
          this.filterFormErrors[field] += messages[key] + ' ';
        }
      }

      if (this.filterForm.errors && this.filterForm.errors[field]) {
        this.filterFormErrors[field] +=
          this.filterValidationMessages[field] + ' ';
      }

      if (this.filterForm.valid && field !== 'notValidDates') {
        if (
          (field === 'beginDate' || field === 'endDate') &&
          changes[field] &&
          changes[field] instanceof moment
        ) {
          const dateField = changes[field].format('YYYY-MM-DD');
          this.queryParams.push({ name: field, value: dateField });
        } else if (
          (field === 'description' || field === 'subject') &&
          changes[field] !== ''
        ) {
          this.filterValues[field] = changes[field];
        } else if (changes[field] !== '') {
          this.queryParams.push({ name: field, value: changes[field] });
        }
      }
    }
  }

  /**
   *  Respond to the user editing a single item.
   *
   * @param       {*}                         row       The current row in the data tabl
   * @param       {Event}                     [event]   The (`click`) event signalling the user's intent.
   * @memberof    IncidentsListComponent
   */
  onEditIntent(row?: any, event?: Event) {
    let isNew = true;
    let formValues = <any>{};
    let formDisabled = false;

    if (row) {
      isNew = false;
    }
    if (event) {
      event.stopImmediatePropagation();
    }

    if (!row) {
      formValues = this.emptyFormValues;

      if (!this.crudC) {
        formDisabled = true;
      }
    } else {
      const editedRowFormValues = {
        id: [row.id],
        subject: [row.subject, Validators.required],
        description: [row.description],
        theme: [
          row._embedded && row._embedded.theme ? row._embedded.theme : null,
        ],
        subTheme: [
          row._embedded && row._embedded.subTheme
            ? row._embedded.subTheme
            : null,
        ],
        improvementIds: [row.improvementIds ? row.improvementIds : []],
        internal: [row.internal],
        newLinkedAttachmentIds: [[]],
        newUnLinkedAttachmentIds: [[]],
        origin: [row.origin],
        reporterName: [row.reporterName, Validators.required],
        reporterPhone: [row.reporterPhone],
        reporterEmail: [row.reporterEmail, Validators.email],
        occurredOn: [row.occurredOn, Validators.required],
        registeredOn: [row.registeredOn, Validators.required],
        plannedOn: [row.plannedOn, Validators.required],
        executioner: [
          row._embedded && row._embedded.executioner
            ? row._embedded.executioner
            : null,
          Validators.required,
        ],
        guard: [
          row._embedded && row._embedded.guard ? row._embedded.guard : null,
        ],
        conclusion: [row.conclusion],
        status: [row.status],
        responseStatus: [row.responseStatus],
        appealCommittee: [row.appealCommittee],
        teamId: this.currentUserRights.currentTeamId,
      };

      formValues = editedRowFormValues;

      if (!this.crudU) {
        formDisabled = true;
      }
    }

    const dialogRef = this.dg.open(AddEditIncidentComponent, {
      data: {
        formValues: formValues,
        isNew: isNew,
        incident: row,
        formDisabled: formDisabled,
      },
      disableClose: true,
      height: '900px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'primary-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        this.getRows();
      }
    });
  }

  /**
   * Reset filters on clear click
   *
   * @memberof IncidentsListComponent
   */
  onFiltersClear() {
    this.filterForm.reset({
      beginDate: '',
      endDate: '',
      responseStatus: '',
      executionerId: '',
      guardId: '',
      internal: '',
      themeId: '',
      subThemeId: '',
      status: '',
      subject: '',
    });

    this.queryParams = [
      { name: 'teamId', value: this.currentUserRights.currentTeamId },
    ];
    this.filterValues = {};
    this.onFiltersSubmit();
  }

  /**
   *  Submit the filter form
   *
   * @memberof IncidentsListComponent
   */
  onFiltersSubmit() {
    if (this.filterForm.valid) {
      this.usersPageSetting.number = 0;
      this.paginator.firstPage();
      this.getRows();
    }
  }

  /**
   *  Compare two select options form the MatSelect form field.
   *
   * @param         {*}           f1
   * @param         {*}           f2
   * @returns
   * @memberof      IncidentsListComponent
   */
  compareValues(f1: any, f2: any) {
    let returnedValue = false;

    if (f1 !== undefined && f2 !== undefined) {
      if (f1.id && f2.id) {
        if (f1.id === f2.id) {
          returnedValue = true;
        }
      } else if (typeof f2 === 'string' && parseInt(f2, 10) === f1) {
        returnedValue = true;
      }
    }

    return returnedValue;
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof          IncidentsListComponent
   */
  ngOnDestroy() {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
    if (this.routerSubs) {
      this.routerSubs.unsubscribe();
    }
  }
}
