/** @format */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Document } from '../models/document';
import { throwError as _throw } from 'rxjs';
import { PagedResult } from '../models/paged-result';
import { PageSetting } from '../models/paged-setting';
import { AbstractRestService } from './iq-package-services/abstract-rest-service.service';

const apiPath = environment.apiPath;

/**
 *  Service for managing the documents (files).
 *
 * @export
 * @class   DocumentsService
 * @extends {AbstractRestService<Document>}
 */
@Injectable()
export class DocumentsService extends AbstractRestService<Document> {
  /**
   * Creates an instance of DocumentsService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      DocumentsService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}document`, 'document');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<Document>}
   * @memberof        DocumentsService
   */
  protected mapToModel(json: any): PagedResult<Document> {
    //  If the array doesn't exist, create an empty object
    let payload = [];

    if (json._embedded) {
      payload = <Document[]>json._embedded.documents;
    }

    return new PagedResult<Document>(payload, <PageSetting>json.page);
  }
}
