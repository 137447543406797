import { Component, Input } from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'marviq-container',
  template: `
      <!--<p><button mat-button (click)="sidenav.toggle()">Toggle2</button></p>-->
      <p>
        <ng-content></ng-content>
      </p>
   
  `,
  styles: [
      `
      mat-sidenav {
        background: white;
      }
      .example-radio-group {
        display: block;
        border: 1px solid #555;
        margin: 20px;
        padding: 10px;
      }

    `,
  ],
})
export class ContainerComponent {
  mode = new FormControl('over');
}
