/** @format */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Role } from '../models/role';

import { environment } from '../../environments/environment';
import { PagedResult } from '../models/paged-result';
import { PageSetting } from '../models/paged-setting';
import { AbstractRestService } from './iq-package-services/abstract-rest-service.service';

const apiPath = environment.apiPath;

/**
 *  Service for managing the roles.
 *
 * @export
 * @class   RolesService
 * @extends {AbstractRestService<Role>}
 */
@Injectable()
export class RolesService extends AbstractRestService<Role> {
  /**
   * Creates an instance of RolesService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      RolesService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}usermanager/roles`, 'roles');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<Role>}
   * @memberof        RolesService
   */
  protected mapToModel(json: any): PagedResult<Role> {
    //  If the array doesn't exist, create an empty object
    let payload = [];

    if (json._embedded) {
      payload = <Role[]>json._embedded.roles;
    }

    return new PagedResult<Role>(payload, <PageSetting>json.page);
  }
}
