/** @format */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Incident } from '../models/incident';
import { Observable, throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PagedResult } from '../models/paged-result';
import { PageSetting } from '../models/paged-setting';
import { BaseModel } from '../models/base-model';
import { AbstractRestService } from './iq-package-services/abstract-rest-service.service';

const apiPath = environment.apiPath;

/**
 *  Service for managing the incidents.
 *
 * @export
 * @class   IncidentsService
 * @extends {AbstractRestService<Incident>}
 */
@Injectable()
export class IncidentsService extends AbstractRestService<Incident> {
  /**
   * Creates an instance of IncidentsService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      IncidentsService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}incidents`, 'incidents');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<Incident>}
   * @memberof        IncidentsService
   */
  protected mapToModel(json: any): PagedResult<Incident> {
    //  If the array doesn't exist, create an empty object
    let payload = [];

    if (json._embedded) {
      payload = <Incident[]>json._embedded.incidents;
    }

    return new PagedResult<Incident>(payload, <PageSetting>json.page);
  }

  /**
   *  get single improvement with the teamId param
   *
   * @param       {string}      aModelId  The id of the improvement
   * @param       {string}      teamId    The id of the team
   * @returns
   * @memberof    IncidentsService
   */
  getSingleItem(aModelId: string, teamId: string): Observable<Incident> {
    return this.http
      .get<Incident>(`${this.actionUrl}/${aModelId}?teamId=${teamId}`)
      .pipe(catchError((err) => _throw(err)));
  }

  /**
   *  Post incident with the teamId param
   *
   * @param       {BaseModel}   aModel  The posted model
   * @param       {string}      teamId  The id of the team
   * @returns
   * @memberof    IncidentsService
   */
  postIncident(aModel: BaseModel, teamId: string): Observable<Incident> {
    return this.http
      .post<Incident>(`${this.actionUrl}?teamId=${teamId}`, aModel)
      .pipe(catchError((err) => _throw(err)));
  }

  /**
   *  Put incident with the teamId param
   *
   * @param       {BaseModel}   aModel  The posted model
   * @param       {string}      teamId  The id of the team
   * @returns
   * @memberof    IncidentsService
   */
  putIncident(aModel: BaseModel, teamId: string): Observable<Incident> {
    return this.http
      .put<Incident>(`${this.actionUrl}/${aModel.id}?teamId=${teamId}`, aModel)
      .pipe(catchError((err) => _throw(err)));
  }

  /**
   * A generic DELETE method.
   * @param BaseModel aModel
   * @returns Observable<T>
   */
  delete(aModel: any): Observable<Incident> {
    const deleteURL = `${this.actionUrl}/${aModel.id}?teamId=${aModel._embedded.team.id}`;

    return this.http
      .delete<Incident>(deleteURL)
      .pipe(catchError((err) => _throw(err)));
  }
}
