/** @format */

import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { Theme } from '../../models/theme';
import {
  IMPROVEMENT_STATUS,
  ImprovementGroup,
} from '../../models/improvement-group';
import { ImprovementGroupService } from '../../services/improvement-group.service';
import { UserRights } from '../../models/user-rights';
import { UserValidatorService } from '../../services/user-validator.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ImprGroupDetailsComponent } from '../../dialogs/impr-group-details/impr-group-details.component';
import { MediaMatcher } from '@angular/cdk/layout';
import { LampService } from '../../services/lamp.service';
import { SubTheme } from '../../models/subtheme';
import { QueryParam } from '../../models/query-param';

/**
 *  The main component for managing the improvement groups divided by the theme
 *
 * @export
 * @class         ImprovementPlanTabComponent
 * @implements    {OnInit}
 * @implements    {OnDestroy}
 */
@Component({
  selector: 'app-improvement-plan-tab',
  templateUrl: './improvement-plan-tab.component.html',
  styleUrls: ['./improvement-plan-tab.component.scss'],
})
export class ImprovementPlanTabComponent implements OnInit, OnDestroy {
  /**
   *  The theme value sent by parent component.
   *
   * @type            {Theme}
   * @memberof        ImprovementPlanTabComponent
   */
  @Input() theme: Theme;

  /**
   *  The list of improvement statuses that API accepts.
   *
   * @memberof ImprovementPlanTabComponent
   */
  statuses = IMPROVEMENT_STATUS;

  /**
   *  Rights of the current user
   *
   * @type                    {UserRights}
   * @memberof                ImprovementPlanTabComponent
   */
  currentUserRights: UserRights;

  /**
   *  The list of improvementGroups within the theme.
   *
   * @type                    {ImprovementGroup[]}
   * @memberof                ImprovementPlanTabComponent
   */
  improvementGroupsList: ImprovementGroup[];

  /**
   *  The subscription of improvement groups.
   *
   * @type                    {Subscription}
   * @memberof                ImprovementPlanTabComponent
   */
  improvementGroupsSubs: Subscription;

  /**
   *  The subThemes list.
   *
   * @type                    {SubTheme}
   * @memberof                ImprovementPlanTabComponent
   */
  subThemesList: SubTheme[];

  /**
   *  The subscription of the subThemes list.
   *
   * @type                    {Subscription}
   * @memberof                ImprovementPlanTabComponent
   */
  subThemesListSubs: Subscription;

  /**
   *  The list of queryParams sent in the request.
   *
   * @type                    {QueryParam[]}
   * @memberof                ImprovementPlanTabComponent
   */
  queryParams: QueryParam[];

  /**
   *  mobile Query
   *
   * @memberof    ScoreItemsListComponent
   */
  mobileQuery: MediaQueryList;

  /**
   *  The listener for mobileQuery changes
   *
   * @private
   * @memberof ImprovementPlanTabComponent
   */
  private _mobileQueryListener: (change: any) => void;

  /**
   *Creates an instance of ImprovementPlanTabComponent.

  * @param    {ImprovementGroupService} improvementGroupService
  * @param    {LampService}             lampService
  * @param    {UserValidatorService}    userValidatorService
  * @param    {SharedService}           sharedService
  * @param    {ChangeDetectorRef}       ref
  * @param    {MediaMatcher}            media
  * @param    {MatDialog}               dialog
  * @memberof ImprovementPlanTabComponent
  */
  constructor(
    private improvementGroupService: ImprovementGroupService,
    private lampService: LampService,
    private userValidatorService: UserValidatorService,
    private ref: ChangeDetectorRef,
    private media: MediaMatcher,
    private dialog: MatDialog,
  ) { }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof                ImprovementPlanTabComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.queryParams = [
      { name: 'teamId', value: this.currentUserRights.currentTeamId },
      { name: 'themeId', value: this.theme.id },
    ];

    this.subThemesListSubs = this.lampService
      .getSubThemes(this.theme.id)
      .subscribe((subthemes) => {
        this.subThemesList = subthemes;
      });

    this.getImprovementGroups();

    //  Change the expansion panel when the mediaQuery matches
    this.mobileQuery = this.media.matchMedia('( max-width: 1125px )');
    this._mobileQueryListener = (change: any) => this.ref.detectChanges();

    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  /**
   *  Get improvement groups subscription.
   *
   * @memberof              ImprovementPlanTabComponent
   */
  getImprovementGroups() {
    this.improvementGroupsSubs = this.improvementGroupService
      .getAll(undefined, undefined, undefined, this.queryParams)
      .subscribe((improvementGroups) => {
        this.improvementGroupsList = improvementGroups.payload;
      });
  }

  /**
   *  Respond to the user add group intent.
   *
   * @memberof              ImprovementPlanTabComponent
   */
  onAddGroup() {
    const dialogRef = this.dialog.open(ImprGroupDetailsComponent, {
      data: { theme: this.theme, subThemesList: this.subThemesList },
      disableClose: true,
      height: '900px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'impr-group-details-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        this.getImprovementGroups();
      }
    });
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof          ImprovementPlanTabComponent
   */
  ngOnDestroy() {
    this.ref.detach();
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.improvementGroupsSubs.unsubscribe();
    this.subThemesListSubs.unsubscribe();
  }
}
