/** @format */

import { Component, OnInit } from '@angular/core';
import { malLoginFormComponent } from '../../iq-auth-components';

/**
 *  Login form component
 *
 * @export
 * @class       KmsLoginFormComponent
 * @extends     {malLoginFormComponent}
 * @implements  {OnInit}
 */
@Component({
  selector: 'kms-auth-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class KmsLoginFormComponent
  extends malLoginFormComponent
  implements OnInit { }
