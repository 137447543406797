import { UserRoles } from "../functions/allowed-user-roles";
import { Role } from "./role";

export enum QUESTIONNAIRE_STATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
}

export enum QUESTIONNAIRE_RESULT_STATUS {
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  WAITING_FOR_AUDIT = 'WAITING_FOR_AUDIT'
}

export enum DIMENSION_TYPE {
  TEAM_ADMIN_ONLY = 'TEAM_ADMIN_ONLY',
  BENCHMARKED = 'BENCHMARKED'
}

// can be left like this to match the model. We need only email for contract
export const auditorTeamRole = {
  description: "IPF Auditor",
  id: null,
  name: UserRoles.IPF_AUDITOR
}

export interface QuestionnaireListItem {
  id: string;
  title: string;
  description: string;
  publishedOn: Date;
  closedOn: Date;
  status: QUESTIONNAIRE_STATUS;
  results: Result[];
  year?: number;
}

export interface QuestionnaireDetails {
  id: string;
  title: string;
  description: string;
  year: number;
  publishedOn: Date;
  status: QUESTIONNAIRE_STATUS;
  dimensions: Dimension[];
  results: DetailsResult[];
}

export interface Question {
  id: string;
  title: string;
  description: string;
  norm: number;
  teamAdminOnly?: boolean;
  ordinal: number;
}

export interface SelectedAnswer {
  questionId: string
  answerId: string | null,
}

interface Result {
  id: string;
  startedOn: Date;
  completedOn: Date;
  status: QUESTIONNAIRE_RESULT_STATUS;
  team: Team;
  auditedOn: string;
  auditor: User;
  submissionsCount: number;
  submission?: {
    id: string,
    selectedAnswers: SelectedAnswer[];
  }
}

interface Auditor {
  id: string;
  memberName: string;
  archived: boolean;
  user: User;
  team: Team;
}

interface Team {
  id: string;
  name: string;
  group: string;
  sequence: number;
  teamGroupId: string;
}

interface User {
  id: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  active: boolean;
  organisationAdmin: boolean;
}

interface Dimension {
  id: string;
  title: string;
  description: string;
  ordinal: number;
  type: DIMENSION_TYPE;
  subdimensions: Subdimension[];
}

interface Subdimension {
  id: string;
  title: string;
  description: string; // check if this is still present in the model
  ordinal: number;
  questionGroups: QuestionGroup[];
  provable: boolean; // should there be attachments
  proofsRequirements: string[]; // list of required attachments
}

export interface PossibleAnswer {
  id: string;
  shortExplanation: string;
  value: number;
}


export interface QuestionGroup {
  id: string;
  ordinal: number;
  norm: number;
  subgroups: QuestionGroupSubGroup[];
  possibleAnswers: PossibleAnswer[];
}

interface QuestionGroupSubGroup {
  id: string;
  title: string;  // check if this is still present in the model
  ordinal: number;
  questions: Question[];
}

export interface DetailsResult {
  id: string;
  startedOn: Date;
  completedOn: Date;
  status: QUESTIONNAIRE_RESULT_STATUS;
  team: Team;
  auditedOn: string;
  auditor: Auditor; 
  audit: Audit;
  submissionsCount: number;
  averageDimensionsScores: Audit[];
  submission?: {
    id: string;
    proofs: Proof[];
    resultId: string;
    selectedAnswers: SelectedAnswer[];
    submittedBy: Auditor & { teamAdmin: boolean; teamRole: Role };
  }
}

interface Proof {
  attachments: Attachment[];
  explanation: string;
  subdimensionId: string;
}

export interface Attachment {
  id: string;
  fileName: string;
}

interface Audit {
  auditedBy: User;
  completed: boolean;
  id: string;
  resultId: string;
  selectedAnswers: AuditSelectedAnswer[];
}

export interface AuditSelectedAnswer {
  comment: string;
  questionGroupId: string;
  value: 0;
}
