/** @format */

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TasksTemplate } from '../../models/tasks-template';
import { TasksTemplateService } from '../../services/tasks-template.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UserRights } from '../../models/user-rights';
import { WarningDeleteDialogComponent } from '../../dialogs/warning-delete-dialog/warning-delete-dialog.component';
import { Subscription } from 'rxjs';
import { TeamGroupsListService } from '../../services/team-groups-list.service';
import { SharedService } from '../../services/shared.service';
import { UserValidatorService } from '../../services/user-validator.service';
import { AddEditTasksTemplateComponent } from '../../dialogs/add-edit-tasks-template/add-edit-tasks-template.component';
import { Member } from '../../models/member';
import { MediaMatcher } from '@angular/cdk/layout';

import * as moment from 'moment';
import { PageSetting } from '../../models/paged-setting';
import { MarviqMatTableInlineCrudComponent } from '../iq-package-components/marviq-mat-table-inline-crud.component';

/**
 *  Display an overview of tasks templates
 *
 * @export
 * @class       TasksTemplatesListComponent
 * @extends     {MarviqMatTableInlineCrudComponent<TasksTemplate>}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-tasks-templates-list',
  templateUrl: './tasks-templates-list.component.html',
  styleUrls: ['./tasks-templates-list.component.scss'],
})
export class TasksTemplatesListComponent
  extends MarviqMatTableInlineCrudComponent<TasksTemplate>
  implements OnInit, OnDestroy {
  /**
   *  Rights of the current user
   *
   * @type                    {UserRights}
   * @memberof                TasksTemplatesListComponent
   */
  currentUserRights: UserRights;

  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                TasksTemplatesListComponent
   */
  columnsToDisplay = [
    'subThemeName',
    'title',
    'description',
    'frequency',
    'responisbleMember',
    'critical-private',
    'CUD',
  ];

  /**
   *  The current member.
   *
   * @type                    {Member}
   * @memberof                TasksTemplatesListComponent
   */
  currentMember: Member;

  /**
   *  The dialog component assigned to the delete request.
   *
   * @memberof                TasksTemplatesListComponent
   */
  deleteDialog = WarningDeleteDialogComponent;

  /**
   *  The default values of the new Form.
   *
   * @memberof                TeamGroupsListComponent
   */
  emptyFormValues = <any>{
    critical: [false],
    description: [''],
    title: ['', Validators.required],
    endDate: [null],
    frequency: [{}, Validators.required],
    privateTaskTemplate: false,
    startDate: [moment().format('YYYY-MM-DD'), Validators.required],
    teams: [[{}], Validators.required],
    subTheme: [null],
  };

  /**
   *  The subscription of teamGroups
   *
   * @type                    {Subscription}
   * @memberof                TasksTemplatesListComponent
   */
  teamGroupsSubscription: Subscription;

  /**
   *  mobile Query
   *
   * @memberof    BestPracticesListComponent
   */
  mobileQuery: MediaQueryList;

  /**
   *  The listener for mobileQuery changes
   *
   * @private
   * @memberof BestPracticesListComponent
   */
  private _mobileQueryListener: (change: any) => void;

  /**
   * The pager info received from the HTTP request
   *
   * @memberof                BestPracticesListComponent
   */
  usersPageSetting: PageSetting = new PageSetting(25, 0, 0, 0);

  /**
   * Creates an instance of TasksTemplatesListComponent.
   *
   * @param       {TasksTemplateService}          tasksTemplateService
   * @param       {TeamGroupsListService}         teamGroupsListService
   * @param       {UserValidatorService}          userValidatorService
   * @param       {SharedService}                 sharedService
   * @param       {FormBuilder}                   fbuild
   * @param       {MatDialog}                     editDialog
   * @param       {MatDialog}                     dg
   * @memberof TasksTemplatesListComponent
   */
  constructor(
    private tasksTemplateService: TasksTemplateService,
    private teamGroupsListService: TeamGroupsListService,
    private userValidatorService: UserValidatorService,
    private sharedService: SharedService,
    private fbuild: UntypedFormBuilder,
    private editDialog: MatDialog,
    private dg: MatDialog,
    private media: MediaMatcher,
    private ref: ChangeDetectorRef,
  ) {
    super(tasksTemplateService, fbuild, dg);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof TasksTemplatesListComponent
   */
  ngOnInit() {
    this.dialogParameters.data.message = `Weet je zeker dat je deze taken-template wilt verwijderen?`;
    this.sortValues = { privateTaskTemplate: 'desc', createDate: 'desc' };

    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();
    this.emptyFormValues.teams = [
      [this.currentUserRights.currentMember.team],
      Validators.required,
    ];

    this.queryParams = [
      { name: 'teamId', value: this.currentUserRights.currentMember.team.id },
    ];

    this.getRows();

    //  Change the table content on mobile
    this.mobileQuery = this.media.matchMedia('( max-width: 768px )');
    this._mobileQueryListener = (change: any) => this.ref.detectChanges();

    this.mobileQuery.addListener(this._mobileQueryListener);

    if (!this.currentUserRights.organisationAdmin) {
      this.currentMember = this.currentUserRights.currentMember;
      this.emptyFormValues.responsibleMember =
        this.currentUserRights.currentMember;
    } else {
      this.teamGroupsSubscription = this.teamGroupsListService
        .getAll(undefined, undefined, { name: 'asc' }, this.queryParams)
        .subscribe((currentTeamGroupsList) => {
          if (
            this.sharedService.currentTeamGroupsList.getValue() !==
            currentTeamGroupsList.payload
          ) {
            this.sharedService.newSelectedTeamGroupsList(
              currentTeamGroupsList.payload,
            );
          }
        });
    }

    this.sharedService.frequenciesList$.subscribe((frequencies) => {
      this.emptyFormValues.frequency = [frequencies[0], Validators.required];
    });
  }

  /**
   *  Respond to the user editing a single item.
   *
   * @param       {*}                         row       The current row in the data tabl
   * @param       {Event}                     [event]   The (`click`) event signalling the user's intent.
   * @memberof    TasksTemplatesListComponent
   */
  onEditIntent(row?: any, event?: Event, type?: string) {
    let isNew = true;
    let formValues = <any>{};

    if (row) {
      isNew = false;
    }
    if (event) {
      event.stopImmediatePropagation();
    }

    if (!row) {
      formValues = this.emptyFormValues;

      if (type && type === 'private') {
        formValues.privateTaskTemplate = true;
      } else {
        formValues.privateTaskTemplate = false;
      }
    } else {
      const editedRowFormValues = {
        id: [row.id],
        critical: [row.critical],
        title: [row.title, Validators.required],
        description: [row.description],
        endDate: [
          row.endDate ? moment(row.endDate).format('YYYY-MM-DD') : null,
        ],
        frequency: [row._embedded.taskTemplateFrequency, Validators.required],
        privateTaskTemplate: row.privateTaskTemplate,
        responsibleMember: [
          row.responsibleMember ? row.responsibleMember : this.currentMember,
        ],
        startDate: [
          row.startDate ? moment(row.startDate).format('YYYY-MM-DD') : null,
          Validators.required,
        ],
        teams: [[row._embedded.team], Validators.required],
        subTheme: [row._embedded.subTheme],
      };

      formValues = editedRowFormValues;
    }

    const dialogRef = this.editDialog.open(AddEditTasksTemplateComponent, {
      data: { formValues: formValues, isNew: isNew },
      disableClose: true,
      height: '900px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: ['primary-dialog', 'tasks-templates-dialog'],
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        this.getRows();
      }
    });
  }

  /**
   *  Respond to the user deleting a single item.
   *
   * @param         event The (`click`) event signalling the user's intent.
   * @param         row   The current row in the data table
   * @memberof      TasksTemplatesListComponent
   */
  onDeleteIntent(row: any, event?: Event, dialog?: any) {
    this.dialogParameters.data.name = row.name;

    if (event) {
      event.stopImmediatePropagation();
    }

    const dialogRef = this.dg.open(dialog, this.dialogParameters);

    dialogRef.afterClosed().subscribe((results) => {
      if (!results) {
        return;
      }

      this.tasksTemplateService
        .deleteTasksTemplate(row, this.currentUserRights.currentTeamId)
        .subscribe(
          (resp) => {
            row.hasError = false;
            this.updateTable(row, resp, 'delete');
          },
          (err) => {
            row.hasError = true;
          },
        );
    });
  }

  /**
   *  Check if the user is eglible to edit given row.
   *
   * @param {*} row
   * @returns
   * @memberof TasksTemplatesListComponent
   */
  checkRowEditingRigths(row: any) {
    if (
      row.privateTaskTemplate ||
      this.currentUserRights.organisationAdmin ||
      this.currentUserRights.isCurrentTeamAdmin
    ) {
      return true;
    } else if (
      row._embedded.subTheme &&
      this.currentUserRights.subThemeOwnership &&
      this.currentUserRights.subThemeOwnership.length > 0
    ) {
      let editable = false;

      for (const subtheme of this.currentUserRights.subThemeOwnership) {
        if (
          row._embedded.subTheme &&
          subtheme.id === row._embedded.subTheme.id
        ) {
          editable = true;

          break;
        }
      }

      return editable;
    } else if (!row._embedded.subTheme) {
      return true;
    } else {
      return false;
    }
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof TasksTemplatesListComponent
   */
  ngOnDestroy() {
    this.serviceSubscription.unsubscribe();
    this.ref.detach();
    this.mobileQuery.removeListener(this._mobileQueryListener);

    if (this.teamGroupsSubscription) {
      this.teamGroupsSubscription.unsubscribe();
    }
  }
}
