import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { NavItemComponent } from "./nav-item";
import { LayoutService } from '../../services/iq-package-services/layout.service';

interface DATA {
  group: any,
  name: any,
  level: any,
  safeLevel: any,
  icon: any,
}
export interface VALhere {
  data?: {
    group: any,
    name: any,
    level: any,
    safeLevel: any,
    icon: any,
  },
  children?: any,
  path?: any,
  group?: any,
  name?: any,
  level?: any,
  safeLevel?: any,
  icon?: any,
}

@Component({
  selector: 'iqc-menu-hor-item',
  template: `
    <ng-container *ngFor="let link of menuList">
      <mat-list-item [routerLink]="link.path" routerLinkActive="active-link">
        <mat-icon mat-list-icon *ngIf="link.icon">{{ link.icon }}</mat-icon>
        <span mat-line><ng-content></ng-content></span>
        <span mat-line class="secondary">{{ link.name }}</span>
      </mat-list-item>
    </ng-container>
  `,
  styles: [
    `
      .secondary {
        color: rgba(0, 0, 0, 0.54);
      }
      .mat-list-item {
        float: left;
        width: auto;
      }
    `
  ],
})
export class ToolItemComponent extends NavItemComponent implements OnInit {
  @Input() links = '';
  @Input() filterBy = '';
  @Input() filterValue = '';
  @Input() icon = '';
  @Input() hint = '';
  @Input() routerLink: string | any[] = '/';
  @Output() navigate = new EventEmitter();

  public jsonMenuList = [];
  public menuList = [];
  val: VALhere;

  constructor(private layoutService2: LayoutService) {
    super(layoutService2);
  }


}
