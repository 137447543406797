import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import * as Auth from '../../services/iq-auth-services/auth.service';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent implements OnInit {

  /**
   *  The set password token
   *
   * @memberof  ForgotPasswordPageComponent
   */
  token: Observable<string>;


  /**
   *  Subscription of the auth service.
   *
   * @memberof ForgotPasswordPageComponent
   */
  authSubs: Subscription;

  /**
   *  True/false if the reset password request has been sent
   *
   * @memberof ForgotPasswordPageComponent
   */
  requestSent = false;


  /**
   * Creates an instance of ForgotPasswordPageComponent.
   *
   * @param router
   * @param auth
   * @param rout
   * @memberof  ForgotPasswordPageComponent
   */
  constructor(private router: Router, private auth: Auth.AuthService, private rout: ActivatedRoute) {

  }


  ngOnInit() {
    this.token = this.rout.snapshot.queryParams['token'];
  }


  onSubmit(forgotData: any) {

    this.authSubs = this.auth.requestPassword(forgotData.emailAddress, forgotData.username)
      .subscribe(
        (data) => {

          this.requestSent = true;
        },
        err => console.log(err),
        () => console.log('[Auth-Set] Forgot request sent')
      );
  }

}
