export { LoginPageComponent as malLoginPageComponent } from './login-page.component';
export { ResetPasswordPageComponent as malResetPasswordPageComponent } from './reset-password-page.component';
export { SetPasswordPageComponent as malSetPasswordPageComponent } from './set-password-page.component';
export { SignUpPageComponent as malSignUpPageComponent } from './sign-up-page.component';
export { ForgotPasswordPageComponent as malForgotPasswordPageComponent } from './forgot-password-page.component';
export { LoginFormComponent as malLoginFormComponent } from './login-form.component';
export { ResetPasswordFormComponent as malResetPasswordFormComponent } from './reset-password-form.component';
export { SetPasswordFormComponent as malSetPasswordFormComponent } from './set-password-form.component';
export { SignUpFormComponent as malSignUpFormComponent } from './sign-up-form.component';
export { ForgotPasswordFormComponent as malForgotPasswordFormComponent } from './forgot-password-form.component';
