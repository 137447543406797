export enum UserRoles {
  ORG_ADMIN = 'ORG_ADMIN', // co-admin
  TEAM_GROUP_ADMIN = 'TEAM_GROUP_ADMIN', // co-coach === ORG_COACH (old role type, out of date now)
  MT_MEMBER = 'MT_MEMBER',
  ZORGVERLENER = 'ZORGVERLENER', // caretaker,
  QUALITY_MANAGER = 'QUALITY_MANAGER',
  TEAM_ADMIN = 'TEAM_ADMIN',  // practice owner (praktijkeihnaar)
  OFFICE_MANAGER = 'OFFICE_MANAGER',
  EMPLOYEE = 'EMPLOYEE',
  IPF_AUDITOR = 'IPF_AUDITOR',
  IPF_TEAM_ADMIN = 'IPF_TEAM_ADMIN',
  IPF_EMPLOYEE = 'IPF_EMPLOYEE'
}

export const allowedUserRoles = (allowedRoles: UserRoles[]): boolean => {
  const userRights = JSON.parse(localStorage.getItem('userRights'));
  return allowedRoles.includes(userRights.currentMember.teamRole.name);
}
