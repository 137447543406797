/** @format */

import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from '@angular/core';
import { PMR } from '../../models/pmr';
import { PmrService } from '../../services/pmr.service';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UserRights } from '../../models/user-rights';
import { UserValidatorService } from '../../services/user-validator.service';
import { Subscription } from 'rxjs';
import { WarningDeleteDialogComponent } from '../../dialogs/warning-delete-dialog/warning-delete-dialog.component';
import * as moment from 'moment';
import { AddEditPmrComponent } from '../../dialogs/add-edit-pmr/add-edit-pmr.component';
import { SelectionModel } from '@angular/cdk/collections';
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { PMRItem } from '../../models/pmr-item';
import { PagedResult } from '../../models/paged-result';
import { PageSetting } from '../../models/paged-setting';
import { MarviqMatTableInlineCrudComponent } from '../iq-package-components/marviq-mat-table-inline-crud.component';

/**
 *  Show the list of the PMRs
 *
 * @export
 * @class PmrListComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-pmr-list',
  templateUrl: './pmr-list.component.html',
  styleUrls: ['./pmr-list.component.scss'],
})
export class PmrListComponent
  extends MarviqMatTableInlineCrudComponent<PMR>
  implements OnInit, OnDestroy, OnChanges {
  /**
   *  The updated pmrItem passed from the parent component
   *
   * @type {PMRItem}
   * @memberof PmrListComponent
   */
  @Input() pmrItemChanged: PMRItem;

  /**
   *  The selectedPmr sent to the parent component
   *
   * @memberof PmrListComponent
   */
  @Output() selectedPmr = new EventEmitter<SelectionModel<PMR>>();

  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                PmrListComponent
   */
  columnsToDisplay = [
    'title',
    'period',
    'note',
    'coEnabled',
    'archived',
    'CUD',
  ];

  /**
   * Apply user rights
   *
   * @memberof PmrListComponent
   */
  public crudC = false;
  public crudR = false;
  public crudU = false;
  public crudD = false;

  /**
   *  The active PMR
   *
   * @memberof          PmrListComponent
   */
  currentPMR = <PMR>{};

  /**
   *  The subscription of current PMR fetch
   *
   * @type              {Subscription}
   * @memberof          PmrListComponent
   */
  currentPMRSubs: Subscription;

  /**
   *  The rights of the current user
   *
   * @type              {UserRights}
   * @memberof          PmrListComponent
   */
  currentUserRights: UserRights;

  /**
   *  The dialog component assigned to the delete request.
   *
   * @memberof                PmrListComponent
   */
  deleteDialog = WarningDeleteDialogComponent;

  /**
   *  The default values of the new Form.
   *
   * @memberof               PmrListComponent
   */
  emptyFormValues = {
    beginTime: [moment().format('YYYY-MM-DD')],
    coEnabled: [false],
    endTime: [moment().add(1, 'd').format('YYYY-MM-DD')],
    note: [''],
    title: ['', Validators.required],
    teamId: '',
  };

  /**
   *  The filters form
   *
   * @type                    {FormGroup}
   * @memberof                PmrListComponent
   */
  filterForm: UntypedFormGroup;

  /**
   *  The object of form validation errors.
   *
   * @memberof                PmrListComponent
   */
  filterFormErrors = {
    title: '',
    beginDate: '',
    notValidDates: '',
    endDate: '',
  };

  /**
   * The form validation messages
   *
   * @memberof                PmrListComponent
   */
  filterValidationMessages = {
    beginDate: {
      matDatepickerParse: 'De waarde is niet correct',
    },
    endDate: {
      matDatepickerParse: 'De waarde is niet correct',
    },
    notValidDates: 'Begindatum kan niet later zijn dan de einddatum',
  };

  /**
   * The pager info received from the HTTP request
   *
   * @memberof                PmrListComponent
   */
  usersPageSetting: PageSetting = new PageSetting(25, 0, 0, 0);

  /**
   * Creates an instance of PmrListComponent.
   *
   * @param    {PmrService}  pmrService
   * @param    {FormBuilder} formBuilder
   * @param    {MatDialog}   dg
   * @memberof PmrListComponent
   */
  constructor(
    private pmrService: PmrService,
    private formBuilder: UntypedFormBuilder,
    private dg: MatDialog,
    private userValidatorService: UserValidatorService,
  ) {
    super(pmrService, formBuilder, dg);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof        PmrListComponent
   */
  ngOnInit() {
    this.sortValues = { archived: 'asc', createDate: 'desc' };
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.selection = new SelectionModel<PMR>(false, this.initialSelection);
    this.selectedPmr.emit(this.selection);

    if (this.currentUserRights.currentTeamId) {
      this.queryParams.push({
        name: 'teamId',
        value: this.currentUserRights.currentTeamId,
      });

      this.emptyFormValues.teamId = this.currentUserRights.currentTeamId;
    }

    this.checkPermissions();

    if (this.crudR) {
      this.addFiltersForm();
      this.getRows();
    }
  }

  /**
   * Respond when Angular (re)sets data-bound input properties.
   * Called before ngOnInit() and whenever one or more data-bound input properties change.
   *
   * @memberof              PmrListComponent
   */
  ngOnChanges() {
    //  After pmr item change, check if there is still active PMR
    //  If not, refetch the list and show the create new PMR button
    if (this.pmrItemChanged) {
      this.pmrService
        .getCurrentPmr(this.currentUserRights.currentTeamId)
        .subscribe((resp) => {
          if (!resp || resp.archived) {
            this.getRows();
          }
        });
    }
  }

  /**
   *  Add and subscribe the form group for list filters
   *
   * @memberof              PmrListComponent
   */
  addFiltersForm() {
    this.filterForm = this.formBuilder.group(
      {
        title: '',
        beginDate: '',
        endDate: '',
      },
      { validator: this.checkDates },
    );

    this.filterForm.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((changes) => {
        for (const field of Object.keys(this.filterFormErrors)) {
          const control = this.filterForm.get(field);
          this.filterFormErrors[field] = '';

          if (control && !control.valid) {
            const messages = this.filterValidationMessages[field];

            for (const key of Object.keys(control.errors)) {
              this.filterFormErrors[field] += messages[key] + ' ';
            }
          }

          if (this.filterForm.errors && this.filterForm.errors[field]) {
            this.filterFormErrors[field] +=
              this.filterValidationMessages[field] + ' ';
          }

          if (this.filterForm.valid && field !== 'notValidDates') {
            if (field === 'beginDate' && changes[field] instanceof moment) {
              const dateField = changes[field].format('YYYY-MM-DD');
              this.filterValues[field] = dateField;
            } else if (
              field === 'endDate' &&
              changes[field] instanceof moment
            ) {
              const endDateField = changes[field].format('YYYY-MM-DD');
              this.filterValues[field] = endDateField;
            } else {
              this.filterValues[field] = changes[field];
            }
          }
        }

        if (this.filterForm.valid) {
          this.usersPageSetting.number = 0;
          this.paginator.firstPage();
          this.getRows();
        }
      });
  }

  /**
   *  Validate if the endDate is earlier than startDate
   *
   * @param     {FormGroup}                     group
   * @returns
   * @memberof  PmrListComponent
   */
  checkDates(group: UntypedFormGroup) {
    if (
      group.controls.endDate.value &&
      group.controls.endDate.value < group.controls.beginDate.value
    ) {
      return { notValidDates: true };
    }
  }

  /**
   *  Check the current user permissions
   *
   * @memberof PmrListComponent
   */
  checkPermissions() {
    const perm = this.currentUserRights.currentMember.teamRole;

    if (perm.permissions) {
      // READ_PMR
      // DELETE_PMR
      // CREATE_PMR
      // UPDATE_PMR
      // READ_ALL_PMR
      // DELETE_ALL_PMR
      // UPDATE_ALL_PMR
      if (
        perm.permissions.indexOf('READ_PMR') > -1 ||
        perm.permissions.indexOf('READ_ALL_PMR') > -1
      ) {
        this.crudR = true;
      }
      if (
        perm.permissions.indexOf('DELETE_PMR') > -1 ||
        perm.permissions.indexOf('DELETE_ALL_PMR') > -1
      ) {
        this.crudD = true;
      }
      if (perm.permissions.indexOf('CREATE_PMR') > -1) {
        this.crudC = true;
      }
      if (
        perm.permissions.indexOf('UPDATE_PMR') > -1 ||
        perm.permissions.indexOf('UPDATE_ALL_PMR') > -1
      ) {
        this.crudU = true;
      }
    }
  }

  /**
   *  Get active PMR
   *
   * @memberof          PmrListComponent
   */
  getCurrentPMR() {
    this.currentPMRSubs = this.pmrService
      .getCurrentPmr(this.currentUserRights.currentTeamId)
      .subscribe((resp) => {
        if (resp && !resp.archived) {
          this.currentPMR = resp;
        } else {
          this.currentPMR = undefined;
        }
      });
  }

  /**
   *  Overrides the getRows() method from MarviqMatTable component
   *  Get all rows for the table view
   *
   * @memberof      PmrListComponent
   */
  getRows() {
    //  Clear selection on the TeamGroup table
    if (this.selection && this.selection.hasValue()) {
      this.selection.clear();
      this.selectedPmr.emit(this.selection);
    }

    //  Get all rows in the list
    this.serviceSubscription = this.pmrService
      .getAll(
        this.usersPageSetting,
        this.filterValues,
        this.sortValues,
        this.queryParams,
      )
      .pipe(map((resp: PagedResult<PMR>) => resp))
      .subscribe((result) => {
        this.dataSource.data = result.payload;

        if (
          result.payload &&
          result.payload.length &&
          !result.payload[0].archived
        ) {
          this.currentPMR = result.payload[0];
          this.selection.toggle(result.payload[0]);
          this.selectedPmr.emit(this.selection);
        } else {
          this.getCurrentPMR();
        }

        if (result.page) {
          this.usersPageSetting = result.page;
        }
      });
  }

  /**
   *  Respond to the user editing a single item.
   *
   * @param       {*}                         row       The current row in the data tabl
   * @param       {Event}                     [event]   The (`click`) event signalling the user's intent.
   * @memberof    PmrListComponent
   */
  onEditIntent(row?: any, event?: Event) {
    let isNew = true;
    let formValues = <any>{};

    if (event) {
      event.stopImmediatePropagation();
    }

    if (!row) {
      formValues = this.emptyFormValues;
    } else {
      isNew = false;

      const editedRowFormValues = {
        id: [row.id],
        beginTime: [row.beginTime],
        coEnabled: [row.coEnabled],
        endTime: [row.endTime],
        note: [row.note],
        title: [row.title, Validators.required],
        teamId: row.teamId ? row.teamId : this.currentUserRights.currentTeamId,
      };

      formValues = editedRowFormValues;
    }

    const dialogRef = this.dg.open(AddEditPmrComponent, {
      data: { formValues: formValues, isNew: isNew },
      disableClose: true,
      height: '900px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'primary-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        this.getRows();
      }
    });
  }

  /**
   * Respond to the clear intent on filters
   *
   * @memberof        PmrListComponent
   */
  onFiltersClear() {
    this.filterForm.reset({
      title: '',
      beginDate: '',
      endDate: '',
    });
  }

  /**
   *  Responds to the user click event on the row.
   *
   * @param       {any}                   row   The current row in the data table
   * @memberof    PmrListComponent
   */
  onRowClick(row: any) {
    //  Prevent selection if the row is in editMode.
    //
    if (!row.editMode) {
      this.selection.toggle(row);
      this.selectedPmr.emit(this.selection);
    }
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof          PmrListComponent
   */
  ngOnDestroy() {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
    if (this.currentPMRSubs) {
      this.currentPMRSubs.unsubscribe();
    }
  }
}
