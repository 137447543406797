import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { throwError as _throw } from 'rxjs';
// import { User } from '../models/user';
// import { Token } from '../models/token';
// import { Credentials } from '../models/credentials';
// import { AuthError } from '../models/auth-error';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/concat';
// import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { malConfigService } from "./config.service";
import { Token } from '../../models/iq-models/token';
import { User } from '../../models/iq-models/user';
import { Credentials } from '../../models/iq-models/credentials';
import { AuthError } from '../../models/iq-models/auth-error';
import { SnackbarService } from '../iq-package-services/snackbar.service';
// import * as API from '../../../environments/environment'


/**
 * /api/token for login
 * /api/id for taking information about current user
 */

@Injectable()
export class AuthService {
  private API_PATH = '/api';
  private API_USER = this.API_PATH + '/id';
  private API_FORGOTPASSWORD = this.API_PATH + '/forgotpassword';
  private API_SETPASSWORD = this.API_PATH + '/activation';
  private API_LOGIN = this.API_PATH + '/token';
  private API_TOKEN_VALID = this.API_PATH + this.API_LOGIN + '/refresh';

  usere = {};


  constructor(
    private httpClient: HttpClient,
    public snackbar: SnackbarService,
    private projCFG: malConfigService
  ) {

    this.API_PATH = projCFG.apiPath;
    this.API_USER = this.API_PATH + projCFG.user;
    this.API_FORGOTPASSWORD = this.API_PATH + projCFG.forgotPassword;
    this.API_LOGIN = this.API_PATH + projCFG.login;
    this.API_SETPASSWORD = this.API_PATH + projCFG.setPassword;
    this.API_TOKEN_VALID = this.API_PATH + this.API_LOGIN + projCFG.refreshToken;
  }

  private token: Token = null;
  private user: User = null;

  /**
   * Check if user token is still valid
   * @returns any
   */
  public isAuthenticated(): boolean {
    this.restoreState();
    return this.token !== null;
  }

  public isUserKnown(): boolean {
    this.restoreState();
    return this.user != null;
  }

  public getUser(): User {
    this.restoreState();
    return this.user;
  }

  public getToken(): Token {
    this.restoreState();
    return this.token;
  }

  /**
   * Remove user from `memory?`; `cookies?`; =>`localstorage?`<=
   * @returns boolean
   */
  public logout(): void {
    this.token = null;
    this.user = null;
    //  Clear the storage when logout
    //
    localStorage.clear();
  }

  /**
   *
   * @param credentials
   * @returns Observable<User>
   * @memberof AuthService
   */
  public login(credentials: Credentials): Observable<User | Token> {

    // let params = new URLSearchParams;
    // params.set('grant_type', 'password');
    // params.set('scope',  '');
    // params.set('client_id', 'fooClientIdPassword');
    // params.set('password', credentials.password);
    // params.set('username', credentials.username);

    credentials.grant_type = 'password';
    credentials.scope = '';
    credentials.client_id = 'fooClientIdPassword';

    /**
     * grant_type=passwordusername=test@marviq.compassword=passwordscope=client_id=fooClientIdPassword
     */

    if (this.API_LOGIN == '/api/oauth/token') {
      let header = new HttpHeaders(
        {
          'X-Skip-Interceptor': '',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic Zm9vQ2xpZW50SWRQYXNzd29yZDpzZWNyZXQ='
        }
      );

      let log = 'grant_type=password&username=' + credentials.username + '&password=' + credentials.password + '&scope=&client_id=fooClientIdPassword';

      return this.httpClient
        .post<any>(
          this.API_LOGIN,
          log,
          { headers: header }
        ).pipe(
          map((response: any) => {
            this.token = new Token(response.access_token);
            this.saveState();
            return this.token;
          }),
          catchError((err) => {
            if (err.status === 401) {
              this.snackbar.showSnackbar('Error 401');
              return _throw(AuthError.BadCredentialsError);
            } else {
              this.snackbar.showSnackbar('Error any');
              return _throw(AuthError.GeneralError);
            }
          })
        );

    } else {

      return this.httpClient
        .post<any>(
          this.API_LOGIN,
          credentials
        ).pipe(
          map((response: any) => {
            this.token = new Token(response.token);
            this.saveState();
            return this.token;
          }),
          catchError((err) => {
            if (err.status === 401) {
              this.snackbar.showSnackbar('Error 401');
              return _throw(AuthError.BadCredentialsError);
            } else {
              this.snackbar.showSnackbar('Error any');
              return _throw(AuthError.GeneralError);
            }
          })
        );

    }
  }

  public id(): Observable<any> {
    return this.httpClient
      .get<any>(this.API_USER).pipe(
        map((response: any) => {

          //  Assign whole response to the user without any changes
          this.user = response;

          //  The code commented below allow to have only speficic model of the user
          //  TODO: All library should be changed into more generic.

          // const authorities: string[] = response.authorities.map(authority => authority.authority);
          // console.log('authorities: ', authorities);
          // this.user = new User(response.username, response.displayName, response.firstName, response.lastName,
          //   response.emailAddress, response.roles, response.permissions, authorities);
          this.saveState();
          return this.user;
        }),
        catchError((err) => {
          console.log('error', err);
          return _throw(AuthError.GeneralError);
        }));
  }

  /**
   * Request for new/change password
   * @param emailAddress
   * @param username
   * @returns Observable<R|T>
   */
  requestPassword(emailAddress, username): Observable<boolean> {

    return this.httpClient
      .post<void>(
        this.API_FORGOTPASSWORD,
        {
          'emailAddress': emailAddress,
          'username': username
        }
      ).pipe(
        map((response: any) => {
          return true;
        }),
        catchError((err) => {
          console.log('error', err);
          return _throw(AuthError.GeneralError);
        })
      );
  }

  /**
   * Update password with valid token
   * @param token The change password token
   * @param password
   * @returns Observable<boolean>
   */
  changePassword(token: Token, password: string) {
    return this.httpClient
      .put<any>(
        this.API_FORGOTPASSWORD,
        {
          'token': token,
          'password': password
        }
      ).pipe(
        map((response: any) => {
          return true;
        }),
        catchError((err) => {
          console.log('error', err);
          return _throw(AuthError.GeneralError);
        })
      );
  }

  /**
   * Set password with valid token
   * @param token The set password token
   * @param password
   * @returns Observable<boolean>
   */
  setPassword(token: Token, password: string) {
    return this.httpClient
      .put<any>(
        this.API_SETPASSWORD,
        {
          'token': token,
          'password': password
        }
      ).pipe(
        map((response: any) => {
          return true;
        }),
        catchError((err) => {
          console.log('error', err);
          return _throw(AuthError.GeneralError);
        })
      )
      ;
  }


  saveState(): void {
    localStorage.setItem('currentUser', JSON.stringify(this.user));
    localStorage.setItem('currentToken', JSON.stringify(this.token));
  }

  private restoreState(): void {
    if (this.token === null && localStorage.getItem('currentToken')) {
      this.token = JSON.parse(localStorage.getItem('currentToken'));
    }
    if (this.user === null && localStorage.getItem('currentUser')) {
      this.user = JSON.parse(localStorage.getItem('currentUser'));
    }
  }
}


