import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  Input
} from '@angular/core';
import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { MatSidenav } from "@angular/material/sidenav";
import { MediaMatcher } from '@angular/cdk/layout';
import { LayoutService } from '../../../services/iq-package-services/layout.service';
import { LoaderService } from '../../../services/iq-package-services/loader.service';

@Component({
  selector: 'marviq-dashboard',
  template: `
    <mat-progress-bar *ngIf="showLoader" mode="query" color="accent"></mat-progress-bar>
    <mat-spinner class="loading" *ngIf="showLoader"></mat-spinner>

    <ng-content select="[topNav]" class="main-topbar">
    </ng-content>

    <mat-toolbar class="mobile-toolbar" [style.display]="mobileQuery.matches ? 'flex' : 'none' ">
      <button mat-icon-button (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button>
      <div class="mobile-logo-container">
        <ng-content select="[mobileLogo]">
        </ng-content>
      </div>
    </mat-toolbar>

    <mat-sidenav-container class="main-container">
    {{optMode}}{{optOpened}}
      <mat-sidenav #sidenav
      [disableClose]="!mobileQuery.matches"
      [mode]="mobileQuery.matches ? 'over' : optMode"
      [opened]="mobileQuery.matches ? optOpenedMobile : optOpened">

        <ng-content select="[sideNav]">
        </ng-content>

      </mat-sidenav>

      <mat-sidenav-content>
        <div *ngIf="toggleIt != 0">
        <p>
          <button mat-button (click)="sidenav.toggle()">Toggle</button>
        </p>
        <p>
          <mat-radio-group class="example-radio-group" [formControl]="mode">
            <label>Mode:</label>
            <mat-radio-button value="over">Over</mat-radio-button>
            <mat-radio-button value="side">Side</mat-radio-button>
            <mat-radio-button value="push">Push3</mat-radio-button>
          </mat-radio-group>
        </p>
        </div>

        <ng-content select="[mainContent]">
        </ng-content>

      </mat-sidenav-content>

    </mat-sidenav-container>

    <ng-content select="[footer]" class="main-footer">
    </ng-content>
  `,
  styleUrls: ['./layout.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  @Input() toggleIt = 0;

  @Input() menuOpened = '';
  @Input() menuDMode = '';

  private subscription: Subscription;
  private loader: Subscription;
  private menuItemSubs: Subscription;
  public showLoader: boolean;
  public isToggleIt = 0;
  public optMode: any = '';
  public optOpened = '';
  public optOpenedMobile = 'false';

  @ViewChild('sidenav') child: MatSidenav;

  mode = new FormControl('over'); // uncommented

  /**
   *  mobile Query
   *
   * @memberof    LayoutComponent
   */
  mobileQuery: MediaQueryList;


  private _mobileQueryListener: (change: any) => void;

  constructor(
    private layoutService: LayoutService,
    private loaderService: LoaderService,
    private ref: ChangeDetectorRef,
    private media: MediaMatcher
  ) { }

  ngOnInit() {

    this.layoutService.menuOther(false);
    console.log('menu opt', this.menuDMode);
    this.isToggleIt = this.toggleIt;
    this.optMode = this.menuDMode;
    this.optOpened = this.menuOpened;

    //  Change the menu mode if the screen size matches the media query
    this.mobileQuery = this.media.matchMedia('(max-width: 1200px)'); // changed from 768px due to items in topnav mobile view mismatcgh
    this._mobileQueryListener = (change: any) => this.ref.detectChanges();

    this.mobileQuery.addListener(this._mobileQueryListener);

    this.loader = this.loaderService.status.subscribe(
      (val: boolean) => {
        this.toggleLodaer(val);
        return val;
      }
    );

    this.subscription = this.layoutService.menuObservable$.subscribe(
      (res) => {
        console.log("here ", res);
        this.child.toggle();
      }
    );


    this.menuItemSubs = this.layoutService.$menuItem.subscribe(

      (newitem) => {

        if (this.mobileQuery.matches) {

          this.optOpenedMobile = 'false';
          this.child.toggle();
          this.ref.detectChanges();
        }
      }
    );

  }

  toggleLodaer(val: boolean) {
    this.showLoader = val;
    /**
     * force change detecting
     */
    this.ref.detectChanges();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.loader.unsubscribe();
    this.menuItemSubs.unsubscribe();
    this.mobileQuery.removeListener(this._mobileQueryListener);

  }

}
