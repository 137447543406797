import { Component, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { QuestionGroup } from '../../models/questionnaire';
import { QuestionnaireService } from "../../services/questionnaire.service";

@Component({
  selector: 'app-questionnaire-auditor-form',
  templateUrl: './questionnaire-auditor-form.component.html',
  styleUrls: ['./questionnaire-auditor-form.component.scss'],
})
export class QuestionnaireAuditorFormComponent implements OnInit {
  @Input() questionGroup: QuestionGroup;
  @Input() averageQuestionGroupScores: { questionGroupId: string, value: number }[];
  @Input() existingAudit: { completed: boolean; selectedAnswers: { questionGroupId: string, value: number }[] };

  score;
  fysiotherapistScore = 0;
  // supportScore = 0; // ondersteuning

  auditorForm: FormGroup<{ questionGroupId: FormControl; value: FormControl; comment: FormControl }>;

  #fb = inject(FormBuilder);
  #questionnaireService = inject(QuestionnaireService);

  ngOnInit(): void {
    this.auditorForm = this.#fb.group({
      value: new FormControl<number>(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      comment: new FormControl<string>(null, []),
      questionGroupId: this.questionGroup.id
    });
    this.#questionnaireService.auditorForm.push(this.auditorForm);

    this.score = this.averageQuestionGroupScores?.find(groupScore => groupScore.questionGroupId === this.questionGroup?.id)?.value || 0;

    setTimeout(() => {
      this.#mapInitialValues();
      if (this.existingAudit.completed) {
        this.auditorForm.disable();
      }
    }, 0)
  }

  #mapInitialValues(): void {
    this.auditorForm.controls.value.setValue(this.#findValueInExistingAudit('value'));
    this.auditorForm.controls.comment.setValue(this.#findValueInExistingAudit('comment'));
  }

  #findValueInExistingAudit(fieldName: string): number | string | null {
    if (this.existingAudit.selectedAnswers.length) {
      return this.existingAudit.selectedAnswers.find(auditItem => auditItem?.questionGroupId === this.questionGroup.id)?.[fieldName] || null;
    } else return null;
  };


}
