/** @format */

import { Component, OnInit } from '@angular/core';
import { malResetPasswordFormComponent } from '../../iq-auth-components';

/**
 *  Reset password form component
 *
 * @export
 * @class KmsResetPasswordFormComponent
 * @extends {malResetPasswordFormComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'kms-auth-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
})
export class KmsResetPasswordFormComponent
  extends malResetPasswordFormComponent
  implements OnInit { }
