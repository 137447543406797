/** @format */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Observable, throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ImprovementAnalysis } from '../models/improvement-analysis';
import { PagedResult } from '../models/paged-result';
import { PageSetting } from '../models/paged-setting';
import { AbstractRestService } from './iq-package-services/abstract-rest-service.service';

const apiPath = environment.apiPath;

/**
 *  Service for managing the improvement analysis
 *
 * @export
 * @class   ImprovementAnalysisService
 * @extends {AbstractRestService<ImprovementAnalysis>}
 */
@Injectable()
export class ImprovementAnalysisService extends AbstractRestService<ImprovementAnalysis> {
  /**
   * Creates an instance of ImprovementAnalysisService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      ImprovementAnalysisService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}improvement_analysis`, 'improvement_analysis');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<ImprovementAnalysis>}
   * @memberof        ImprovementAnalysisService
   */
  protected mapToModel(json: any): PagedResult<ImprovementAnalysis> {
    //  If the array doesn't exist, create an empty object
    let payload = [];

    if (json._embedded) {
      payload = <ImprovementAnalysis[]>json._embedded.improvementAnalysis;
    }

    return new PagedResult<ImprovementAnalysis>(
      payload,
      <PageSetting>json.page,
    );
  }

  /**
   * A generic DELETE method.
   * @param BaseModel aModel
   * @returns Observable<T>
   */
  delete(aModel: any): Observable<ImprovementAnalysis> {
    const deleteURL = `${this.actionUrl}/${aModel.id}?teamId=${aModel.teamId}`;

    return this.http
      .delete<ImprovementAnalysis>(deleteURL)
      .pipe(catchError((err) => _throw(err)));
  }
}
