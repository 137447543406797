/** @format */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserRights } from '../../models/user-rights';
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UserValidatorService } from '../../services/user-validator.service';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { WarningDeleteDialogComponent } from '../../dialogs/warning-delete-dialog/warning-delete-dialog.component';
import { ImprovementAnalysis } from '../../models/improvement-analysis';
import { ImprovementAnalysisService } from '../../services/improvement-analysis.service';
import { AddEditImprovementAnalysisComponent } from '../../dialogs/add-edit-improvement-analysis/add-edit-improvement-analysis.component';
import { PageSetting } from '../../models/paged-setting';
import { MarviqMatTableInlineCrudComponent } from '../iq-package-components/marviq-mat-table-inline-crud.component';

/**
 *  Show the list of improvement analysis
 *
 * @export
 * @class         ImprovementAnalysisListComponent
 * @implements    {OnInit}
 */
@Component({
  selector: 'app-improvement-analysis-list',
  templateUrl: './improvement-analysis-list.component.html',
  styleUrls: ['./improvement-analysis-list.component.scss'],
})
export class ImprovementAnalysisListComponent
  extends MarviqMatTableInlineCrudComponent<ImprovementAnalysis>
  implements OnInit, OnDestroy {
  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                ImprovementAnalysisListComponent
   */
  columnsToDisplay = ['title', 'description', 'IVM', 'CUD'];

  /**
   *  The rights of the current user
   *
   * @type              {UserRights}
   * @memberof          ImprovementAnalysisListComponent
   */
  currentUserRights: UserRights;

  /**
   *  The dialog component assigned to the delete request.
   *
   * @memberof                ImprovementAnalysisListComponent
   */
  deleteDialog = WarningDeleteDialogComponent;

  /**
   *  The default values of the new Form.
   *
   * @memberof               ImprovementAnalysisListComponent
   */
  emptyFormValues = {
    description: [''],
    improvementIds: [[]],
    title: ['', Validators.required],
    teamId: '',
  };

  /**
   *  The filters form
   *
   * @type                    {FormGroup}
   * @memberof                ImprovementAnalysisListComponent
   */
  filterForm: UntypedFormGroup;

  /**
   * The pager info received from the HTTP request
   *
   * @memberof                ImprovementAnalysisListComponent
   */
  usersPageSetting: PageSetting = new PageSetting(25, 0, 0, 0);

  /**
   * Apply user rights
   *
   * @memberof ImprovementAnalysisListComponent
   */
  public crudC = false;
  public crudR = false;
  public crudU = false;
  public crudD = false;

  /**
   * Creates an instance of ImprovementAnalysisListComponent.
   *
   * @param {ImprovementAnalysisService} analysisService
   * @param {FormBuilder}             formBuilder
   * @param {MatDialog}               dg
   * @param {UserValidatorService}    userValidatorService
   * @memberof ImprovementAnalysisListComponent
   */
  constructor(
    private analysisService: ImprovementAnalysisService,
    private formBuilder: UntypedFormBuilder,
    private dg: MatDialog,
    private userValidatorService: UserValidatorService,
  ) {
    super(analysisService, formBuilder, dg);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof ImprovementAnalysisListComponent
   */
  ngOnInit() {
    this.sortValues = { createDate: 'desc' };
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.dialogParameters.data.message = `Weet je zeker dat je deze analyse verbetermaatregel wilt verwijderen?`;

    if (this.currentUserRights.currentTeamId) {
      this.queryParams.push({
        name: 'teamId',
        value: this.currentUserRights.currentTeamId,
      });

      this.emptyFormValues.teamId = this.currentUserRights.currentTeamId;
    }

    this.checkPermissions();

    if (this.crudR) {
      this.addFiltersForm();
      this.getRows();
    }
  }

  /**
   *  Add and subscribe the form group for list filters
   *
   * @memberof ImprovementAnalysisListComponent
   */
  addFiltersForm() {
    this.filterForm = this.formBuilder.group({
      title: '',
    });

    this.filterForm.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((changes) => {
        for (const field of Object.keys(changes)) {
          this.filterValues[field] = changes[field];
        }

        this.usersPageSetting.number = 0;
        this.paginator.firstPage();
        this.getRows();
      });
  }

  /**
   *  Check the current user permissions
   *
   * @memberof ImprovementAnalysisListComponent
   */
  checkPermissions() {
    const perm = this.currentUserRights.currentMember.teamRole;

    if (perm.permissions) {
      // READ_ANALYSIS
      // DELETE_ANALYSIS
      // CREATE_ANALYSIS
      // UPDATE_ANALYSIS
      // READ_ALL_ANALYSIS
      // DELETE_ALL_ANALYSIS
      // UPDATE_ALL_ANALYSIS
      if (
        perm.permissions.indexOf('READ_ANALYSIS') > -1 ||
        perm.permissions.indexOf('READ_ALL_ANALYSIS') > -1
      ) {
        this.crudR = true;
      }
      if (
        perm.permissions.indexOf('DELETE_ANALYSIS') > -1 ||
        perm.permissions.indexOf('DELETE_ALL_ANALYSIS') > -1
      ) {
        this.crudD = true;
      }
      if (perm.permissions.indexOf('CREATE_ANALYSIS') > -1) {
        this.crudC = true;
      }
      if (
        perm.permissions.indexOf('UPDATE_ANALYSIS') > -1 ||
        perm.permissions.indexOf('UPDATE_ALL_ANALYSIS') > -1
      ) {
        this.crudU = true;
      }
    }
  }

  /**
   *  Respond to the user editing a single item.
   *
   * @param       {*}                         row       The current row in the data tabl
   * @param       {Event}                     [event]   The (`click`) event signalling the user's intent.
   * @memberof    ImprovementAnalysisListComponent
   */
  onEditIntent(row?: any, event?: Event) {
    let isNew = true;
    let formValues = <any>{};

    if (event) {
      event.stopImmediatePropagation();
    }

    if (!row) {
      formValues = this.emptyFormValues;
    } else {
      isNew = false;

      const editedRowFormValues = {
        id: [row.id],
        description: [row.description],
        improvementIds: [row.improvementIds ? row.improvementIds : []],
        title: [row.title, Validators.required],
        teamId: row.teamId ? row.teamId : this.currentUserRights.currentTeamId,
      };

      formValues = editedRowFormValues;
    }

    const dialogRef = this.dg.open(AddEditImprovementAnalysisComponent, {
      data: {
        formValues: formValues,
        isNew: isNew,
        analysis: row,
        canUpdate: this.crudU,
      },
      disableClose: true,
      height: '900px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'primary-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        this.getRows();
      }
    });
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof          ImprovementAnalysisListComponent
   */
  ngOnDestroy() {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
  }
}
