/** @format */

import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { YearlyPlannedSubject } from '../models/yearly-planned-subject';
import { HttpClient } from '@angular/common/http';
import { PagedResult } from '../models/paged-result';
import { PageSetting } from '../models/paged-setting';
import { AbstractRestService } from './iq-package-services/abstract-rest-service.service';

const apiPath = environment.apiPath;

/**
 *  Service for managing the meetings.
 *
 * @export
 * @class         YearlyPlannedSubjectsService
 * @extends       {AbstractRestService<YearlyPlannedSubject>}
 */
@Injectable()
export class YearlyPlannedSubjectsService extends AbstractRestService<YearlyPlannedSubject> {
  /**
   * Creates an instance of YearlyPlannedSubjectsService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      YearlyPlannedSubjectsService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}yearlyPlannedSubjects`, 'yearlyPlannedSubjects');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<YearlyPlannedSubject>}
   * @memberof        YearlyPlannedSubjectsService
   */
  protected mapToModel(json: any): PagedResult<YearlyPlannedSubject> {
    //  If the YearlyPlannedSubject array doesn't exist, create an empty YearlyPlannedSubject object
    let payload = [];

    if (json._embedded) {
      payload = <YearlyPlannedSubject[]>json._embedded.yearlyPlannedSubjects;
    }

    return new PagedResult<YearlyPlannedSubject>(
      payload,
      <PageSetting>json.page,
    );
  }
}
