
<marviq-dashboard>
  a
  <!-- removed | json pipes die to type conflicts -->
  <marviq-topnav
    topNav
    [lines]="0"
    [tLinks]="menuItems"
    [bLinks]="menuItems"
    [tbLinks]="menuItems"
    [bbLinks]="menuItems"
  >

  </marviq-topnav>

  <marviq-sidebar sideNav>
  <!-- removed | json pipe die to type conflicts -->
    <iqc-menu-por-item links="{{ menuItems }}"></iqc-menu-por-item>

  </marviq-sidebar>

  <marviq-container mainContent>
    <router-outlet></router-outlet>
  </marviq-container>

  <!-- removed | json pipes die to type conflicts -->
  <iqc-footer
    footer
    [lines]="0"
    [tLinks]="menuItems"
    [bLinks]="menuItems"
    [tbLinks]="menuItems"
    [bbLinks]="menuItems"
  >

  </iqc-footer>

</marviq-dashboard>
