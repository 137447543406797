/** @format */

import { Component, OnInit } from '@angular/core';
import { malForgotPasswordFormComponent } from '../../iq-auth-components';

/**
 *  Forgot password form component
 *
 * @export
 * @class KmsForgotPasswordFormComponent
 * @extends {malForgotPasswordFormComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'kms-auth-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss'],
})
export class KmsForgotPasswordFormComponent
  extends malForgotPasswordFormComponent
  implements OnInit { }
