/** @format */

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Member } from '../models/member';
import { HttpClient } from '@angular/common/http';
import { throwError as _throw, Observable } from 'rxjs';
import { PagedResult } from '../models/paged-result';
import { PageSetting } from '../models/paged-setting';
import { AbstractRestService } from './iq-package-services/abstract-rest-service.service';

const apiPath = environment.apiPath;

/**
 *  Service for managing the members.
 *
 * @export
 * @class         MembersService
 * @extends       {AbstractRestService<Member>}
 */
@Injectable()
export class MembersService extends AbstractRestService<Member> {
  /**
   * Creates an instance of MembersService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      MembersService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}members`, 'members');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<Member>}
   * @memberof        MembersService
   */
  protected mapToModel(json: any): PagedResult<Member> {
    //  If the Member array doesn't exist, create an empty Member object
    let payload = [];

    if (json._embedded) {
      payload = <Member[]>json._embedded.members;
    }

    return new PagedResult<Member>(payload, <PageSetting>json.page);
  }

  /**
   * A generic DELETE method.
   * @param BaseModel aModel
   * @returns Observable<T>
   */
  deleteMember(aModel: any): Observable<Member> {
    const deleteURL = `${this.actionUrl}/${aModel.id}?teamId=${aModel.teamId}`;

    return this.http
      .delete<Member>(deleteURL)
      .pipe(catchError((err) => _throw(err)));
  }
}
