import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { LayoutService } from '../../services/iq-package-services/layout.service';

interface DATA {
  group: any,
  name: any,
  level: any,
  safeLevel: any,
  icon: any
}
export interface VALhere {
  data?: {
    group: any,
    name: any,
    level: any,
    safeLevel: any,
    icon: any,
  },
  children?: any,
  path?: any,
  group?: any,
  name?: any,
  level?: any,
  safeLevel?: any,
  icon?: any,
}

@Component({
  selector: 'iqc-menu-por-item',
  template: `
    <ng-container *ngFor="let link of menuList">
      <mat-list-item [routerLink]="link.path" routerLinkActive="active-link" (click)="onMenuItemSelect( link.path )">
        <mat-icon mat-list-icon *ngIf="link.icon">{{ link.icon }}</mat-icon>
        <span mat-line><ng-content></ng-content></span>
        <span mat-line class="secondary">{{ link.name }}</span>
      </mat-list-item>
    </ng-container>
  `,
  styles: [
    `
      .secondary {
        color: rgba(0, 0, 0, 0.54);
      }
    `
  ],
})
export class NavItemComponent implements OnInit {
  @Input() links = '';
  @Input() filterBy = '';
  @Input() filterValue = '';
  @Input() icon = '';
  @Input() hint = '';
  @Input() routerLink: string | any[] = '/';
  @Output() navigate = new EventEmitter();

  public jsonMenuList = [];
  public menuList: any[] = [];
  val: VALhere;

  constructor(private layoutService: LayoutService) {
  }

  ngOnInit() {

    if (this.links === '') {
      return
    }

    this.jsonMenuList = JSON.parse(this.links);
    for (let i = 0; i < this.jsonMenuList.length; i++) {
      this.val = this.jsonMenuList[i];

      if (this.val.data) {

        if (this.val.data[this.filterBy]) {
          if (this.val.data[this.filterBy] === this.filterValue) {
            this.createMenu('', this.val.path, this.val.data.group, this.val.data.name, this.val.data.level, this.val.data.safeLevel, this.val.data.icon)
          }
        }
        else {
          this.createMenu('', this.val.path, this.val.data.group, this.val.data.name, this.val.data.level, this.val.data.safeLevel, this.val.data.icon)
        }
      }

      if (this.val.children) {
        this.findSubMenu(this.val.path, this.val.children);
      }

    }

  }

  createMenu(parentPath: any, path: any, group: any, name: any, level: any, safeLevel: any, icon: any) {
    this.menuList.push({
      path: parentPath + path,
      group: group,
      name: name,
      level: level,
      safeLevel: safeLevel,
      icon: icon
    });

  }

  findSubMenu(parentPath: any, valsub: any) {
    let parent = parentPath + '/';

    for (var i = 0; i < valsub.length; i++) {
      var sub = valsub[i];

      if (sub.data) {
        if (sub.data[this.filterBy]) {
          if (sub.data[this.filterBy] === this.filterValue) {
            this.createMenu(parent, sub.path, sub.data.group, sub.data.name, sub.data.level, sub.data.safeLevel, sub.data.icon);
          }
        }
        else {
          this.createMenu(parent, sub.path, sub.data.group, sub.data.name, sub.data.level, sub.data.safeLevel, sub.data.icon);
        }
      }

      if (sub.children) {
        let newparent = parent + sub.path;

        this.findSubMenu(newparent, sub.children);
      }

    }

  }


  onMenuItemSelect(path: string) {

    this.layoutService.nextMenuItem(path);

  }


}
