/** @format */

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Team } from '../models/team';
import { HttpClient } from '@angular/common/http';
import { PagedResult } from '../models/paged-result';
import { PageSetting } from '../models/paged-setting';
import { AbstractRestService } from './iq-package-services/abstract-rest-service.service';

const apiPath = environment.apiPath;

/**
 *  Service for managing the teams list.
 *
 * @export
 * @class     TeamsListService
 * @extends   {AbstractRestService<Team>}
 */
@Injectable()
export class TeamsListService extends AbstractRestService<Team> {
  /**
   * Creates an instance of TeamsListService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      TeamsListService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}teams`, 'teams');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<Team>}
   * @memberof        TeamsListService
   */
  protected mapToModel(json: any): PagedResult<Team> {
    //  If the Team array doesn't exist, create an empty Team object
    let payload = [];

    if (json._embedded) {
      payload = <Team[]>json._embedded.teams;
    }

    return new PagedResult<Team>(payload, <PageSetting>json.page);
  }

  /**
   *  Filter the list of Teams.
   *
   * @param         {string}            val
   * @returns       {Team[]}
   * @memberof      TeamsListService
   */
  filterTeam(teamsList: Team[], val: string): Team[] {
    return teamsList.filter(
      (option) => option.name.toLowerCase().indexOf(val.toLowerCase()) !== -1,
    );
  }
}
