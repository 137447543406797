<mat-card>
  <span i18n="The title of the reset password window@@auth.resetPasswordWindowTitle">Set password</span>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>New password</mat-label>
      <input matInput type="password" placeholder="*********" formControlName="password" required>
      <mat-error [hidden]="!(formErrors.password)">{{ formErrors.password }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Repeat password</mat-label>
      <input matInput type="password" placeholder="*********" formControlName="confirmPassword" required>
      <mat-error [hidden]="!(formErrors.confirmPassword)">{{ formErrors.confirmPassword }}</mat-error>
    </mat-form-field>

    <button mat-button (click)="submit()" [disabled]="form.invalid || form.disabled" type="submit">
      <i class='fa fa-circle-o-notch fa-spin'></i>
      Send
    </button>
  </form>
  <mat-horizontal-stepper #stepper="matHorizontalStepper">
  </mat-horizontal-stepper>
</mat-card>
