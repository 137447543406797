import { Component, inject, Input, OnInit } from "@angular/core";
import { QuestionnaireFileUploadService } from "../../services/questionnaire-file-upload.service";
import { Attachment, DetailsResult } from "../../models/questionnaire";
import { saveAs } from 'file-saver';
import { allowedUserRoles, UserRoles } from "../../functions/allowed-user-roles";
import { MatDialog } from "@angular/material/dialog";
import { WarningDeleteDialogComponent } from "../../dialogs/warning-delete-dialog/warning-delete-dialog.component";
import { QuestionnaireService } from "../../services/questionnaire.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { map } from "rxjs/operators";

@Component({
  selector: 'app-questionnarie-file-upload',
  templateUrl: './questionnarie-file-upload.component.html',
  styleUrls: ['./questionnarie-file-upload.component.scss'],
})
export class QuestionnaireFileUploadComponent implements OnInit {
  @Input() subDimensionId: string = '';
  @Input() submissionId: string = '';
  @Input() results: DetailsResult[] = [];
  @Input() isFileUploadDisabled = false;
  @Input() proofsRequirements: string[] = [];

  isUserAuditor: boolean;
  isUserIPFTeamAdminOrTeamAdmin: boolean;
  attachmentsList: Attachment[] = [];

  #questionnaireFileUploadService = inject(QuestionnaireFileUploadService);
  #dialog = inject(MatDialog);
  #questionnaireService = inject(QuestionnaireService);
  #fb = inject(FormBuilder);

  proofsForm = this.#fb.group({});

  displayMissingAttachment$ = this.#questionnaireService.missingAttachmentsIds$.pipe(map(idList => idList.includes(this.subDimensionId)));

  ngOnInit(): void {
    this.#findAttachmentsLinkedToSubDimension();
    this.isUserAuditor = allowedUserRoles([UserRoles.IPF_AUDITOR]);
    this.isUserIPFTeamAdminOrTeamAdmin = allowedUserRoles([UserRoles.IPF_TEAM_ADMIN, UserRoles.TEAM_ADMIN]);
    this.#constructProofsForm();
    this.#questionnaireService.proofsForm.push(this.proofsForm as unknown as FormGroup<{ explanation: FormControl<string>, subdimensionId: FormControl<string> }>);

    if (this.isFileUploadDisabled) {
      this.proofsForm.disable();
    }
  }

  #constructProofsForm(): void {
    const explanationValue = this.results[0]?.submission?.proofs?.find(proof => proof.subdimensionId === this.subDimensionId)?.explanation || null;

    this.proofsForm.addControl('explanation', new FormControl(explanationValue));
    this.proofsForm.addControl('subdimensionId', new FormControl(this.subDimensionId))
  }

  #findAttachmentsLinkedToSubDimension(): void {
    this.results.forEach(result => {
      const filteredAttachments: Attachment[] = [];

      result.submission?.proofs?.filter(proof => proof.subdimensionId === this.subDimensionId).forEach(proof => filteredAttachments.push(...proof.attachments));

      // TODO check if this works
      if (filteredAttachments && filteredAttachments.length) {
        this.attachmentsList = filteredAttachments;
      }
    });
  };

  onFileSelected(event: any): void {
    const files: File[] = event.target.files;

    if (files) {
      for (let i = 0; i < files.length; i++) {
        if (this.submissionId) {
          const formData = new FormData();
          formData.append("attachmentFiles", files[i], files[i].name);

          this.#questionnaireFileUploadService.uploadFiles(this.submissionId, this.subDimensionId, formData, i === files.length - 1).subscribe();
        }
      }
    }
  }

  deleteFile(attachment: Attachment): void {
    const dialogRef = this.#dialog.open(WarningDeleteDialogComponent, {
      data: { message: 'Are you sure you want to delete this file?' },
      disableClose: true,
      height: '300px',
      panelClass: 'primary-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results) {
        this.#questionnaireFileUploadService.deleteFile(attachment.id).subscribe();
      }
    });
  }

  downloadFile(attachment: Attachment): void {
    this.#questionnaireFileUploadService.downloadFile(attachment.id).subscribe(res => saveAs(res, attachment.fileName));
  }
}
