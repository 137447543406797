import { Injectable, Inject } from '@angular/core';
import { Subject ,  Observable }            from 'rxjs';

@Injectable()
export class LayoutService {
  private menu = new Subject<boolean>();
  /**
   * Observable string streams
   */
  menuObservable$ = this.menu.asObservable();

  /**
   *  Path to currently selected menuItem.
   *
   * @memberof LayoutService
   */
  private menuItem  = new Subject<string>();

  /**
   *  Observable of menuItem string.
   *
   * @memberof LayoutService
   */
  $menuItem         = this.menuItem.asObservable();

  constructor() {}

  public menuOther( data: boolean ) {
    if ( data ) {
      this.menu.next( data );
    }

  }

  /**
   *  Set next value to the menuItem
   *
   * @memberof LayoutService
   */
  public  nextMenuItem( path: string ) {

    this.menuItem.next( path );

  }
}
