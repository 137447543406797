import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormBuilder } from '@angular/forms';
import {Observable} from "rxjs";

@Component({
  selector: 'auth-set-password-form',
  templateUrl: './set-password-form.component.html',
  styleUrls: ['./set-password-form.component.scss']
})
export class SetPasswordFormComponent implements OnInit {

  @Input()
    token: Observable<string>;

  @Output() submitted = new EventEmitter<{ token: Observable<string>, password: string }>();


  /**
   *  Set password form.
   *
   * @memberof      SetPasswordFormComponent
   */
  form: UntypedFormGroup = new UntypedFormGroup({
    password:         new UntypedFormControl('', [ Validators.required, Validators.minLength(6)] ),
    confirmPassword:  new UntypedFormControl('', [ Validators.required ] )
  }, this.validatePassword );

  /**
   *  The object of form validation errors.
   *
   * @memberof                TeamsListComponent
   */
  formErrors                 = { 'password': '', 'confirmPassword': '' };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                SetPasswordFormComponent
   */
  validationMessages          = {
    'password':  {
      'required':   'Password is required',
      'minlength':  'Password should be longer than 6 characters'
    },
    'confirmPassword': {
      'required':   'Password confirmation is required',
      'mismatch':   'Password is not the same'
    },
  };

  constructor() {}

  ngOnInit() {

    this.form.valueChanges
    .subscribe( changes => this.onFormValueChanged( changes ) );

    this.onFormValueChanged();

  }

  /**
   *  Respond to the submit intent
   *
   * @memberof  SetPasswordFormComponent
   */
  submit() {

    if ( this.form.valid ) {
      const setPasswordData = { token: this.token, password: this.form.value.password };
      this.submitted.emit( setPasswordData );
    }
  }

  /**
   *  Validate if the confirmed password is the same as the `password`
   *
   * @param       group Form group
   * @returns
   * @memberof    SetPasswordFormComponent
   */
  validatePassword( group: UntypedFormGroup ) {

    const confirmPasswordField    = group.get( 'confirmPassword' );

    if ( group.get( 'password' ).value === group.get( 'confirmPassword' ).value ) {

      return null;

    } else {

      confirmPasswordField.setErrors( { 'mismatch': true } );

      return { 'mismatch': true };

    }

  }


  /**
   *  Respond to the change of the form value.
   *
   * @param             [data]
   * @returns
   * @memberof          TeamGroupsListComponent
   */
  onFormValueChanged( data?: any ) {

    if ( !this.form ) { return; }

    const form =  this.form;

    for ( const field of Object.keys( this.formErrors ) ) {
      this.formErrors[ field ]  = ''; //  Clear all of messages
      const control             = form.get( field );

      if ( control && control.dirty && !control.valid ) {
        const messages          = this.validationMessages[ field ];

        for ( const key of Object.keys( control.errors )) {

          this.formErrors[ field ] += messages[ key ] + ' ';

        }
      }
    }

  }

}
