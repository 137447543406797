<div class="container mt-5">
  <div class="row">
    <div class="col-md-12 mt-5">
      <!-- <h2 class="text-center text-dark mb-4">We Care Support</h2> -->
      <div class="row">
        <div class="col-md-6 mx-auto">
          <span class="anchor" id="formLogins"></span>

          <!-- form card login -->
          <div class="card border border-info">
            <div class="card-header bg-info text-white">
              <h3 class="mb-0">
                <i class="fa"></i>
                Register
              </h3>
            </div>
            <div class="card-body">
              <form class="form" role="form" autocomplete="off" id="formLogin" [formGroup]="form" (ngSubmit)="submit()">
                <div class="form-group">
                  <label for="name">Name</label>
                  <input id="name" type="text" class="form-control form-control-lg rounded-0" required="" placeholder="Fullname" formControlName="name"
                         required>
                </div>

                <div class="form-group">
                  <label for="uname">Email</label>
                  <input id="uname" type="text" class="form-control form-control-lg rounded-0" required="" placeholder="test@example.com" formControlName="username"
                         required>
                  <small id="emailHelpBlock" class="form-text text-muted">
                    Email will be used as the username.
                  </small>
                </div>

                <div class="form-group">
                  <label for="password">Password</label>
                  <input id="password" type="password" class="form-control form-control-lg rounded-0" placeholder="secret pass" formControlName="password" required
                         autocomplete="new-password">
                </div>

                <button [disabled]="form.invalid || form.disabled" type="submit" class="btn btn-info rounded-0 btn-lg btn-block mt-5 mb-3">
                  <i class='fa fa-circle-o-notch fa-spin'></i>
                  Register
                </button>

                <div class="row mb-2 mt-4">
                  <small class="col text-center">
                    Already have an account?
                    <a routerLink='/auth/login' class="text-info">Login</a>
                  </small>
                </div>

              </form>
            </div>
            <!--/card-block-->
          </div>
          <!-- /form card login -->

        </div>


      </div>
      <!--/row-->

    </div>
    <!--/col-->
  </div>
  <!--/row-->
</div>
<!--/container-->
