import { Component, Input } from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'marviq-sidebar',
  template: `    
    <mat-drawer-container class="sidenav-main-panel">
      
      <mat-drawer #shortcuts class="sidenav-shortcuts-panel">
        LisT:
        <ng-content select="[blockShortcuts]"></ng-content>
      </mat-drawer>

      <mat-list>
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-description>
                Settings
              </mat-panel-description>
              <mat-panel-title>
                <mat-icon>settings</mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div>
              <button type="button" mat-button (click)="shortcuts.toggle()">
                <mat-icon>compare_arrows</mat-icon>
              </button>
            </div>

          </mat-expansion-panel>
        </mat-accordion>
        
        <ng-content select="[block1]">
        </ng-content>
        <ng-content select="[block2]">
        </ng-content>
        <ng-content select="[block3]">
        </ng-content>
        <ng-content select="[block4]">
        </ng-content>
        <ng-content select="[block5]">
        </ng-content>
      </mat-list>
    </mat-drawer-container>
  `,
  styles: [
      `
      mat-sidenav {
        background: white;
      }

    `,
  ],
})
export class SidenavComponent {

}
